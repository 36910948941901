import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import { Select, Checkbox } from 'antd';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import { ADD_DELIVERABLE } from './mutation';
import { showError, showSuccess } from '../../notifications';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AddDeliverableForm = ({
  onComplete,
  client,
  projectId,
  deliverable = {},
  refetchQueries = [],
  milestoneNames = [],
}) => {
  const [createNew, setCreateNew] = useState(false);
  return (
    <Formik
      initialValues={{
        ...deliverable,
      }}
      validate={values => {
        const errors = {};
        if (!values.deliverableName) {
          errors.deliverableName = 'Required';
        }
        if (!values.milestoneName) {
          errors.milestoneName = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        return client
          .mutate({
            mutation: ADD_DELIVERABLE,
            variables: {
              projectId,
              ...values,
            },
            awaitRefetchQueries: true,
            refetchQueries,
          })
          .then(() => {
            setSubmitting(false);
            onComplete();
            showSuccess({ message: `Successfully updated deliverable.` });
          })
          .catch(err => {
            // console.log('Error Message: ', err.message);
            showError({ message: err.message });
          });
      }}
    >
      {({ values, errors, touched, isValid, setFieldValue, handleChange, handleSubmit, isSubmitting }) => {
        const { deliverableName, milestoneName } = values;
        return (
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            <Form.Item label="Category" hasFeedback>
              <Select
                disabled={createNew}
                name="cagegory"
                showSearch
                placeholder="Select category"
                optionFilterProp="children"
                onChange={category => {
                  setFieldValue('milestoneName', category);
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {milestoneNames &&
                  milestoneNames.map(milestoneName => {
                    return (
                      <Option value={milestoneName} key={milestoneName}>
                        {milestoneName}
                      </Option>
                    );
                  })}
              </Select>
              <Checkbox checked={createNew} onChange={e => setCreateNew(e.target.checked)}>
                Create New
              </Checkbox>
            </Form.Item>
            {createNew && (
              <Form.Item
                label="New Category"
                hasFeedback
                help={errors.milestoneName || 'Specify new category'}
              >
                <Input name="milestoneName" onChange={handleChange} value={milestoneName} />
              </Form.Item>
            )}
            <Form.Item
              extra="Create a new deliverable."
              label="Deliverable"
              hasFeedback
              help={errors.deliverableName}
              validateStatus={touched.deliverableName && errors.deliverableName && 'error'}
            >
              <Input name="deliverableName" onChange={handleChange} value={deliverableName} />
            </Form.Item>
            {/* {financeGroupInc && financeGroupInc.length ? (
              <>
                <Form.Item label="BU Code" hasFeedback>
                  <Input name="accountingCode" onChange={handleChange} value={accountingCode} />
                </Form.Item>
                <Form.Item label="IS Code" hasFeedback>
                  <Input
                    name="accountingCodeSuffix"
                    onChange={handleChange}
                    value={accountingCodeSuffix}
                  />
                </Form.Item>
              </>
            ) : (
              <></>
            )} */}

            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Add Deliverable
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(AddDeliverableForm);
