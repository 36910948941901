import Tabs from 'antd/lib/tabs';
import styled from 'styled-components';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  background-color: #FFF;
`;

export default StyledTabs;
export { TabPane };
