import { useQuery } from '@apollo/react-hooks';
// @ts-ignore
import { Progress } from 'antd';
import { GET_DELIVERABLES_AND_EXPENSES } from '../../../modules/project-manager/components/ProjectFinancialSummary';
import { calcBudgetFill } from '../../../modules/project-manager/components/ProjectFinancials';
import { constructDailyTotals } from './TimesheetGrid';
import { trpc } from '../../../utils/trpc';

const ProjectMilestoneBudgetIndicator = ({
  projectId,
  deliverableId,
  milestoneName,
  milestoneLineItems,
}: {
  projectId: string;
  deliverableId: string;
  milestoneName: string;
  milestoneLineItems: [any];
}) => {
  const { loading, data } = useQuery(GET_DELIVERABLES_AND_EXPENSES, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: 'cache-first',
  });
  const { data: myRate = 0 } = trpc.useQuery(
    [
      'users.myRateByProjectId',
      {
        projectId: projectId,
      },
    ],
    {
      refetchOnWindowFocus: false,
    },
  );

  if (loading) {
    return null;
  }

  const deliverables = data?.projectManagement?.project?.deliverables || [];

  // @ts-ignore
  const totalDeliverableCost = deliverables
    // @ts-ignore
    .filter(d => d.milestoneName === milestoneName)
    // @ts-ignore
    .reduce((acc, cur) => {
      return acc + cur.totalCost;
    }, 0);

  const totalDeliverableBudget = deliverables
    // @ts-ignore
    .filter(d => d.milestoneName === milestoneName)
    // @ts-ignore
    .reduce((acc, cur) => {
      return acc + cur.budget || 0;
    }, 0);

  const dailyTotals = constructDailyTotals(milestoneLineItems);
  let newHours = 0;
  for (const property in dailyTotals) {
    // @ts-ignore
    newHours += dailyTotals[property];
  }

  const newCost = newHours * myRate;

  const budgetUsedPct = (totalDeliverableCost / totalDeliverableBudget) * 100;
  const newBudgetPct = ((totalDeliverableCost + newCost) / totalDeliverableBudget) * 100;

  const budgetDiff = newBudgetPct - budgetUsedPct;

  if (totalDeliverableBudget === 0) {
    return null;
  }

  const status = newBudgetPct > 100 ? 'exception' : newBudgetPct <= 100 ? 'active' : 'success';
  return (
    <div className="ml-2 flex items-end">
      <div style={{ width: 150 }}>
        <Progress
          percent={Math.floor(newBudgetPct)}
          successPercent={budgetUsedPct < 100 ? Math.floor(budgetUsedPct) : undefined}
          size="small"
          strokeColor={calcBudgetFill({ budgetUsedPct: (newBudgetPct || budgetUsedPct) / 100 })}
          status={status}
        />
      </div>
      <div className="font-thin text-xs ml-2">
        {status === 'exception' ? 'Over budget' : status === 'active' ? 'Budget used' : 'On budget'}
      </div>
      {myRate > 0 && budgetDiff > 0 && status !== 'exception' && (
        <div className="font-thin text-xs ml-2">
          {`(Your new hours will use ${budgetDiff.toFixed(1)}% of the budget)`}
        </div>
      )}
    </div>
  );
};

export default ProjectMilestoneBudgetIndicator;
