import React, { useState } from 'react';
import { Radio } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Card from '../../common/components/Card';
import Form from '../../common/components/Form';
import Button from '../../common/components/Button';
import VaccinatedForm from './VaccinatedForm';
import NotVaccinatedForm from './NotVaccinatedForm';

const VaccineProofForm = ({ userVaccineProofs }) => {
  const history = useHistory();

  const { status, vaccineDetails, updatedAt } = userVaccineProofs ? userVaccineProofs : {};
  const [vaccineStatus, setvaccineStatus] = useState(
    status ? (status === 'yes' ? 'yes' : 'no') : '',
  );
  const [updateDate] = useState(updatedAt ? moment(updatedAt).format('L LTS') : null);
  const [updatedDetails, setUpdatedDetails] = useState(false);

  const handleVaccineStatus = e => {
    setvaccineStatus(e.target.value);
  };

  return (
    <Card
      border
      title="Vaccine Tracker"
      description="If you have been given direction, please provide your vaccine information below"
      padded={true}
      floating
      actionComponent={updateDate && `Last Update : ${updateDate}`}
    >
      {updatedDetails ? (
        <div>
          <p>Thank you, your vaccine details have been updated successfully.</p>
          <Button
            primary
            onClick={() => {
              history.push('/');
            }}
          >
            Return to Dashboard
          </Button>
        </div>
      ) : (
        <>
          <Form layout="horizontal">
            <Form.Item label="Are you fully vaccinated against COVID-19 ?" colon={false}>
              <Radio.Group
                defaultValue={vaccineStatus}
                buttonStyle="solid"
                onChange={handleVaccineStatus}
              >
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
          {vaccineStatus === 'yes' ? (
            <VaccinatedForm data={vaccineDetails} setUpdatedDetails={setUpdatedDetails} />
          ) : (
            vaccineStatus === 'no' && <NotVaccinatedForm data={userVaccineProofs} />
          )}
        </>
      )}
    </Card>
  );
};

export default VaccineProofForm;
