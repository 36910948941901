import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, Input, Button } from 'antd';
import { trpc } from '../../../utils/trpc';
import Modal from '../../common/components/Modal';
import React from 'react';
// import AutoForm from '../../common/components/AutoForm';

export const schema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  shortName: z.string().min(1, 'Short Name is required').trim(),
  quickbooksCustomerName: z.string().trim().nullish(),
});

const NewClientForm = () => {
  const [showModal, setShowModal] = React.useState(false);
  const addMutation = trpc.useMutation(['client.add']);
  const utils = trpc.useContext();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <div>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
      >
        New Client
      </Button>
      {/* @ts-ignore */}
      <Modal
        title="New Client"
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          reset();
        }}
        footer={
          <div>
            <Button
              onClick={() => {
                setShowModal(false);
                reset();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!isDirty}
              loading={addMutation.isLoading}
              onClick={handleSubmit(({ name, shortName }) => {
                addMutation.mutate(
                  {
                    name,
                    shortName,
                  },
                  {
                    onSuccess: () => {
                      setShowModal(false);
                      reset();
                      utils.invalidateQueries(['client.all']);
                    },
                    onError: e => {
                      console.log(e);
                    },
                  },
                );
              })}
            >
              Submit
            </Button>
          </div>
        }
      >
        {/* <AutoForm
          fields={[
            {
              label: 'Name',
              name: 'name',
            },
            {
              label: 'Short Name',
              name: 'shortName',
            },
          ]}
          schema={schema}
          onSubmit={handleSubmit(({ name, shortName }) => {
            addMutation.mutate(
              {
                name,
                shortName,
              },
              {
                onSuccess: () => {
                  setShowModal(false);
                  reset();
                  utils.invalidateQueries(['client.all']);
                },
                onError: e => {
                  console.log(e);
                },
              },
            );
          })}
        /> */}
        <form className="flex flex-wrap transition-opacity">
          <Form.Item
            className="w-full md:w-1/2 p-2"
            label="Name"
            hasFeedback
            validateStatus={errors.name?.message && 'error'}
            // @ts-ignore
            help={errors.name?.message}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
          <Form.Item
            className="w-full md:w-1/2 p-2"
            label="Short Name"
            hasFeedback
            validateStatus={errors.shortName?.message && 'error'}
            // @ts-ignore
            help={errors.shortName?.message}
          >
            <Controller
              name="shortName"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </form>
      </Modal>
    </div>
  );
};

export default NewClientForm;
