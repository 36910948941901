import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';
import gql from 'graphql-tag';

const CALL_OAUTH = gql`
  mutation Oauth($token: String!) {
    oauth(token: $token)
  }
`;

const Oauth = () => {
  const history = useHistory();
  const parsed = queryString.parse(window.location.hash);
  const [loginWithOauth] = useMutation(CALL_OAUTH);
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
      history.goBack();
    // }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const login = async () => {
      await loginWithOauth({
        variables: {
          token: parsed.id_token,
        },
      });
      setLoggingIn(false);
      window.location.href = window.location.origin;
    };

    if (parsed?.id_token && !loggingIn) {
      setLoggingIn(true);
      login();
    }
  }, [loginWithOauth, parsed, loggingIn]);

  return null;
};

export default Oauth;
