import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, flatten } from 'lodash';
import TimesheetWeeklySnapshot from './TimesheetWeeklySnapshot';
import TextLoader from '../../common/components/TextLoader';
import { GET_TIMESHEET_WEEKLY_SNAPSHOT } from '../queries';
import { fetchTimesheetsForPeriod } from '../index';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

export const sumLineItemHours = li => {
  const mon = get(li, 'monTask.hours') || 0;
  const tue = get(li, 'tueTask.hours') || 0;
  const wed = get(li, 'wedTask.hours') || 0;
  const thu = get(li, 'thuTask.hours') || 0;
  const fri = get(li, 'friTask.hours') || 0;
  const sat = get(li, 'satTask.hours') || 0;
  const sun = get(li, 'sunTask.hours') || 0;
  return mon + tue + wed + thu + fri + sat + sun;
};

export const createGridData = timesheets =>
  flatten(
    timesheets.map(ts =>
      ts.lineItems.map(li => {
        let bu = `${get(li, 'project.accountingCode') || ''}-${
          get(li, 'project.accountingCodeSuffix') || ''
        }`;
        if (get(li, 'deliverable.accountingCode') && get(li, 'deliverable.accountingCodeSuffix')) {
          bu = `${get(li, 'deliverable.accountingCode') || ''}-${
            get(li, 'deliverable.accountingCodeSuffix') || ''
          }`;
        }
        return {
          timesheetId: get(ts, '_id'),
          status: get(ts, 'status'),
          endDate: moment(get(ts, 'endDate')).format('MM-DD-YYYY'),
          firstName: get(ts, 'user.firstName'),
          lastName: get(ts, 'user.lastName'),
          email: get(ts, 'user.emails[0].address'),
          payrollId: get(ts, 'user.payrollId'),
          clientName: get(li, 'project.client.shortName'),
          project: get(li, 'project.name'),
          projectCode: get(li, 'project.projectCode'),
          bu: bu,
          milestone: get(li, 'deliverable.milestoneName'),
          deliverable: get(li, 'deliverable.deliverableName'),
          mon: get(li, 'monTask.hours'),
          tue: get(li, 'tueTask.hours'),
          wed: get(li, 'wedTask.hours'),
          thu: get(li, 'thuTask.hours'),
          fri: get(li, 'friTask.hours'),
          sat: get(li, 'satTask.hours'),
          sun: get(li, 'sunTask.hours'),
          total: sumLineItemHours(li),
          // TODO group all comments together
          // comment: get(li, 'monTask.comment'),
        };
      }),
    ),
  );

const TimesheetListContainer = ({ user, setLoading, loading, startDate, endDate, ...rest }) => {
  const [timesheets, setTimesheets] = useState([]);
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchedData = await fetchTimesheetsForPeriod({
        startDate,
        endDate,
        setLoadingText,
        query: GET_TIMESHEET_WEEKLY_SNAPSHOT,
      });
      setTimesheets(fetchedData);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [startDate, endDate, setLoading]);
  // remove tasks for days that fall outside of the selected range
  const timesheetsWithTrimmedTasks = timesheets.map(timesheet => {
    const newLineItems = timesheet.lineItems.map(lineItem => {
      for (const property in lineItem) {
        if (property.endsWith('Task') && lineItem[property]) {
          const { date } = lineItem[property];
          if (date < startDate || date > endDate) {
            delete lineItem[property];
          }
        }
      }
      return lineItem;
    });
    return {
      ...timesheet,
      lineItem: newLineItems,
    };
  });
  const rowData = createGridData(timesheetsWithTrimmedTasks);

  if (loading) {
    return <TextLoader text={loadingText} />;
  }

  return <TimesheetWeeklySnapshot user={user} rowData={rowData} endDate={endDate} {...rest} />;
};

export default connect(mapStateToProps)(TimesheetListContainer);
