import React, { useState } from 'react';
import { Checkbox, Statistic, Icon, Input, Button } from 'antd';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { createClient } from '../../../api/graphql/client';
import Table from '../../common/components/Table';
import DateRangePicker from '../../common/components/DateRangePicker';
import Card from '../../common/components/Card';
import Profile from '../../common/components/Profile';
import { CSVLink } from 'react-csv';
import ResourcePerformanceDetail from './ResourcePerformanceDetail';
import { showError } from '../../common/notifications';
import { ExportToCsv } from 'export-to-csv';

export const PERFORMANCE = gql`
  query performance($startDate: String, $endDate: String) {
    users {
      _id
      firstName
      lastName
      emails {
        address
      }
      title
      category
      imageUrl
      employeeType
      performance(startDate: $startDate, endDate: $endDate) {
        numOfWeeks
        timeReported
        leftOverHours
        pctUtilized
        hoursAvailable
        billableTime
        pctBillable
        nonBillableTime
        pctNonBillable
        holidayTime
        pctHoliday
        ptoTime
        pctPtoTime
      }
    }
  }
`;
const getUtilizationColor = utilizationPct => {
  if (utilizationPct > 100 && utilizationPct <= 110) {
    return 'orange';
  }
  if (utilizationPct < 95 && utilizationPct >= 85) {
    return 'orange';
  }
  if (utilizationPct > 110 || utilizationPct < 85) {
    return 'red';
  }
};

const getColumns = ({ searchInput, onSearch, resetSearch }) => [
  {
    dataIndex: 'index',
    render: value => <div className="font-medium text-base">{value + 1}</div>,
  },
  {
    title: 'Resource',
    key: 'name',
    width: '40%',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => onSearch(selectedKeys, confirm, 'firstName')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => onSearch(selectedKeys, confirm, 'firstName')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => resetSearch(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const { firstName, lastName, title, emails } = record;
      const searchString = firstName + lastName + title + emails[0].address;
      return searchString.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: ({ firstName, lastName, imageUrl, title, emails, employeeType }) => {
      return (
        <div>
          <Profile
            imageUrl={imageUrl}
            title={title}
            firstName={firstName}
            lastName={lastName}
            employeeType={employeeType}
            email={emails[0].address}
          />
        </div>
      );
    },
  },
  {
    // title: 'Utilization',
    key: 'performance',
    dataIndex: 'performance',
    width: '20%',
    sorter: ({ performance: aPerformance }, { performance: bPerformance }) => {
      const aUtilizationPct = (aPerformance.timeReported / aPerformance.hoursAvailable) * 100;
      const bUtilizationPct = (bPerformance.timeReported / bPerformance.hoursAvailable) * 100;
      return aUtilizationPct - bUtilizationPct;
    },
    render: ({ hoursAvailable, timeReported, pctUtilized }) => {
      const utilizationPct = pctUtilized * 100;

      const utilizationColor = getUtilizationColor(utilizationPct);
      let utilizationIcon = null;
      if (utilizationPct >= 100) {
        utilizationIcon = 'arrow-up';
      }
      if (utilizationPct < 100) {
        utilizationIcon = 'arrow-down';
      }

      return (
        <div className="flex justify-between">
          <div>
            <Statistic
              title="Utilization"
              value={numeral(utilizationPct).format('0.0')}
              valueStyle={{ color: utilizationColor }}
              prefix={<Icon type={utilizationIcon} />}
              suffix="%"
            />
            <Statistic
              title="Hours Reported"
              value={numeral(timeReported).format('0')}
              suffix={`/ ${hoursAvailable} hours available`}
            />
          </div>
        </div>
      );
    },
  },
  {
    // title: 'Billable',
    key: 'billable',
    dataIndex: 'performance',
    width: '20%',
    sorter: ({ performance: aPerformance }, { performance: bPerformance }) => {
      const aUtilizationPct = aPerformance.pctBillable * 100;
      const bUtilizationPct = bPerformance.pctBillable * 100;
      return aUtilizationPct - bUtilizationPct;
    },
    render: ({ billableTime, pctBillable }) => {
      const utilizationColor = getUtilizationColor(pctBillable * 100);
      let utilizationIcon = null;
      if (pctBillable * 100 > 100) {
        utilizationIcon = 'arrow-up';
      }
      if (pctBillable * 100 < 100) {
        utilizationIcon = 'arrow-down';
      }

      return (
        <div className="flex justify-between">
          <div>
            <Statistic
              title="Billable"
              value={numeral(pctBillable * 100).format('0.0')}
              valueStyle={{ color: utilizationColor }}
              prefix={<Icon type={utilizationIcon} />}
              suffix="%"
            />
            <Statistic title="Billable Hours" value={numeral(billableTime).format('0.0')} />
          </div>
        </div>
      );
    },
  },
  {
    // title: 'NonBillable',
    key: 'nonbillable',
    dataIndex: 'performance',
    width: '20%',
    sorter: ({ performance: aPerformance }, { performance: bPerformance }) => {
      const aUtilizationPct = aPerformance.pctNonBillable * 100;
      const bUtilizationPct = bPerformance.pctNonBillable * 100;
      return aUtilizationPct - bUtilizationPct;
    },
    render: ({ nonBillableTime, pctNonBillable, hoursAvailable }) => {
      const utilizationColor = getUtilizationColor(pctNonBillable * 100);
      let utilizationIcon = null;
      if (pctNonBillable * 100 > 100) {
        utilizationIcon = 'arrow-up';
      }
      if (pctNonBillable * 100 < 100) {
        utilizationIcon = 'arrow-down';
      }

      return (
        <div className="flex justify-between">
          <div>
            <Statistic
              title="Non Billable"
              value={numeral(pctNonBillable * 100).format('0.0')}
              valueStyle={{ color: utilizationColor }}
              prefix={<Icon type={utilizationIcon} />}
              suffix="%"
            />
            <Statistic title="Non Billable Hours" value={numeral(nonBillableTime).format('0.0')} />
          </div>
        </div>
      );
    },
  },
];

const headers = [
  { label: 'Type', key: 'type' },
  { label: 'Total Resources', key: 'totalResources' },
  { label: 'Overall Utilization', key: 'overallUtilization' },
  { label: 'Overall Billable', key: 'overallBillable' },
  { label: 'Overall Non-Billable', key: 'overallNonBillable' },
  { label: 'Hours Available', key: 'hoursAvailable' },
  { label: 'Number of Weeks', key: 'numOfWeeks' },
];

const calculateData = ({ filters, data, ignoreInactive }) => {
  const users =
    data?.users
      ?.filter(({ performance }) => !!performance)
      ?.filter(({ category }) => {
        if (filters.includes('exclude-overhead') && category === 'Overhead') {
          return false;
        }
        return true;
      })
      ?.filter(({ performance }) => {
        if (ignoreInactive) {
          const { timeReported } = performance;
          return timeReported !== 0;
        }
        return true;
      })
      .filter(({ employeeType, category, performance }) => {
        if (filters.length === 0) {
          return true;
        }
        const { pctUtilized } = performance;
        const utilization = pctUtilized * 100;

        return filters.some(filter => {
          switch (filter) {
            case 'underutilized':
              return utilization < 90;
            case 'overutilized':
              return utilization > 110;
            case 'contractors':
              return employeeType !== 'W-2';
            case 'employees':
              return employeeType === 'W-2';

            default:
              return false;
          }
        });
      })
      .sort((a, b) => {
        const aUtil = a.performance.timeReported / a.performance.hoursAvailable;
        const bUtil = b.performance.timeReported / b.performance.hoursAvailable;
        return bUtil - aUtil;
      }) || [];

  const { hoursAvailable, numOfWeeks } = users.length > 0 ? users[0].performance : {};
  const totalResources = users.length;
  const overallUtilization =
    users.reduce((acc, cur) => acc + cur.performance.pctUtilized * 100, 0) / totalResources;
  const overallBillable =
    users.reduce((acc, cur) => acc + cur.performance.pctBillable * 100, 0) / totalResources;
  const overallNonBillable =
    users.reduce((acc, cur) => acc + cur.performance.pctNonBillable * 100, 0) / totalResources;

  return {
    users,
    totalResources,
    hoursAvailable,
    numOfWeeks,
    overallUtilization,
    overallBillable,
    overallNonBillable,
  };
};

const getExportData = async ({ startDate, endDate }) => {
  const client = createClient();
  const res = await client.query({
    query: PERFORMANCE,
    variables: {
      startDate,
      endDate,
    },
  });
  return res.data.users
    .filter(u => u.performance?.timeReported > 0)
    .map(u => {
      return {
        month: moment(startDate).format('MM'),
        year: moment(startDate).format('YYYY'),
        email: u.emails[0].address,
        employeeType: u.employeeType,
        firstName: u.firstName,
        lastName: u.lastName,
        title: u.title,
        category: u.category,
        ...u.performance,
      };
    });
};

const ResourceUtilization = () => {
  const [exporting, setExporting] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, 'month').startOf('month').format('YYYYMMDD'),
    moment().subtract(1, 'month').endOf('month').format('YYYYMMDD'),
  ]);
  const [searchInput, setSearchInput] = useState(null);
  const [filters, setFilters] = useState([
    'employees',
    'contractors',
    'overutilized',
    'underutilized',
  ]);
  const [ignoreInactive, setIgnoreInactive] = useState(true);
  const { loading, data = {} } = useQuery(PERFORMANCE, {
    variables: {
      startDate: dateRange[0],
      endDate: dateRange[1],
    },
  });

  const {
    users,
    totalResources,
    hoursAvailable,
    numOfWeeks,
    overallUtilization,
    overallBillable,
    overallNonBillable,
  } = calculateData({ filters, data, ignoreInactive });

  const additionalExportData = {
    all: calculateData({
      filters: ['exclude-overhead', 'employees', 'contractors'],
      data,
      ignoreInactive,
    }),
    employees: calculateData({ filters: ['employees', 'exclude-overhead'], data, ignoreInactive }),
    contractors: calculateData({
      filters: ['contractors', 'exclude-overhead'],
      data,
      ignoreInactive,
    }),
  };
  const exportData = [
    {
      type: 'All',
      ...additionalExportData.all,
    },
    {
      type: 'Employees',
      ...additionalExportData.employees,
    },
    {
      type: 'Contractors',
      ...additionalExportData.contractors,
    },
  ];

  return (
    <Card
      border
      floating
      title={
        <>
          Resource Utilization <sup>(beta)</sup>
        </>
      }
      actionComponent={
        <DateRangePicker
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          ranges={{
            YTD: [moment().startOf('year'), moment()],
            QTD: [moment().startOf('quarter'), moment()],
            'Last Year': [
              moment().startOf('year').subtract(1, 'year'),
              moment().startOf('year').subtract(1, 'day'),
            ],
            'Last Quarter': [
              moment().startOf('quarter').subtract(1, 'quarter'),
              moment().startOf('quarter').subtract(1, 'day'),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          handleDateChange={([startDate, endDate]) => {
            const diff = endDate.diff(startDate, 'days');
            if (diff < 31) {
              setDateRange([startDate.format('YYYYMMDD'), endDate.format('YYYYMMDD')]);
            } else {
              showError({ duration: 7, message: 'The selected date range is too large.' });
            }
          }}
        />
      }
    >
      <div className="p-4 flex justify-between">
        <Checkbox checked={ignoreInactive} onChange={e => setIgnoreInactive(e.target.checked)}>
          Ignore Inactive
        </Checkbox>
        <Checkbox.Group
          options={[
            { label: 'Overutilized', value: 'overutilized' },
            { label: 'Underutilized', value: 'underutilized' },
            { label: 'Contractors', value: 'contractors' },
            { label: 'Employees', value: 'employees' },
            { label: 'Exclude Overhead', value: 'exclude-overhead' },
          ]}
          value={filters}
          onChange={filters => setFilters(filters)}
        />
        <CSVLink
          headers={headers}
          filename={`Utilization_Report_For_${moment(dateRange[0]).format('YYYYMMDD')}-${moment(
            dateRange[1],
          ).format('YYYYMMDD')}_ExportedOn_${moment().format('YYYYMMDD.hhmm')}.csv`}
          data={exportData}
          className="btn"
        >
          Export CSV
        </CSVLink>
        <Button
          loading={exporting}
          onClick={async () => {
            setExporting(true);
            let exportData = [];
            for (
              let month = '20200101';
              month < '20220630';
              month = moment(month).add(1, 'month').format('YYYYMMDD')
            ) {
              const data = await getExportData({
                startDate: month,
                endDate: moment(month).endOf('month').format('YYYYMMDD'),
              });
              exportData = [...exportData, ...data];
            }

            const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              showTitle: false,
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true,
              // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(exportData);
            setExporting(false);
          }}
        >
          Export
        </Button>
      </div>
      <div className="flex justify-between p-4">
        <Statistic title="Total Resources" value={numeral(totalResources).format('0')} />
        <Statistic
          title="Overall Utilization"
          value={numeral(overallUtilization).format('0.0')}
          suffix="%"
        />
        <Statistic
          title="Overall Billable"
          value={numeral(overallBillable).format('0.0')}
          suffix="%"
        />
        <Statistic
          title="Overall Non-Billable"
          value={numeral(overallNonBillable).format('0.0')}
          suffix="%"
        />
        <Statistic title="Available Hours" value={numeral(hoursAvailable).format('0.0')} />
        <Statistic title="Number of Weeks" value={numeral(numOfWeeks).format('0.0')} />
      </div>
      <Table
        loading={loading}
        columns={getColumns({
          searchInput,
          resetSearch: clearFilters => {
            clearFilters();
            setSearchInput(null);
          },
          onSearch: (selectedKeys, confirm) => {
            confirm();
            setSearchInput(selectedKeys[0]);
          },
        })}
        expandedRowRender={user => (
          <ResourcePerformanceDetail userId={user._id} dateRange={dateRange} />
        )}
        pagination={{
          pageSize: 50,
        }}
        locale={{
          emptyText: 'No timesheets for that range',
        }}
        dataSource={users.map((u, index) => ({ ...u, key: u._id, index }))}
      />
    </Card>
  );
};

export default ResourceUtilization;
