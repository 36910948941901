import React from 'react';
import { useGlobal } from 'reactn';
import { get } from 'lodash';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import ApprovalBadgeCounter from '../../common/components/ApprovalBadgeCounter';
import Icon from '../../common/components/Icon';
import classNames from 'classnames';

const Button = styled.button`
  margin-top: 5px;
`;

const Menu = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 1em;
  ${props => props.active && 'border-left: 3px solid #8192ff;'}
  transition: border-left 300ms;
  a {
    color: #2b2d50;
    text-decoration: none;
  }
`;

const ItemIcon = styled(Icon)`
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const determineIfShouldBeActive = (matchUrl, useRegex = false) => {
  if (useRegex) {
    const regex = new RegExp(matchUrl);
    return regex.test(window.location.pathname);
  }
  return window.location.pathname.startsWith(matchUrl);
};

const SideMenu = props => {
  const [user] = useGlobal('user');
  const [config] = useGlobal('config');
  const companyName = get(config, 'company-short-name');
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [showMobileMenu, setShowMobileMenu] = useGlobal('showMobileMenu');
  const menu = get(user, 'menu');
  const inviteToBeta = get(user, 'inviteToBeta');
  const isLocal = window.location.origin.includes('localhost');
  const isStage = window.location.origin.includes('portal-stg');

  if (!menu) {
    return null;
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <Menu>
        {menu.map((item, i) => {
          const { children } = item;
          return (
            <Item key={i + item.label}>
              <div className="flex items-center py-1 text-sm text-gray-700">
                <ItemIcon type={item.iconName} />
                <div className="font-semibold">{item.label}</div>
              </div>
              {children && children.length > 0 && (
                <div className="text-sm">
                  {children.map((subItem, y) => {
                    const { linkUrl, overrideMatchUrl } = subItem;
                    const match = determineIfShouldBeActive(
                      overrideMatchUrl || linkUrl,
                      !!overrideMatchUrl,
                    );

                    const MenuItem = ({ children }) => {
                      return (
                        <Link
                          onClick={() => {
                            setShowMobileMenu(false);
                          }}
                          className={classNames(
                            'block text-gray-700 py-1 pl-12 rounded-lg dark:text-white hover:bg-gray-300 hover:text-gray-700',
                            {
                              'bg-gray-200': match,
                            },
                          )}
                          to={subItem.linkUrl}
                        >
                          {children}
                        </Link>
                      );
                    };

                    if (subItem.label === 'Approvals') {
                      return (
                        <MenuItem key={i + y + subItem.label}>
                          <span className="mr-2">{subItem.label}</span>
                          <ApprovalBadgeCounter />
                        </MenuItem>
                      );
                    } else {
                      return <MenuItem key={i + y + subItem.label}>{subItem.label}</MenuItem>;
                    }
                  })}
                </div>
              )}
            </Item>
          );
        })}
      </Menu>
      <div className="">
        {inviteToBeta && companyName === 'Spruce' && (
          <div
            onClick={() => {
              window.location.href = 'https://portal.sprucetech.com' + location.pathname;
            }}
            className="p-2 font-semibold text-center uppercase font-bold cursor-pointer"
          >
            Return to the old site?
            <Button className="ant-btn sc-bxivhb ciMtsy" type="button">
              Go Back
            </Button>
          </div>
        )}
        {isLocal && (
          <div className="p-2 bg-green-200 text-white text-center uppercase font-bold">Local</div>
        )}
        {isStage && (
          <div className="p-2 bg-orange text-white text-center uppercase font-bold">Stage</div>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
