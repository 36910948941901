import notification from 'antd/lib/notification';

export const showSuccess = ({ duration = 3, ...rest }) => {
  notification.success({
    duration,
    ...rest,
  });
};

export const showInfo = ({ duration = 3, ...rest }) => {
  notification.info({
    duration,
    ...rest,
  });
};

export const showError = ({ duration = 3, ...rest }) => {
  notification.error({
    duration,
    ...rest,
  });
};
