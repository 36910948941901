import classNames from 'classnames';
import { Icon, Statistic, Progress, Empty } from 'antd';
import gql from 'graphql-tag';
import { calcBudgetFill } from './ProjectFinancials';

export const GET_DELIVERABLES_AND_EXPENSES = gql`
  query getProjectDeliverablesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        deductExpenses
        deliverables(withHours: true) {
          _id
          projectId
          milestoneName
          deliverableName
          accountingCode
          accountingCodeSuffix
          totalApprovedHours
          totalCost
          budget
          includeInAllProjects
        }
        budget
        expenses {
          name
          amount
        }
      }
    }
  }
`;

const ProjectFinancialSummary = ({ project, budgetUsed, className }) => {
  // eslint-disable-next-line no-unused-vars
  // const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  // const { loading, data } = useQuery(GET_DELIVERABLES_AND_EXPENSES, {
  //   variables: {
  //     projectId: project._id || project.id,
  //   },
  //   fetchPolicy: 'cache-first',
  // });

  // const expenses = data?.projectManagement?.project?.expenses || [];
  // const deliverables = data?.projectManagement?.project?.deliverables || [];

  // const totalDeliverableCost = deliverables.reduce((acc, cur) => {
  //   return acc + cur.totalCost;
  // }, 0);

  // const deductExpenses = data?.projectManagement?.project?.deductExpenses;
  // const totalExpenseCost = deductExpenses ? expenses.reduce((acc, cur) => {
  //   return acc + cur.amount;
  // }, 0) : 0;
  // const totalCost = totalDeliverableCost + totalExpenseCost;
  const { budget: projectBudget } = project;
  // const budgetUsed2 = totalCost;
  // const budgetRemaining = projectBudget - totalCost;

  // let rowsObj = deliverables.reduce((acc, cur) => {
  //   if (acc[cur.milestoneName]) {
  //     return {
  //       ...acc,
  //       [cur.milestoneName]: [...acc[cur.milestoneName], cur],
  //     };
  //   } else {
  //     return {
  //       ...acc,
  //       [cur.milestoneName]: [cur],
  //     };
  //   }
  // }, {});

  // let categories = [];
  // for (const [key, value] of Object.entries(rowsObj)) {
  //   const budget = value.reduce((acc, cur) => acc + cur.budget, 0);
  //   const totalCost = value.reduce((acc, cur) => acc + cur.totalCost, 0);
  //   categories.push({
  //     name: key,
  //     value: totalCost,
  //     fill: totalCost > budget ? 'red' : '#38A169',
  //   });
  // }

  // if (expenses.length > 0) {
  //   const totalExpenses = expenses.reduce((acc, cur) => acc + cur.amount, 0);
  //   categories.push({
  //     name: 'Expenses',
  //     value: totalExpenses,
  //     fill: 'lightgray',
  //   });
  // }

  // const budgetUsedPct2 = (budgetUsed2 / projectBudget) * 100;
  const budgetUsedPct = budgetUsed * 100;

  // if (loading) {
  //   return (
  //     <div className="flex justify-center items-center">
  //       <Spin />
  //     </div>
  //   );
  // }
  return (
    <div className={classNames('flex ', className)}>
      <div className="w-full text-center border-0 border-solid border-gray-200 p-2 flex flex-col justify-items-center align-middle">
        {!projectBudget ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 36,
            }}
            className="m-2"
            description={<span>Budget not set</span>}
          ></Empty>
        ) : (
          <>
            <Statistic
              title="Budget Used"
              value={budgetUsedPct}
              valueStyle={{ color: calcBudgetFill({ budgetUsedPct: budgetUsedPct / 100 }) }}
              precision={1}
              prefix={budgetUsedPct > 80 ? <Icon type="exclamation" /> : null}
              suffix="%"
            />
            <Progress
              showInfo={false}
              percent={budgetUsedPct}
              strokeColor={calcBudgetFill({ budgetUsedPct: budgetUsedPct / 100 })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectFinancialSummary;
