import gql from 'graphql-tag';

export const GET_USER_VACCINE_PROOFS = gql`
  query userVaccineProofs {
    userVaccineProofs {
      vaccineDetails {
        vaccineVersion
        lastVaccinationDate
        vaccineProof
        authorizeShare
        affirmAccurate
        discloseStatus
      }
      testDetails {
        authorizeShare
        affirmAccurate
        discloseStatus
      }
      status
      noType
      requestExceptionText
      updatedAt
    }
  }
`;
export const GET_USER_TEST_PROOFS = gql`
  query UserVaccineProofs {
    userVaccineProofs {
      testDetails {
        authorizeShare
        affirmAccurate
        discloseStatus
        testProof {
          date
          proofUrl
          updatedAt
        }
      }
      status
      noType
      requestExceptionText
      updatedAt
    }
  }
`;
export const GET_VACCINE_REPORT = gql`
  query getVaccineTrackerReport {
    vaccineTrackerReport {
      _id
      firstName
      lastName
      lastActiveOn
      employeeType
      vaccineStatus
      emails {
        address
        verified
      }
      vaccineData {
        vaccineDetails {
          vaccineVersion
          lastVaccinationDate
          vaccineProof
        }
        testDetails {
          testProof {
            date
            proofUrl
            updatedAt
          }
        }
        noType
        updatedAt
      }
    }
  }
`;

export const GET_NONVACCINATED_TIMESHEETS = gql`
  query getNonVaccinatedTimesheets($userIds:[String]) {
    allUserTimesheets(userIds:$userIds) {
      userId
      startDate
      endDate
      totalHours
      lineItems {
        project {
          name
          clientName
        }
      }
    }
    
  }
`;
