import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spin from 'antd/lib/spin';
import { fadeIn } from '../../../../globalStyles';

const Wrapper = styled.div`
  min-height: 100px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.2s linear;
`;

const TextLoader = ({ text }) => (
  <Wrapper>
    <Spin tip={text} />
  </Wrapper>
);

TextLoader.propTypes = {
  /**
   * String to display when loading.
   */
  text: PropTypes.string,
};

TextLoader.defaultProps = {
  text: undefined,
};

export default TextLoader;
