import React, { useState } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { InputNumber } from 'antd';
import { Formik } from 'formik';
import DataDisplay from '../../common/components/DataDisplay';
import Card from '../../common/components/Card';
import Profile from '../../common/components/Profile';
import Form from '../../common/components/Form';
import DatePicker from '../../common/components/DatePicker';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';

const GET_USER = gql`
  query getUser($userId: String!) {
    admin {
      user(userId: $userId) {
        _id
        firstName
        lastName
        emails {
          address
        }
        employeeType
        imageUrl
        title
        hr {
          financials {
            _id
            hourlyCost
            startDate
            endDate
          }
        }
      }
    }
  }
`;

const GET_USERS = gql`
  query getUsers {
    admin {
      users {
        _id
        firstName
        lastName
        emails {
          address
        }
        imageUrl
        title
        employeeType
        hr {
          financials {
            _id
            hourlyCost
            startDate
            endDate
          }
        }
      }
    }
  }
`;

const UPDATE_USER_FINANCIAL = gql`
  mutation updateUserFinancial(
    $userId: ID!
    $indexId: ID!
    $hourlyCost: Float
    $startDate: String
    $endDate: String
  ) {
    updateUserFinancial(
      userId: $userId
      indexId: $indexId
      hourlyCost: $hourlyCost
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

const ADD_USER_FINANCIAL = gql`
  mutation addUserFinancial(
    $userId: ID!
    $hourlyCost: Float!
    $startDate: String!
    $endDate: String
  ) {
    addUserFinancial(
      userId: $userId
      hourlyCost: $hourlyCost
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

const REMOVE_USER_FINANCIAL = gql`
  mutation removeUserFinancial($userId: ID!, $indexId: ID!) {
    removeUserFinancial(userId: $userId, indexId: $indexId)
  }
`;

const FinancialForm = ({
  userId,
  financial = {},
  addUserFinancial,
  updateUserFinancial,
  removeUserFinancial,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const { _id: indexId } = financial;
  return (
    <Formik
      key={financial._id}
      initialValues={financial}
      validate={values => {
        const errors = {};
        if (!values.hourlyCost) {
          errors.hourlyCost = 'Hourly rate is required';
        } else if (values.hourlyCost < 0) {
          errors.hourlyCost = 'Provide a positive value';
        }
        if (!values.startDate) {
          errors.startDate = 'Start date is required';
        }
        if (values.endDate && values.startDate > values.endDate) {
          errors.endDate = 'End date needs to be after the start date';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const { hourlyCost, endDate, startDate } = values;
        setSubmitting(true);

        if (financial._id) {
          await updateUserFinancial({
            variables: { userId, indexId, hourlyCost, endDate, startDate },
            refetchQueries: [
              {
                query: GET_USER,
                variables: {
                  userId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
        } else {
          await addUserFinancial({
            variables: { userId, hourlyCost, endDate, startDate },
            refetchQueries: [
              {
                query: GET_USER,
                variables: {
                  userId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          resetForm();
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, setValues, handleSubmit, isSubmitting, isValid, dirty, resetForm }) => (
        <Form onSubmit={handleSubmit} layout="inline">
          <div>
            <Form.Item
              label="Hourly Cost"
              validateStatus={errors.hourlyCost ? 'error' : null}
              hasFeedback={!!errors.hourlyCost}
              help={errors.hourlyCost ? errors.hourlyCost : null}
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={hourlyCost => setValues({ ...values, hourlyCost })}
                value={values.hourlyCost}
                disabled={isSubmitting}
                name="hourlyCost"
              />
            </Form.Item>
            <Form.Item
              label="Start Date"
              validateStatus={errors.startDate ? 'error' : null}
              hasFeedback={!!errors.startDate}
              help={errors.startDate ? errors.startDate : null}
            >
              <DatePicker
                name="startDate"
                disabled={isSubmitting}
                value={values.startDate ? moment(values.startDate, 'YYYYMMDD') : null}
                onChange={mDate =>
                  setValues({
                    ...values,
                    startDate: mDate ? mDate.format('YYYYMMDD') : null,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              validateStatus={errors.endDate ? 'error' : null}
              hasFeedback={!!errors.endDate}
              help={errors.endDate ? errors.endDate : null}
            >
              <DatePicker
                name="endDate"
                disabled={isSubmitting}
                value={values.endDate ? moment(values.endDate, 'YYYYMMDD') : null}
                onChange={mDate =>
                  setValues({
                    ...values,
                    endDate: mDate ? mDate.format('YYYYMMDD') : null,
                  })
                }
              />
            </Form.Item>
            {dirty && (
              <>
                <Form.Item>
                  <Button disabled={isSubmitting} onClick={() => resetForm()}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </>
            )}
            {financial._id && (
              <Form.Item>
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to delete this record?"
                  onConfirm={async () => {
                    setIsRemoving(true);
                    await removeUserFinancial({
                      variables: { userId, indexId },
                      refetchQueries: [
                        {
                          query: GET_USERS,
                        },
                      ],
                      awaitRefetchQueries: true,
                    });
                    // setIsRemoving(false);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="danger"
                    loading={isRemoving}
                    disabled={isSubmitting}
                    icon="close"
                  ></Button>
                </Popconfirm>
              </Form.Item>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const UserHourlyCostList = () => {
  const { loading, data } = useQuery(GET_USERS);
  const [updateUserFinancial] = useMutation(UPDATE_USER_FINANCIAL);
  const [addUserFinancial] = useMutation(ADD_USER_FINANCIAL);
  const [removeUserFinancial] = useMutation(REMOVE_USER_FINANCIAL);

  return (
    <Card
      floating
      bodyBackground="none"
      title="User Hourly Cost"
      loading={loading}
      loadingText="Loading Users"
    >
      <DataDisplay
        data={data?.admin?.users.map(user => ({ ...user, key: user._id }))}
        loading={loading}
        defaultViewType="table"
        columns={[
          {
            title: 'User',
            key: 'user',
            width: '15%',
            render: ({ imageUrl, emails, firstName, lastName, employeeType, title }) => (
              <Profile
                className="w-full"
                imageUrl={imageUrl}
                email={emails[0].address}
                firstName={firstName}
                lastName={lastName}
                employeeType={employeeType}
                title={title}
              />
            ),
          },
          {
            title: 'Hourly Cost',
            key: 'hourlyCost',
            width: '85%',
            render: ({ hr, _id: userId }) => (
              <div className="h-full" style={{ minHeight: '100px' }}>
                {hr?.financials
                  .sort((a, b) => a.startDate.localeCompare(b.startDate))
                  .map(financial => (
                    <FinancialForm
                      key={financial._id}
                      financial={financial}
                      updateUserFinancial={updateUserFinancial}
                      removeUserFinancial={removeUserFinancial}
                      userId={userId}
                    />
                  ))}
                <FinancialForm addUserFinancial={addUserFinancial} userId={userId} />
              </div>
            ),
          },
        ]}
        searchKeys={['firstName', 'lastName']}
      />
    </Card>
  );
};

export default UserHourlyCostList;
