import React from 'react';
import classNames from 'classnames';

const Page = ({ children, header, full, padded = true }) => {
  return (
    <>
      {header && <div className="p-4 border-0 border-b border-solid border-gray-300 text-xl">{header}</div>}
      <div
        className={classNames('flex justify-center', {
          'p-1 md:pt-4': padded,
          'p-1 md:px-4': padded && full,
          'p-1 md:px-2': padded && !full
        })}
      >
        <div
          className={classNames('w-full', {
            'max-w-7xl': !full,
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Page;
