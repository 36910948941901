import React, { useState } from 'react';
import { Select } from 'antd';
import { EMAIL_STATS_RECIEVER } from '../mutations';
import client from '../../../api/graphql/client';
import moment from 'moment';
import Card from '../../common/components/Card';
import { Tooltip, Icon } from 'antd';
const { Option } = Select;

const EmailStatsReciever = ({ users, emailStatsRecievers }) => {
  const { emails, updatedBy, updatedAt } = emailStatsRecievers || {};
  const [emailStatReciever, setEmailStatReciever] = useState(emails || []);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = value => {
    setEmailStatReciever(value);
    setIsLoading(true);
    client
      .mutate({
        mutation: EMAIL_STATS_RECIEVER,
        variables: {
          emails: value ? value.toString() : null,
        },
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return (
    <Card
      borderWrapper
      title="Email Stats Receivers"
      actionComponent={
        <Tooltip
          placement="topRight"
          title={
            updatedBy ? `Updated By : ${updatedBy} at ${moment(updatedAt).format('LLL')} ` : ''
          }
        >
          <Icon type="info-circle" theme="twoTone" />{' '}
        </Tooltip>
      }
    >
      <div style={{ padding: '10px', margin: '10px' }}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={emailStatReciever}
          onChange={handleChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={isLoading}
        >
          {users &&
            users.map(item => {
              const { firstName, lastName, emails } = item;
              // let firstName = firstName ? firstName : '';
              // let lastName = lastName ? lastName : '';
              return (
                <Option value={emails[0].address} key={item._id}>
                  {`${firstName} ${lastName} - ${emails[0].address}`}
                </Option>
              );
            })}
        </Select>
      </div>
    </Card>
  );
};

export default EmailStatsReciever;
