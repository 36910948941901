import { connect } from 'react-redux';
import TimesheetGridRowHour from './TimesheetGridRowHour';
import { changeTimesheetDate } from '../actions';

const mapStateToProps = state => ({
  endDate: state.timesheets.endDate,
});

const mapDispatchToProps = dispatch => ({
  handleDateChange: endDate => dispatch(changeTimesheetDate(endDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimesheetGridRowHour);
