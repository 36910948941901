import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import Card from '../../common/components/Card';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { Box } from '@mantine/core';
import Modal from '../../common/components/Modal';
import EditConfigForm from './EditConfigForm';

const Wrapper = styled.div`
  height: 600px;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }

  .edit_btn {
    color: #1890ff;
    cursor: pointer;
    /* text-align: center; */
    :hover {
      text-decoration: underline;
    }
  }
`;

function capitalizeAndRemoveDashes(inputString) {
  // Split the string into words using dashes as separators
  let words = inputString.split('-');

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together without dashes
  let result = capitalizedWords.join(' ');

  return result;
}

class ConfigurationList extends React.Component {
  gridApi = undefined;
  state = {
    isModalVisible: false,
    showActive: true,
    query: '',
  };

  onGridReady = params => {
    this.gridApi = params.api;
  };

  export = () => {
    const params = {
      fileName: `Users-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };
  render() {
    const { data, loading, handleUpdate } = this.props;
    const { isModalVisible } = this.state;

    return (
      <Card
        border
        floating
        padded={false}
        title="Configurations"
        loading={loading}
        actionComponent={
          <Modal
        width={800}
            visible={isModalVisible}
            footer={null}
            onCancel={() => this.setState({ isModalVisible: false })}
          >
            <Box pt={4}>
              {isModalVisible && (
                <EditConfigForm
                  onComplete={() => {
                    this.setState({ isModalVisible: false });
                    handleUpdate();
                  }}
                  values={this.state.formData}
                />
              )}
            </Box>
          </Modal>
        }
      >
        <Wrapper className="ag-theme-balham">
          <AgGridReact modules={AllCommunityModules} rowData={data} enableCellTextSelection
           defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
          >
            <AgGridColumn
              field="key"
              width={250}
              cellRendererFramework={({ data }) => <p>{capitalizeAndRemoveDashes(data.key)}</p>}
            />
            <AgGridColumn field="value" width={250} />
            <AgGridColumn field="description" width={800} />
            <AgGridColumn
              field="Actions"
              cellRendererFramework={({ data }) => (
                <p
                  className="edit_btn"
                  onClick={() => this.setState({ formData: data, isModalVisible: true })}
                >
                  Edit
                </p>
              )}
            />
          </AgGridReact>
        </Wrapper>
      </Card>
    );
  }
}

export default ConfigurationList;
