import Layout from 'antd/lib/layout';
import styled from 'styled-components';
import { fadeIn } from '../../../../globalStyles';

const StyledLayout = styled(Layout)`
  background: initial;
  color: initial;
  padding-bottom: 0;
  > * {
    animation: ${fadeIn} 0.2s linear;
  }
`;

export default StyledLayout;
