import { CHANGE_TIMESHEET_DATE, GET_TIMESHEET, STORE_TIMESHEET_TO_STATE } from './actions.js';

const reducers = (state = {}, action) => {
  switch (action.type) {
    case STORE_TIMESHEET_TO_STATE:
      return {
        timesheet: action.timesheet,
      };
    case CHANGE_TIMESHEET_DATE:
      return {
        endDate: action.endDate,
      };
    case GET_TIMESHEET:
      return {
        endDate: action.endDate,
      };
    default:
      return state;
  }
};

export default reducers;
