import styled from 'styled-components';
import Popover from 'antd/lib/popover';

const StyledPopover = styled(Popover)`
  > .ant-popover-inner-content {
    padding: 0;
  }
`;

export default StyledPopover;
