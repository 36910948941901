import React from 'react';
import { Query } from 'react-apollo';
import ManualTimesheet from './ManualTimesheet';
import TimesheetLoading from '../../timesheets/components/TimesheetLoading';
import { GET_USER_TIMESHEET } from '../../timesheets/queries';

const ManualTimesheetContainer = ({ endDate , user }) => (
  <Query query={GET_USER_TIMESHEET} variables={{ endDate , user }}>
    {({ loading, error, data }) => {
      if (error) return <div>error</div>;
      if (loading || !data) return <TimesheetLoading />;
      return <ManualTimesheet timesheet={data.timesheet} endDate={endDate} user={user} />;
    }}
  </Query>
);

export default ManualTimesheetContainer;