import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import Button from '../../common/components/Button';
import MissingTimesheetsReportGrid from './MissingTimesheetsReportGrid';
import useEndDateStore from '../../common/stores/useEndDateStore';

export const MISSING_TIMESHEETS_QUERY = gql`
  query getMissingTimesheetsByWeek($endDate: String) {
    finance {
      missingTimesheets(endDate: $endDate)
    }
  }
`;

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const constructGridData = data => {
  const timesheets = data?.finance?.missingTimesheets || [];

  return timesheets.map(timesheet => {
    return {
      ...timesheet,
      endDate: moment(timesheet.endDate).format('MM/DD/YYYY'),
    };
  });
};

const MissingTimesheetReport = () => {
  const { endDate, setEndDate } = useEndDateStore();
  const { loading, data } = useQuery(MISSING_TIMESHEETS_QUERY, {
    variables: {
      startDate: moment(endDate).subtract(7, 'days').format('YYYYMMDD'),
      endDate,
    },
  });
  const gridData = constructGridData(data);

  return (
    <Card
      loading={loading}
      border
      title="Missing Timesheets By Week"
      floating
      padded={false}
      actionComponent={
        <div className="flex">
          <WeekPicker
            loading={loading}
            endDate={moment(endDate).format('YYYYMMDD')}
            onDateChange={endDate => {
              const endDateString = moment(endDate).format('YYYYMMDD');
              setEndDate(endDateString);
            }}
          />
          <div className="pl-2">
            <Button onClick={handleExportClick}>Export</Button>
          </div>
        </div>
      }
    >
      <MissingTimesheetsReportGrid endDate={endDate} onExportReady={onExportReady} rowData={gridData} />
    </Card>
  );
};

export default MissingTimesheetReport;
