import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import UserDetails from './UserDetails';
import { ADMIN_GET_USER_BY_ID } from '../queries';

const UserDetailsContainer = ({ match }) => {
  const { userId } = match.params;
  return (
    <Query query={ADMIN_GET_USER_BY_ID} variables={{ userId }}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        const user = get(data, 'admin.user');
        return (
          <UserDetails title="All Users" data={{ user }} loading={loading} />
        );
      }}
    </Query>
  );
};

export default withRouter(UserDetailsContainer);
