import { useState } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import { datadogRum } from '@datadog/browser-rum';
import TopBar from '../modules/topbar/components/TopBar';
import SideBar from '../modules/sidebar/components/SideBar';
import TimesheetsPage from '../modules/timesheets/components/TimesheetsPage';
import ProjectManagerPage from '../modules/project-manager/components/ProjectManagerPage';
import FinancePage from '../modules/finance/components/FinancePage';
import AdminPage from '../modules/admin/components/AdminPage';
import SuperAdminPage from '../modules/super-admin/components/Page';
import ProfilePage from '../modules/profile/components/ProfilePage';
import PerformancePage from '../modules/performance/components/PerformancePage';
import HumanResourcesPage from '../modules/human-resources/components/HumanResourcesPage';
import TextLoader from '../modules/common/components/TextLoader';
// import FeedbackButton from '../modules/feedback/components/FeedbackButton';
import { fadeIn, GlobalStyle } from '../globalStyles';
import VaccineTrackerPage from '../modules/vaccine-tracker/components/VaccineTrackerPage';

export const ME = gql`
  fragment MenuItem on MenuItem {
    label
    iconName
    linkUrl
    overrideMatchUrl
  }
  {
    me {
      _id
      firstName
      lastName
      title
      imageUrl
      emails {
        address
      }
      groups
      employeeType
      menu {
        ...MenuItem
        children {
          ...MenuItem
        }
      }
      inviteToBeta
    }
  }
`;

const Wrapper = styled.div`
  background-color: #f4f6f8;
  display: flex;
  justify-content: flex-start;
  // height: 100vh;
  overflow-x: auto;
  flex-direction: column;
`;

const Layout = styled.div`
  /* width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column; */
  ${props =>
    props.isOffline &&
    `
  opacity: .5;
  pointer-events: none;
  user-select: none;`}
`;

const MainPane = styled.div`
  /* display: flex; */
  transition: opacity 200ms;
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  # min-width: ${props => (props.sidebarCollapsed ? 1224 - props.sidebarWidth : 1224)}px;
  transition: min-width 200ms 300ms;
  border-bottom: 1px solid #dfe3e8;
  background-color: #121826;
`;

const Side = styled.div`
  background-color: white;
  animation: ${fadeIn} 0.2s linear;
  ${props => (props.collapsed ? 'min-width: 0px;' : `min-width: ${props.width}px;`)}
  ${props => (props.collapsed ? 'width: 0px;' : `width: ${props.width}px;`)}
  ${props => props.collapsed && 'opacity: 0;'}
 max-width: ${props => props.width}px;
  transition: min-width 200ms 300ms, opacity 200ms;
  ${props => !props.collapsed && 'transition: opacity 200ms 300ms, min-width 200ms;'}
  border-right: 1px solid #dfe3e8;
`;

const Content = styled.div`
  /*max-width: ${props =>
    props.sidebarCollapsed ? props.maxWidth + props.sidebarWidth : props.maxWidth}px;
  min-width: ${props => props.minWidth}px;
  width: calc(100vw - ${props => (props.sidebarCollapsed ? 0 : props.sidebarWidth)}px); 
  transition: all 200ms;
  min-height: calc(100vh - 75px); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  overflow-y: auto;
  height: calc(100vh - 75px);
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 75px);
`;

const sidebarWidth = 230;
// const contentMaxWidth = 1115;
// const contentMinWidth = 970;

const MainLayout = ({ userTempPasswordCheck, pathname, search }) => {
  const [config] = useGlobal('config');
  const [user, setUser] = useGlobal('user');
  const [sidebarCollapsed, setSidebarCollapsed] = useState('sidebarCollapsed');
  const { loading, data } = useQuery(ME);
  const [networkStatus] = useGlobal('networkStatus');
  const { isOffline } = networkStatus;

  // useIdleTimer({
  //   timeout: 1000 * 60 * 30,
  //   onIdle: () => window.location.reload(),
  //   // onActive: handleOnActive,
  //   // onAction: handleOnAction,
  //   debounce: 500,
  // });

  // useEffect(() => {
  //   setTimeout(() => setSidebarCollapsed(!sidebarCollapsed), 3000);
  // }, []);

  let hasTempPassword;
  if (userTempPasswordCheck) {
    hasTempPassword = userTempPasswordCheck.profile.tempPassword;
  }

  if (data?.me && !user) {
    setUser(data.me);
    datadogRum.setUser({
      id: data?.me._id,
      name: `${data?.me.firstName} ${data?.me.lastName}`,
      email: data?.me.emails[0].address,
    });
  }

  if (loading || !user) {
    return <TextLoader text="Loading user data" />;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{`${config['company-short-name']} Time Tracker`}</title>
        <link rel="icon" href={`${config['favicon-url']}`} sizes="32x32" />
      </Helmet>
      <GlobalStyle />
      <ReactResizeDetector
        handleWidth
        onResize={width => {
          if (width < 800 && !sidebarCollapsed) {
            setSidebarCollapsed(true);
          }

          if (width > 800 && sidebarCollapsed) {
            setSidebarCollapsed(false);
          }
        }}
      />
      <Top sidebarCollapsed={sidebarCollapsed || hasTempPassword} sidebarWidth={sidebarWidth}>
        <TopBar
          // toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
          sidebarCollapsed={sidebarCollapsed || hasTempPassword}
        />
      </Top>
      <Layout isOffline={isOffline} className="flex w-full h-full justify-center">
        <MainPane className="flex w-full">
          <Side
            // className={classNames({
            //   'absolute z-50 top-0 bottom-0': true,
            // })}
            collapsed={sidebarCollapsed || hasTempPassword}
            width={sidebarWidth}
          >
            <SideBar />
          </Side>
          <Content
            sidebarCollapsed={sidebarCollapsed || hasTempPassword}
            // sidebarWidth={sidebarWidth}
            // maxWidth={contentMaxWidth}
            // minWidth={contentMinWidth}
          >
            <Switch>
              <Route exact path="/user" render={() => <Redirect to="/user/timesheets" />} />
              <Route exact path="/user/timesheets" component={TimesheetsPage} />
              <Route exact path="/user/timesheets/:endDate" component={TimesheetsPage} />
              <Route exact path="/user/profile" component={ProfilePage} />
              <Route path="/project-management" component={ProjectManagerPage} />
              <Route
                exact
                path="/vaccine-tracker"
                render={() => <Redirect to="/vaccine-tracker/proofs" />}
              />
              <Route exact path="/vaccine-tracker/proofs" component={VaccineTrackerPage} />
              <Route exact path="/vaccine-tracker/report" component={VaccineTrackerPage} />
              <Route path="/finance" component={FinancePage} />
              <Route path="/admin" component={AdminPage} />
              <Route path="/super-admin" component={SuperAdminPage} />
              <Route path="/performance" component={PerformancePage} />
              <Route path="/human-resources" component={HumanResourcesPage} />
            </Switch>
          </Content>
        </MainPane>
      </Layout>
      {/* <FeedbackButton /> */}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  userTempPasswordCheck: state.meteor.user,
  pathname: get(state, 'router.location.pathname'),
  search: get(state, 'router.location.search'),
});

export default connect(mapStateToProps)(MainLayout);
