import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import { trpc } from '../../../utils/trpc';
import NewClientForm from './NewClientForm';
import EditClientForm from './EditClientForm';

const Clients = () => {
  const { data: clients, isLoading } = trpc.useQuery(['client.all'], {
    refetchOnWindowFocus: false,
  });

  return (
    // @ts-ignore
    <Card border title="Clients" floating actionComponent={<NewClientForm />}>
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Short Name',
            dataIndex: 'shortName',
            key: 'shortName',
          },
          {
            title: 'Customer Name',
            render: client => {
              // @ts-ignore
              return client?.quickbooks?.customerName;
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: client => (
              <EditClientForm
                clientId={client.id}
                defaultValues={{
                  name: client.name,
                  shortName: client.shortName,
                  quickbooksCustomerName: client.quickbooks?.customerName,
                }}
              />
            ),
          },
        ]}
        loading={isLoading}
        dataSource={clients?.map((client:any) => ({ ...client, key: client.id }))}
        pagination={false}
      />
    </Card>
  );
};

export default Clients;
