import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Drawer } from 'antd';
import Tabs, { TabPane } from '../../common/components/Tabs';
// import Modal from '../../common/components/Modal';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import ProjectTemplateRoles from './ProjectTemplateRoles';
import ProjectTemplateDeliverables from './ProjectTemplateDeliverables';
import UpdateProjectTemplateForm from '../../common/components/UpdateProjectForm/UpdateProjectTemplateForm';
import TextLoader from '../../common/components/TextLoader';
import ProjectTemplateTeam from '../../project-manager/components/ProjectDetailsResourceList.js';

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

const GET_PROJECT_BY_ID = gql`
  query getProjectById($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        accountingCode
        projectCode
        name
        description
        updatedAt
        archived
        client {
          _id
          shortName
          name
        }
        endClient {
          _id
          shortName
          name
        }
        roles {
          _id
          name
        }
        resources {
          _id
        }
        deliverables {
          _id
        }
        # timesheets {
        #   _id
        #   status
        # }
      }
    }
  }
`;

const ProjectTemplateDetails = () => {
  let { projectId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const { loading, data } = useQuery(GET_PROJECT_BY_ID, { variables: { projectId } });

  const project = data?.projectManagement?.project || null;

  if (loading && !project) return <TextLoader text="Loading Template Details" />;

  return (
    <Flex flexDirection="column" pb="6rem">
      <Card
        title={project.name}
        padded
        borderWrapper
        actionComponent={<Button onClick={() => setShowModal(true)}>Update Template</Button>}
      >
        <div>{project.description}</div>
        <Drawer
          title="Update Project Template"
          width="50%"
          visible={showModal}
          destroyOnClose
          onClose={() => setShowModal(false)}
          footer={null}
        >
          <UpdateProjectTemplateForm
            onComplete={() => setShowModal(false)}
            currentProject={project}
            refetchQueries={[{ query: GET_PROJECT_BY_ID, variables: { projectId } }]}
          />
        </Drawer>
      </Card>
      <Box width={1} mt=".5rem">
        <Card border fadeIn withShadow padded={false} floating>
          <StyledTabs>
            <TabPane tab="Deliverables" key="deliverables">
              <ProjectTemplateDeliverables projectId={projectId} />
            </TabPane>
            <TabPane tab="Roles" key="roles">
              <ProjectTemplateRoles projectId={projectId} />
            </TabPane>
            <TabPane tab="Team" key="team">
              <ProjectTemplateTeam projectId={projectId} roles={project.roles} />
            </TabPane>
          </StyledTabs>
        </Card>
      </Box>
    </Flex>
  );
};

export default ProjectTemplateDetails;
