import React from 'react';
import styled from 'styled-components';
import { TextArea } from '../../common/components/Input';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import Popover from '../../common/components/Popover';

const StyledButton = styled(Button)`
  margin-top: 1em;
`;

class DeclinePopover extends React.Component {
  state = {
    comments: null,
    hasCancelled: false,
    shouldBeCancelBtn: this.props.cancelPopover === 'true',
    visible: false,
  };

  handleClick = async () => {
    // decline and provide comments
    await this.props.decline(this.state.comments);
    this.setState({ comments: null, hasCancelled: true, visible: false });
  };

  handleChange = e => this.setState({ comments: e.target.value });

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const {
      visible, shouldBeCancelBtn, hasCancelled, comments,
    } = this.state;
    const { disabled, loading, btnClassName } = this.props;
    return (
      <Popover
        trigger="click"
        visible={visible}
        placement="left"
        onVisibleChange={this.handleVisibleChange}
        title={shouldBeCancelBtn ? 'Cancel Reason' : 'Decline Reason'}
        content={(
          <div>
            <TextArea
              onChange={this.handleChange}
              rows={4}
              disabled={!shouldBeCancelBtn && disabled}
              value={comments}
            />
            <StyledButton
              block
              loading={loading}
              disabled={shouldBeCancelBtn ? hasCancelled : disabled}
              onClick={this.handleClick}
            >
              {shouldBeCancelBtn ? 'Cancel' : 'Decline'}
            </StyledButton>
          </div>
)}
      >
        <Tooltip title={shouldBeCancelBtn ? 'Cancel' : 'Decline'} trigger="hover">
          <Button
            className={btnClassName}
            loading={loading}
            disabled={shouldBeCancelBtn ? hasCancelled : disabled}
            shape="circle"
            icon="close"
          />
        </Tooltip>
      </Popover>
    );
  }
}

export default DeclinePopover;
