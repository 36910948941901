import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

const history = createHistory();

history.listen(location => {
  // eslint-disable-next-line no-undef
  if (!window.location.host.includes('localhost')) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

export default history;
