import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ManualTimesheetCard from './ManualTimesheetCard';
import { changeTimesheetDate } from '../../timesheets/actions';

const mapStateToProps = state => {
    return {
        endDate: state.timesheets.endDate,
    }
  
};

const mapDispatchToProps = dispatch => ({
  changeTimesheetDate: endDate => dispatch(changeTimesheetDate(endDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ManualTimesheetCard));