import React from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TextLoader from '../../common/components/TextLoader';
import AllUsersReport from './AllUsersReport';
import { HR_ALL_USERS } from '../queries';

const AllUsersCard = ({ data }) => {
  return (
    <Query query={HR_ALL_USERS}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        if (loading) {
          return <TextLoader text="Loading Report" />;
        }
        const users = get(data, 'activeStaff');
        return <AllUsersReport data={users} />;
      }}
    </Query>
  );
};

export default AllUsersCard;
