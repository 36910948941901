import { USER_UPDATE } from './actions';

const reducers = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE: {
      return {
        ...state,
        user: action.user,
        loggedIn: action.loggedIn,
        loggingIn: action.loggingIn,
        loggingOut: action.loggingOut,
      };
    }
    default:
      return state;
  }
};

export default reducers;