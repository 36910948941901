import React from 'react';
import { useMutation } from 'react-apollo';
import { Formik } from 'formik';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input, { TextArea } from '../../common/components/Input';
import { CREATE_PROJECT_TEMPLATE } from '../mutations';

const NewProjectTemplateForm = ({ onComplete }) => {
  const [addProjectTemplate] = useMutation(CREATE_PROJECT_TEMPLATE);

  return (
    <Formik
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.email = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        try {
          const { data } = await addProjectTemplate({
            variables: {
              ...values,
            },
          });
          const projectId = data.createProject._id;
          onComplete(projectId);
        } catch (error) {
          console.log({ error });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item
            label="Name"
            hasFeedback
            validateStatus={errors.name && touched.name && 'error'}
            help={errors.name && touched.name && errors.name}
            required
          >
            <Input
              type="name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            hasFeedback
            validateStatus={errors.description && touched.description && 'error'}
            help={errors.description && touched.description && errors.description}
          >
            <TextArea
              type="description"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Create Project Template
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default NewProjectTemplateForm;
