import React from 'reactn';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import TimesheetCreate from '../../timesheets/components/TimesheetCreate';
import TimesheetGrid from '../../timesheets/components/TimesheetGrid';
import TimesheetTimeline from '../../timesheets/components/TimesheetTimeline';
import Tag from '../../common/components/Tag';
import Button from '../../common/components/Button';
import Popconfirm from '../../common/components/Popconfirm';
import { Checkbox, Alert } from 'antd';
import { getColorByStatus } from '../../common';
import { GET_USER_TIMESHEET } from '../../timesheets/queries';
import { SUBMIT_MANUAL_TIMESHEET } from '../mutations';
import { RECALL_TIMESHEET } from '../../timesheets/mutations';
import TimesheetApprovers from '../../project-manager/components/TimesheetApprovers';

const Wrapper = styled.div`
  background-color: white;
  padding: 1em;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TimesheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const WeekOf = styled.div`
  font-size: 1.7em;
  font-weight: 300;
  width: 100%;
  margin-right: 1em;
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const HoursValue = styled.span`
  font-size: 1.7em;
  ${props => props.highlightColor && `color: ${props.highlightColor}`};
`;

const SubLabel = styled.span`
  color: #bdbdbd;
  font-weight: 300;
`;

class ManualTimesheet extends React.Component {
  state = {
    submitLoading: false,
    recallLoading: false,
    regularApproval: false,
  };

  submitTimesheet = () => {
    this.setState({ submitLoading: true });
    this.props.client
      .mutate({
        mutation: SUBMIT_MANUAL_TIMESHEET,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate: this.props.endDate,
              user: this.props.user || null,
            },
          },
        ],
        variables: {
          timesheetId: this.props.timesheet._id,
          regularApproval: this.state.regularApproval,
          user: this.props.user || null,
        },
      })
      // componented will be unmounted so no need to call setState
      .then(() => this.setState({ submitLoading: false }));
  };

  recallTimesheet = () => {
    this.setState({ recallLoading: true });
    this.props.client
      .mutate({
        mutation: RECALL_TIMESHEET,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: {
              endDate: this.props.endDate,
              user: this.props.user || null,
            },
          },
        ],
        variables: {
          timesheetId: this.props.timesheet._id,
        },
      })
      // componented will be unmounted so no need to call setState
      .then(() => {
        this.setState({ recallLoading: false });
        this.setState({ regularApproval: false });
      });
  };

  renderTotalHours = hours => {
    let highlightColor;
    if (hours > 40) {
      highlightColor = 'orange';
    }
    if (hours > 50) {
      highlightColor = 'red';
    }
    return (
      <HoursWrapper>
        <HoursValue highlightColor={highlightColor}>{hours}</HoursValue>
        <SubLabel>hours</SubLabel>
      </HoursWrapper>
    );
  };

  render() {
    const { timesheet, endDate, user, readOnly } = this.props;
    if (!timesheet) return <TimesheetCreate endDate={endDate} user={user || null} />;
    const { isLocked, timeline, documentUrl } = timesheet;
    return (
      <Wrapper>
        <TimesheetHeader>
          <WeekOf>
            {`${moment(timesheet.startDate).format('MMMM Do')} - ${moment(timesheet.endDate).format(
              'MMMM Do',
            )}`}
          </WeekOf>
          {documentUrl && (
            <Button href={documentUrl} target="_blank" icon="cloud-download">
              Download
            </Button>
          )}
          {timeline && timeline.length > 0 && (
            <TimesheetTimeline timeline={timeline} status={timesheet.status} />
          )}
          <div>
            <Tag color={getColorByStatus(timesheet.status)}>{timesheet.status}</Tag>
          </div>
          {this.renderTotalHours(timesheet.totalHours)}
        </TimesheetHeader>
        <div className="flex">
          {timeline && timeline.length > 0 && (
            <div style={{ margin: '5px' }}>
              <TimesheetTimeline timeline={timeline} status={timesheet.status} />
            </div>
          )}
          <div style={{ margin: '5px' }}>
            <Tag color={getColorByStatus(timesheet.status)}>{timesheet.status}</Tag>
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <div className="text-right text-lg font-medium mb-2 -mt-8">Approvers</div>
          <div className="flex justify-end gap-2">
            <TimesheetApprovers approvalsRequired={timesheet.approvalsRequired} />
          </div>
        </div>
        <TimesheetGrid
          user={user || null}
          endDate={timesheet.endDate}
          timesheetId={timesheet._id}
          lineItems={timesheet.lineItems}
          isLocked={isLocked || readOnly || this.state.submitLoading}
        />
        <SubmitButtonContainer>
          {!readOnly && !isLocked && timesheet.lineItems.length > 0 && (
            <>
              {timesheet.status === 'Not Submitted' && (
                <div style={{ padding: '10px' }}>
                  <Checkbox onChange={e => this.setState({ regularApproval: e.target.checked })}>
                    Follow regular approval process
                  </Checkbox>
                </div>
              )}
              {['Not Submitted', 'Declined', 'Cancelled'].includes(timesheet.status) && (
                <Popconfirm
                  title="Submit timesheet for approval?"
                  onConfirm={this.submitTimesheet}
                  placement="left"
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    loading={this.state.submitLoading || isLocked}
                    disabled={this.global.loading}
                    type="primary"
                    icon="upload"
                  >
                    Submit
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
          {!readOnly &&
            (timesheet.status === 'Submitted-Pending' || timesheet.status === 'Approved') && (
              <Button loading={this.state.recallLoading} onClick={this.recallTimesheet} icon="undo">
                Recall Timesheet
              </Button>
            )}
        </SubmitButtonContainer>
        {timesheet.status === 'Not Submitted' && timesheet.lineItems.length > 0 && (
          <div style={{ margin: '10px', paddingBottom: '80px' }}>
            <Alert
              showIcon
              message="Disclaimer"
              description={
                this.state.regularApproval
                  ? 'Emails will be triggered according to the regular approval process'
                  : 'No emails will be triggered when submitted'
              }
              type="warning"
              style={{ width: '50%', float: 'right', padding: '10px 10px 10px 60px' }}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default withApollo(ManualTimesheet);
