import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, Alert } from 'antd';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import Popconfirm from '../../common/components/Popconfirm';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;

const TimesheetListActions = ({ timesheet, cancel, deleteTimesheet, refetch }) => {
  const { status, endDate, _id: timesheetId, documentUrl } = timesheet;
  const [isLoading, setIsLoading] = useState(false);
  const [lockEditing] = useGlobal('lockEditing');
  return (
    <ButtonsWrapper>
      <Tooltip title="Open Timesheet" trigger="hover">
        <Link to={`/user/timesheets/${endDate}`}>
          <Button>Open</Button>
        </Link>
      </Tooltip>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="details">
              <a
                href={`/reports/timesheets/${timesheetId}?options=project-filter`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Details
              </a>
            </Menu.Item>
            {documentUrl && (
              <Menu.Item key="download">
                <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </Menu.Item>
            )}
            <Menu.Item
              key="cancel"
              disabled={
                ['Cancelled', 'Not Submitted'].includes(status) ||
                (timesheet.status === 'Approved' && timesheet.approvalsRequired?.length > 0)
              }
              onClick={async () => {
                setIsLoading(true);
                await cancel();
                await refetch();
                setIsLoading(false);
              }}
            >
              Cancel
            </Menu.Item>
            <Menu.Item disabled={lockEditing || ['Approved', 'Submitted'].includes(status)}>
              <Popconfirm
                title={
                  <Alert
                    message="Warning: Are you sure you want to delete this timesheet ?"
                    type="warning"
                  />
                }
                onConfirm={async () => {
                  setIsLoading(true);
                  await deleteTimesheet();
                  await refetch();
                }}
                placement="left"
                okText="Yes"
                cancelText="No"
              >
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        }
      >
        <Button shape="circle" icon="ellipsis" loading={isLoading} />
      </Dropdown>
    </ButtonsWrapper>
  );
};

export default TimesheetListActions;
