import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardContent = styled.div`
  min-height: 100px;
`;
const Card = ({ children, className, to = '/' }) => {
  return (
    <Link to={to} className="p-2 w-1/3">
      <CardContent
        className={classnames(
          'bg-white shadow hover:shadow-lg transition-shadow duration-150 cursor-pointer border border-solid border-l-0 border-r-0 border-b-0 border-t-4 rounded-md p-3 flex justify-center items-center text-black',
          className,
        )}
      >
        {children}
      </CardContent>
    </Link>
  );
};

const Dashboard = () => {
  return (
    <div className="flex flex-wrap">
      <Card className="border-red-200">
        <div className="font-light text-2xl">Accounts AP & AR</div>
      </Card>
      <Card to="/performance/resource-utilization" className="border-green-200">
        <div className="font-light text-2xl">Resource Utilization</div>
      </Card>
      <Card className="border-green-200">
        <div className="font-light text-2xl">Business Development</div>
      </Card>
      <Card className="border-orange">
        <div className="font-light text-2xl">Yearly Forecasts</div>
      </Card>
      <Card className="border-red-200">
        <div className="font-light text-2xl">Flow of RFPs</div>
      </Card>
      <Card className="border-green-200">
        <div className="font-light text-2xl">HV Staffing Contracts</div>
      </Card>
      <Card className="border-green-200">
        <div className="font-light text-2xl">Payroll Data & Employees</div>
      </Card>
      <Card className="border-orange">
        <div className="font-light text-2xl">Vendor Payments</div>
      </Card>
      <Card className="border-orange">
        <div className="font-light text-2xl">Cash & Payments Report</div>
      </Card>
    </div>
  );
};

export default Dashboard;
