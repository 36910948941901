import React from 'react';
import { useGlobal } from 'reactn';
import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import gql from 'graphql-tag';
import TimesheetReport from './TimesheetReport';
import { GET_TIMESHEET_BY_ID } from '../queries';

const TimesheetContainer = ({ match, location }) => {
  const [state] = useGlobal();
  const skipUserQuery = !state.jwt;
  const { data } = useQuery(
    gql`
      query {
        me {
          projectsThatIManage {
            _id
          }
        }
      }
    `,
    {
      skip: skipUserQuery,
    },
  );

  const myProjectIds = data?.me?.projectsThatIManage?.map(project => project._id) || [];
  const { timesheetId } = match.params;
  const queryParams = queryString.parse(location.search);
  const { projectId = null } = queryParams;
  return (
    <Query query={GET_TIMESHEET_BY_ID} variables={{ timesheetId, projectId }}>
      {({ loading, error, data }) => {
        if (error || loading) return null;
        const { timesheet } = data.reports;
        if (!timesheet) return null;
        return <TimesheetReport timesheet={timesheet} queryParams={queryParams} myProjectIds={myProjectIds} />;
      }}
    </Query>
  );
};

export default withRouter(TimesheetContainer);
