import React from 'react';
import styled from 'styled-components';
import Spin from '../../common/components/Spin';

const Wrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimesheetLoading = () => (
  <Wrapper>
    <Spin tip="Loading Timesheet Data" />
  </Wrapper>
);

export default TimesheetLoading;
