import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import TextLoader from '../../common/components/TextLoader';
import { useMutation } from '@apollo/react-hooks';
import {
  EMAIL_STATS_RECEIVERS,
  GET_TIMESHEET_THRESHOLD,
  GET_WEEKLY_REMINDER_EMAIL,
  UPDATE_SNAPSHOTS,
} from '../queries';
import { Button, InputNumber, Input } from 'antd';
import CronConfigContainer from './CronConfigContainer';
import EmailStatsRecieverContainer from './EmailStatsRecieverContainer';
import TimesheetThresholdRuleContainer from './TimesheetThresholdRuleContainer';
import Card from '../../common/components/Card';
import { get } from 'lodash';

const Settings = () => {
  const [snapshotsUpdated, setSnapshotsUpdates] = useState(null);
  const [snapshotLimit, setSnapsthoLimit] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [timesheetId, setTimesheetId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateSnapshots] = useMutation(UPDATE_SNAPSHOTS);
  return (
    <div>
      <div className="flex gap-2">
        <Card title="Timesheet Settings" border padded={false} floating className="w-1/2">
          <Query query={GET_TIMESHEET_THRESHOLD}>
            {({ loading, error, data }) => {
              if (error) throw new Error(error);
              if (loading) {
                return <TextLoader text="Loading" />;
              }
              const thresholdDetails = get(data, 'timesheetThreshold');
              return <TimesheetThresholdRuleContainer thresholdDetails={thresholdDetails} />;
            }}
          </Query>
        </Card>
        <Card floating title="Snapshots" className="w-1/2" border padded>
          <div className="flex flex-col gap-2">
            <InputNumber
              className="w-full"
              placeholder="Specify how many snapshots to update"
              onChange={value => setSnapsthoLimit(value)}
            />
            <Input
              className="w-full"
              placeholder="Specify project ID to update snapshots"
              onChange={e => setProjectId(e.target.value)}
            />
            <Input
              className="w-full"
              placeholder="Specify a timesheet ID to update snapshot for"
              onChange={e => setTimesheetId(e.target.value)}
            />
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true);
                const { data } = await updateSnapshots({
                  variables: {
                    limit: snapshotLimit || 10,
                    projectId,
                    timesheetId,
                  },
                });
                setSnapshotsUpdates(data.updateTimesheetSnapshots);
                setLoading(false);
              }}
            >
              Update Snapshot
            </Button>
            {snapshotsUpdated !== null && <div>{`${snapshotsUpdated} snapshot updated.`}</div>}
            <div>A snapshot for a single timesheet can only be updated every 30 minutes.</div>
          </div>
        </Card>
      </div>
      <Card floating bodyBackground={null} title="Reminder Emails">
        <div className="flex w-full flex-wrap">
          <div className="w-full">
            <Query query={EMAIL_STATS_RECEIVERS}>
              {({ loading, error, data }) => {
                if (error) throw new Error(error);
                if (loading) {
                  return <TextLoader text="Loading" />;
                }
                const emailStatsRecievers = get(data, 'admin.emailStatsRecievers');
                const users = get(data, 'admin.users');
                return (
                  <EmailStatsRecieverContainer
                    users={users}
                    emailStatsRecievers={emailStatsRecievers}
                  />
                );
              }}
            </Query>
          </div>
          <div className="w-full">
            <Query query={GET_WEEKLY_REMINDER_EMAIL}>
              {({ loading, error, data }) => {
                if (error) throw new Error(error);
                if (loading) {
                  return <TextLoader text="Loading" />;
                }
                const weeklyReminderEmailJob = get(data, 'weeklyReminderEmailJob');
                return <CronConfigContainer weeklyReminderEmailJob={weeklyReminderEmailJob} />;
              }}
            </Query>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Settings;
