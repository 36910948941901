import Card from '../../common/components/Card'
import Button from '../../common/components/Button'
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const RESUBMIT_TIMESHEETS = gql`
  mutation ResubmitTimesheets($userId: String!) {
    resubmitTimesheets(input: { userId: $userId })
  }
`;

interface IProps {
  userId: string;
}

const ResubmitTimesheets = ({ userId }: IProps) => {
  const [resubmitTimesheets, {
    loading,
  }] = useMutation(RESUBMIT_TIMESHEETS);

  // @ts-ignore
  return <Card
    title="Resubmit Timesheets"
    floating
    actionComponent={
      <div>
        <Button
          loading={loading}
          onClick={async () => {
            console.log('Reset User Password')
            await resubmitTimesheets({
              variables: {
                userId
              }
            })
          }}
        >
          Resubmit Timesheets
        </Button>
      </div>
    }
  />
}

export default ResubmitTimesheets;