import { create, SetState } from "zustand";

type ConfigStoreState = {
  config: any | undefined;
  setConfig: (config: any) => void;
};

const useConfig = create<ConfigStoreState>((set: SetState<ConfigStoreState>) => ({
  config: undefined,
  setConfig: (config: any) => set({ config }),
}));

export default useConfig;
