import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, Input, Button, Select, Divider } from 'antd';
import classnames from 'classnames';
import { trpc } from '../../../utils/trpc';
import Card from '../../common/components/Card';
// import AutoForm from '../../common/components/AutoForm';

// const { Option } = Select;

type UserDetailsFormProps = {
  userId: string;
};

const schema = z.object({
  firstName: z.string().min(1, 'First name is required').trim(),
  lastName: z.string().min(1, 'Last name is required').trim(),
  title: z.string().trim().nullish(),
  payrollId: z.string().trim().nullish(),
  role: z.string().nullish(),
  employeeType: z.string().nullish(),
  quickbooksEmployeeName: z.string().trim().nullish(),
  quickbooksPayrollItem: z.string().trim().nullish(),
});

const UserDetailsForm = ({ userId }: UserDetailsFormProps) => {
  const userQuery = trpc.useQuery(['users.profileById', { id: userId }], {
    refetchOnWindowFocus: false,
  });
  const taxonomyQuery = trpc.useQuery(['taxonomy.byName', { name: 'Roles' }], {
    refetchOnWindowFocus: false,
  });
  const editMutation = trpc.useMutation(['users.edit']);
  const utils = trpc.useContext();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: userQuery.data?.profile?.firstName,
      lastName: userQuery.data?.profile?.lastName,
      payrollId: userQuery.data?.profile?.payrollId,
      title: userQuery.data?.profile?.title,
      role: userQuery.data?.profile?.role,
      employeeType: userQuery.data?.profile?.employeeType,
      quickbooksPayrollItem: userQuery.data?.quickbooks?.payrollItem,
      quickbooksEmployeeName: userQuery.data?.quickbooks?.employeeName,
    },
  });

  useEffect(() => {
    // reset form with user data
    if (userQuery.data) {
      reset({
        firstName: userQuery?.data?.profile?.firstName,
        lastName: userQuery?.data?.profile?.lastName,
        title: userQuery?.data?.profile?.title,
        payrollId: userQuery?.data?.profile?.payrollId,
        role: userQuery?.data?.profile?.role,
        employeeType: userQuery.data?.profile?.employeeType,
        quickbooksPayrollItem: userQuery.data?.quickbooks?.payrollItem,
        quickbooksEmployeeName: userQuery.data?.quickbooks?.employeeName,
      });
    }
  }, [reset, userQuery.data]);

  return (
    // @ts-ignore
    <Card title="Edit Information" floating border padded>
      {/* <AutoForm
        onSubmit={(d: any) => {
          console.log({ d });
          // editMutation.mutate(
          //   {
          //     id: userId,
          //     firstName: d.firstName,
          //     // lastName: d.lastName,
          //     // title: d.title,
          //     // role: d.role,
          //     // payrollId: d.payrollId,
          //   },
          //   {
          //     onSuccess: () => {
          //       utils.invalidateQueries(['user.profileById', { id: userId }]);
          //     },
          //   },
          // );
        }}
        schema={schema}
        isLoading={editMutation.isLoading}
        isFetching={userQuery.isFetching}
        defaultValues={{
          firstName: userQuery.data?.profile?.firstName,
        }}
        values={{
          firstName: userQuery.data?.profile?.firstName,
        }}
        fields={[
          {
            className: 'w-full',
            label: 'First Name',
            name: 'firstName',
          },
        ]}
      /> */}
      <form
        className={classnames('flex flex-wrap transition-opacity', {
          'opacity-50': userQuery.isFetching || editMutation.isLoading,
        })}
        onSubmit={handleSubmit(d => {
          editMutation.mutate(
            // @ts-ignore
            {
              id: userId,
              firstName: d.firstName,
              lastName: d.lastName,
              title: d.title,
              role: d.role,
              payrollId: d.payrollId,
              employeeType: d.employeeType,
              quickbooksEmployeeName: d.quickbooksEmployeeName,
              quickbooksPayrollItem: d.quickbooksPayrollItem,
            },
            {
              onSuccess: () => {
                // @ts-ignore
                utils.invalidateQueries(['user.profileById', { id: userId }]);
              },
            },
          );
        })}
      >
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="First Name"
          hasFeedback
          validateStatus={errors.firstName?.message && 'error'}
          help={errors.firstName?.message}
        >
          <Controller
            name="firstName"
            control={control}
            // @ts-ignore
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Last Name"
          hasFeedback
          validateStatus={errors.lastName?.message && 'error'}
          help={errors.lastName?.message}
        >
          <Controller
            name="lastName"
            control={control}
            // @ts-ignore
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Title"
          hasFeedback
          validateStatus={errors.title?.message && 'error'}
          help={errors.title?.message}
        >
          <Controller
            name="title"
            control={control}
            // @ts-ignore
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Payroll ID"
          hasFeedback
          validateStatus={errors.payrollId?.message && 'error'}
          help={errors.payrollId?.message}
        >
          <Controller
            name="payrollId"
            control={control}
            // @ts-ignore
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Role"
          validateStatus={errors.role?.message && 'error'}
          help={errors.role?.message}
        >
          <Controller
            name="role"
            control={control}
            // @ts-ignore
            render={({ field }) => (
              // @ts-ignore
              <Select showSearch placeholder="Select a role" {...field}>
                {taxonomyQuery.data?.terms.map(role => (
                  // @ts-ignore
                  <Select.Option key={role.name} value={role.name}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Employee Type"
          hasFeedback
          validateStatus={errors.employeeType?.message && 'error'}
          help={errors.employeeType?.message}
        >
          <Controller
            name="employeeType"
            control={control}
            // @ts-ignore
            render={({ field }) => (
              // @ts-ignore
              <Select showSearch placeholder="Select an employee type" {...field}>
                {/* @ts-ignore */}
                <Select.Option value="W-2">W-2</Select.Option>
                {/* @ts-ignore */}
                <Select.Option value="C2C">C2C</Select.Option>
              </Select>
            )}
          />
        </Form.Item>
        <Divider>Quickbooks</Divider>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Employee Name"
          hasFeedback
          validateStatus={errors.quickbooksEmployeeName?.message && 'error'}
          help={errors.quickbooksEmployeeName?.message}
        >
          <Controller
            name="quickbooksEmployeeName"
            control={control}
            // @ts-ignore
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-1/2 p-2"
          label="Payroll Item"
          validateStatus={errors.quickbooksPayrollItem?.message && 'error'}
          help={errors.quickbooksPayrollItem?.message}
        >
          <Controller
            name="quickbooksPayrollItem"
            control={control}
            // @ts-ignore
            render={({ field }) => (
              // @ts-ignore
              <Select showSearch placeholder="Select payroll item" {...field}>
                {/* @ts-ignore */}
                <Select.Option value="Salary">Salary</Select.Option>
                {/* @ts-ignore */}
                <Select.Option value="Hourly">Hourly</Select.Option>
              </Select>
            )}
          />
        </Form.Item>
        <div className="w-full flex">
          <Form.Item className=" p-2">
            <Button
              type="primary"
              disabled={!isDirty}
              loading={userQuery.isFetching || editMutation.isLoading}
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
          <Form.Item className=" p-2">
            <Button
              disabled={!isDirty}
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </form>
    </Card>
  );
};

export default UserDetailsForm;
