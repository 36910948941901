import React, { useState } from 'react';
import Form from '../../common/components/Form';
import { Formik } from 'formik';
import { Checkbox, Radio, Divider, Input } from 'antd';
import Button from '../../common/components/Button';
import TestProofListCard from './TestProofListCard';
import client from '../../../api/graphql/client';
import { UPDATE_TEST_DETAILS, UPDATE_NO_TYPE } from '../mutations';
import { showSuccess, showError } from '../../common/notifications';
const { TextArea } = Input;

const NotVaccinatedForm = ({ data }) => {
  const { testDetails, noType, requestExceptionText } = data || {};
  const { authorizeShare, affirmAccurate, discloseStatus } = testDetails || {};
  const [updated, setUpdated] = useState(
    authorizeShare && affirmAccurate && discloseStatus ? true : false,
  );
  const [noGroupType, setNoGroupType] = useState(noType || 'intendToVaccinated');

  const [requestExceptionTextInput, setRequestExceptionText] = useState(requestExceptionText || '');

  const shareLabel =
    'By checking this box, I confirm my understanding that if I provide information about my vaccination status to Spruce Technology, Inc. (Spruce), the information will be maintained in a confidential manner, except that information may be provided to appropriate officials/managers of Spruce who have a need to know this information, primarily for the purpose of ensuring compliance with workplace safety standards. I further understand that Spruce is not requiring me to provide my vaccination status and that if I do not wish to provide my vaccination status, Spruce must treat me as if my status is not fully vaccinated.';
  const affirmLable =
    ' I understand that the Americans with Disabilities Act and other laws may prohibit my employer from disclosing my medical/health information. By checking the box below, I authorize Spruce to disclose to clients and others whom I may encounter for work-related purposes, that I am fully vaccinated or not fully vaccinated against COVID-19. I understand that this disclosure is due to certain health and safety protocols, including third-party verification of vaccination status. I further understand that if I do not consent, Spruce may be required to inform third parties that I have declined, and they may then treat me as unvaccinated. Spruce did not seek to coerce or pressure me to disclose information relating to my vaccination status or my medical or family history.';
  const discloseLabel =
    'Yes, I consent for Spruce to disclose my vaccination status as “fully vaccinated” or “unvaccinated”.';

  const handleChecks = (e, handleChange) => {
    if (e.target.checked === false) {
      setUpdated(false);
    }
    handleChange(e);
  };
  const noGroupHandler = e => {
    const type = e.target.value;
    client
      .mutate({
        mutation: UPDATE_NO_TYPE,
        variables: {
          type: type,
          exceptionText: type === 'reqestingException' ? requestExceptionTextInput : '',
        },
      })
      .then(() => {
        setNoGroupType(e.target.value);
      })
      .catch(err => showError({ message: err.message }));
  };
  const textAreaHandler = e => {
    const value = e.target.value;
    setRequestExceptionText(value);
  };
  const exceptionTextChangeHandler = (e, val) => {
    client.mutate({
      mutation: UPDATE_NO_TYPE,
      variables: {
        type: 'reqestingException',
        exceptionText: requestExceptionTextInput || '',
      },
    });
  };
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  return (
    <>
      <Radio.Group onChange={noGroupHandler} value={noGroupType}>
        <Radio style={radioStyle} value={'intendToVaccinated'}>
          I intend to be vaccinated by January 18, 2022
        </Radio>
        <Radio style={radioStyle} value={'reqestingException'}>
          I am requesting a religious or medical exemption
        </Radio>
        {noGroupType && noGroupType === 'reqestingException' && (
          <TextArea
            placeholder="Optional Details"
            value={requestExceptionTextInput}
            onChange={textAreaHandler}
            onBlur={exceptionTextChangeHandler}
            autoSize
          />
        )}
        <Radio style={radioStyle} value={'notIntendToVaccinated'}>
        I do not intend to be fully vaccinated before January 18, 2022 or seek medical/religious exemption.
        </Radio>
      </Radio.Group>
      <Divider />
      <h4 className="w-full md:w-1/2 p-1" style={{ marginBottom: '15px' }}>
        If your client allows weekly testing, you can upload your weekly proofs of negative COVID-19
        test:
      </h4>
      <Formik
        initialValues={{
          authorizeTestShare: authorizeShare,
          affirmTestAccurate: affirmAccurate,
          discloseStatus: discloseStatus,
        }}
        validate={values => {
          const errors = {};
          if (!values.affirmTestAccurate) {
            errors.affirmTestAccurate = 'Required';
          }
          if (!values.authorizeTestShare) {
            errors.authorizeTestShare = 'Required';
          }
          if (!values.discloseStatus) {
            errors.discloseStatus = 'Required';
          }
          return errors;
        }}
        className="w-full md:w-1/2 p-1"
        onSubmit={async (values, { setSubmitting }) => {
          return client
            .mutate({
              mutation: UPDATE_TEST_DETAILS,
              variables: {
                authorizeShare: values.authorizeTestShare,
                affirmAccurate: values.affirmTestAccurate,
                discloseStatus: values.discloseStatus,
              }
            })
            .then(() => {
              setSubmitting(false);
              setUpdated(true);
              showSuccess({ message: 'Updated Successfully' });
            })
            .catch(err => showError({ message: err.message }));
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit} layout="vertical">
              <Form.Item
                hasFeedback
                validateStatus={errors.authorizeTestShare && touched.authorizeTestShare && 'error'}
                help={
                  errors.authorizeTestShare &&
                  touched.authorizeTestShare &&
                  errors.authorizeTestShare
                }
                className="w-full md:w-1/2 p-1"
              >
                <Checkbox
                  name="authorizeTestShare"
                  checked={values.authorizeTestShare ? true : false}
                  onChange={e => {
                    handleChecks(e, handleChange);
                  }}
                >
                  {shareLabel}
                </Checkbox>
              </Form.Item>
              <Form.Item
                hasFeedback
                validateStatus={errors.affirmTestAccurate && touched.affirmTestAccurate && 'error'}
                help={
                  errors.affirmTestAccurate &&
                  touched.affirmTestAccurate &&
                  errors.affirmTestAccurate
                }
                className="w-full md:w-1/2 p-1"
              >
                <Checkbox
                  name="affirmTestAccurate"
                  checked={values.affirmTestAccurate ? true : false}
                  onChange={e => {
                    handleChecks(e, handleChange);
                  }}
                >
                  {affirmLable}
                </Checkbox>
              </Form.Item>
              <Form.Item
                hasFeedback
                validateStatus={errors.discloseStatus && touched.discloseStatus && 'error'}
                help={errors.discloseStatus && touched.discloseStatus && errors.discloseStatus}
                className="w-full md:w-1/2 p-1"
              >
                <Checkbox
                  name="discloseStatus"
                  checked={values.discloseStatus ? true : false}
                  onChange={e => {
                    handleChecks(e, handleChange);
                  }}
                >
                  {discloseLabel}
                </Checkbox>
              </Form.Item>
              <Form.Item className="w-full md:w-2/2 p-2">
                <Button loading={isSubmitting} type="primary" htmlType="submit">
                  Save & Continue
                </Button>
              </Form.Item>
              <Form.Item>
                <TestProofListCard submitted={updated} />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NotVaccinatedForm;
