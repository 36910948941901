import React from 'react';
import { useGlobal } from 'reactn';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { Avatar, Input, Select } from '@mantine/core';
import moment from 'moment';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import { Statistic } from 'antd';
import TimesheetActionsContainer from './TimesheetActionsContainer';
import Tag from '../../common/components/Tag';
import {
  GET_TIMESHEETS_FOR_APPROVAL,
} from '../queries';
import TimesheetApprovers from './TimesheetApprovers';
import { getColorByStatus } from '../../common';
// @ts-ignore
import numeral from 'numeral';
import classNames from 'classnames';
import useEndDateStore from '../../common/stores/useEndDateStore';

const TimesheetTile = ({ timesheet, myApprovalStatus }: any) => {
  const client = useApolloClient();
  const { status, endDate, totalHours } = timesheet;
  return (
    <div className="p-1 w-full">
      <div
        className={classNames(
          'relative bg-white p-4 rounded-md min-h-64 shadow-md transition-shadow duration-200 overflow-hidden'
        )}
      >
        <div className="">
          <div className={classNames("absolute top-0 left-0 right-0 h-1", {
            "bg-green-300": myApprovalStatus === 'Approved',
            "bg-red-300": myApprovalStatus === 'Declined'
          })} />

          <div className="flex flex-col-reverse lg:flex-row justify-between gap-2">
            <div className="">
              <Avatar src={timesheet.user.imageUrl} radius="xl" size="lg" />
              <div className="font-semibold text-lg">{timesheet.user.firstName} {timesheet.user.lastName}</div>
            </div>
            <div>
              <Tag color={getColorByStatus(status)}>{status}</Tag>
            </div>
          </div>

        </div>
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="text-base text-gray-800">
            {moment(endDate, 'YYYYMMDD').format('LL')}
          </div>
          <Statistic title="Total Hours" value={numeral(totalHours).format('0.[00]')} />
        </div>
        <div>
          <div className="my-2 font-semibold">Approvers</div>
          <TimesheetApprovers approvalsRequired={timesheet.approvalsRequired} />
        </div>
        <div>
          <div className="my-2 font-semibold">Actions</div>
          {/* @ts-ignore */}
          <TimesheetActionsContainer timesheet={timesheet} client={client} wrap={false} refetchQueries={[
            {
              query: GET_TIMESHEETS_FOR_APPROVAL,
              variables: {
                startDate: moment(timesheet.endDate).subtract(1, 'week').format('YYYYMMDD'),
                endDate: timesheet.endDate,
                showAll: true,
              },
            },
          ]} />
        </div>
      </div>
    </div>
  )
}

const TimesheetsGrid = ({ userEmail, timesheets, title, subTitle }: any) => {
  return <div>
    <div className="flex justify-start gap-4">
      <div className="mb-4">
        <div className="font-medium text-lg">{title}</div>
        <div className="font-normal text-xs">{subTitle}</div>
      </div>
    </div>
    {timesheets.length === 0 && (
      <div className="w-full text-center">No timesheets found with selected filter</div>
    )}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mb-4">
      {timesheets.map((timesheet: any) => {
        let myApprovalStatus = undefined;

        for (const approval of timesheet.approvalsRequired) {
          if (approval.approvedBy === userEmail)
            myApprovalStatus = approval.status;
        }

        return <TimesheetTile key={timesheet._id} timesheet={timesheet} myApprovalStatus={myApprovalStatus} />
      })}
    </div>
  </div>
}

const TimesheetApprovalGrid = () => {
  const { endDate, setEndDate } = useEndDateStore();
  // @ts-ignore
  const [user] = useGlobal('user');
  // TODO make this dynamic
  const [selectedStatus, setSelectedStatus] = React.useState(undefined);
  const [query, setQuery] = React.useState("");
  const startDate = moment(endDate).subtract(1, 'week').format('YYYYMMDD');
  const { loading, data } = useQuery(GET_TIMESHEETS_FOR_APPROVAL, {
    variables: {
      startDate,
      endDate,
      showAll: true
    }
  });

  const timesheets = data?.projectManagement?.timesheets || [];
  let filteredTimesheets = timesheets.filter((timesheet: any) => {
    if (!selectedStatus) {
      return true;
    }

    return timesheet.status === selectedStatus;
  })

  if (query !== "") {
    filteredTimesheets = filteredTimesheets.filter((timesheet: any) => {
      const name = `${timesheet.user?.firstName} ${timesheet.user?.lastName}`.toLowerCase();
      return name.includes(query)
    })
  }

  // @ts-ignore
  const userEmail = user.emails[0].address;

  const mainTimesheets = filteredTimesheets.filter((timesheet: any) => {
    const mainApprovers = timesheet.approvalsRequired.map((approval: any) => {
      // @ts-ignore
      return approval.canApprove[0];
    })

    return mainApprovers.includes(userEmail)
  });

  const otherTimesheets = filteredTimesheets.filter((timesheet: any) => {
    const mainApprovers = timesheet.approvalsRequired.map((approval: any) => {
      // @ts-ignore
      return approval.canApprove[0];
    })

    return !mainApprovers.includes(userEmail)
  })

  return (
    // @ts-ignore
    <Card loading={loading} title="Timesheet Approval" floating bodyBackground="none" actionComponent={
      <div className="flex gap-4">
        <Input
          placeholder="Search by name"
          onChange={(e: any) => {
            setQuery(e.target.value.toLowerCase());
          }}
        />
        <Select
          allowDeselect
          placeholder="Select Status"
          data={['Approved', 'Declined', "Submitted-Pending"]}
          value={selectedStatus}
          // @ts-ignore
          onChange={setSelectedStatus}
        />
        <WeekPicker loading={loading}
          endDate={moment(endDate).format('YYYYMMDD')}
          onDateChange={(newEndDate: string) => {
            const endDateString = moment(newEndDate).format('YYYYMMDD');
            setEndDate(endDateString);
          }}
        />
      </div>
    }>
      <div className="">
        {timesheets.length === 0 ? <div className="w-full text-center">No timesheets found</div> : (
          <div>
            <TimesheetsGrid userEmail={userEmail.toLowerCase()} timesheets={mainTimesheets} title="My Timesheets" subTitle="Timesheets where I am one of the main approvers" />
            {
              otherTimesheets.length > 0 && (
                <TimesheetsGrid timesheets={otherTimesheets} title="Other Timesheets" subTitle="Timesheets where I am the backup approver" />
              )
            }

          </div>

        )}
      </div>
    </Card>
  );
};

export default TimesheetApprovalGrid;
