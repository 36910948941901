/* eslint-disable */
import { Switch } from '@mantine/core';
import PasswordChange from './PasswordChange';
import Page from '../../common/components/Page';
import Card from '../../common/components/Card';
import useLocalStorage from '../../common/hooks/useLocalStorage';

const ProfilePage = () => {
  const [useNewTaskSelector, setUseNewTaskSelector] = useLocalStorage('useNewTaskSelector', false);

  return (
    <Page>
      <PasswordChange />
      <Card title="Preview Features">
        <div className="flex flex-col justify-start p-4">
          <Switch
            label="Use new task selector"
            checked={useNewTaskSelector}
            onChange={(value) => {
              setUseNewTaskSelector(!useNewTaskSelector);
            }}
          />
        </div>
      </Card>
    </Page>
  );
};

export default ProfilePage;
