import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TimesheetCard from './TimesheetCard';
import { changeTimesheetDate } from '../actions';

const mapStateToProps = state => ({
  endDate: state.timesheets.endDate,
});

const mapDispatchToProps = dispatch => ({
  changeTimesheetDate: endDate => dispatch(changeTimesheetDate(endDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TimesheetCard));
