import React from 'react';
import { Query } from 'react-apollo';
import { GET_USER_VACCINE_PROOFS } from '../queries';
import TextLoader from '../../common/components/TextLoader';
import VaccineProofForm from './VaccineProofForm';



const VaccineProofContainer = () => (
  <Query query={GET_USER_VACCINE_PROOFS}>
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      if (loading) {
        return <TextLoader text="Loading" />;
      }
      return <VaccineProofForm userVaccineProofs={data.userVaccineProofs}/>
    }}
  </Query>
);

export default VaccineProofContainer;