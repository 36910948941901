import * as Sentry from '@sentry/browser';

/* eslint-disable */
if (window.location.origin.includes('sprucetech.com')) {
  const environment = window.location.origin.includes('stage.sprucetech.com')
  ? 'staging-spruce'
  : 'production-spruce';
  Sentry.init({
    dsn: 'https://0bcd563b31c44b87b1047e1f20ac304a@sentry.io/1297042',
    environment,
    attachStacktrace: true,
  });
}

/* eslint-disable */
if (window.location.origin.includes('env-time-tracker.com')) {
  const environment = window.location.origin.includes('stg.env-time-tracker.com')
    ? 'staging-env'
    : 'production-env';
  Sentry.init({
    dsn: 'https://0bcd563b31c44b87b1047e1f20ac304a@sentry.io/1297042',
    environment,
    attachStacktrace: true,
  });
}

/* eslint-disable */
if (window.location.origin.includes('timecone.io')) {
  const environment = window.location.origin.includes('stage.timecone.io')
    ? 'staging-timecone'
    : 'production-timecone';
  Sentry.init({
    dsn: 'https://0bcd563b31c44b87b1047e1f20ac304a@sentry.io/1297042',
    environment,
    attachStacktrace: true,
  });
}
