import React, { useState } from 'react';
import styled from 'styled-components';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import moment from 'moment';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import { Button, Checkbox, Icon } from 'antd';
import { CSVLink } from 'react-csv';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

const Wrapper = styled.div`
  height: 600px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

function msToTime(ms) {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + ' Sec';
  else if (minutes < 60) return minutes + ' Min';
  else if (hours < 24) return hours + ' Hrs';
  else return days + ' Days';
}

const DelinquencyReport = ({ data }) => {
  const { users, handleDateChange, startDate, endDate } = data;
  const [hideZeroTimesheet, setHideZeroTimesheet] = useState(true);

  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Employee Type', key: 'employeeType' },
    { label: 'Email', key: 'email' },
    { label: 'Last Active On', key: 'lastActiveOn' },
  ];
  // console.log(data);
  const visibleRows = users?.filter(item => {
    if (hideZeroTimesheet) {
      const submitCount = item.timesheets?.filter(
        item => item.status === 'Submitted-Pending' || item.status === 'Approved',
      );
      return submitCount?.length;
    } else {
      return true;
    }
  });
  const tableData = visibleRows?.map(item => {
    const { timesheets } = item;

    const totalTimesheetSubmitted = timesheets?.filter(
      item => item.status === 'Submitted-Pending' || item.status === 'Approved',
    );

    //index 0 is the last
    const lastTimesheet = totalTimesheetSubmitted.sort((a, b) => {
      const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
      return diff;
    });
    const latency = [];
    totalTimesheetSubmitted.forEach(element => {
      const submittedTimeline = element.timeline?.filter(el => el.type === 'Submitted');

      // calculating Monday 9 am from end date
      const monday = moment(element.endDate).add(33, 'h');

      const calculateLatency =
        new Date(moment(monday)).getTime() -
        new Date(submittedTimeline[submittedTimeline.length - 1].date).getTime();

      if (calculateLatency < 0) {
        latency.push(Math.abs(calculateLatency));
      }
    });
    const avgLatency = latency.length ? latency.reduce((previousValue, currentValue) => previousValue + currentValue)/latency.length : 0;

 
    return {
      lastName: item.lastName,
      firstName: item.firstName,
      employeeType: item.employeeType,
      email: item.emails[0].address,
      totalTimesheetSubmit: totalTimesheetSubmitted?.length || 0,
      lastTimesheetSubmitted: lastTimesheet?.length
        ? moment(lastTimesheet[0].endDate).format('LL')
        : '',
      avgLatency : totalTimesheetSubmitted.length ? avgLatency === 0 ? "0" : `${msToTime(avgLatency)} Late`: "No submission",
      lastActiveOn: item.lastActiveOn ? moment(new Date(item.lastActiveOn)).format('LLL') : null,
    };
  });
  return (
    <Card
      padded={false}
      title="Delinquency Report "
      floating
      withShadow={false}
      description={
        <div className="flex">
          <div className="mx-2" style={{ marginTop: '8px' }}>
            <Checkbox
              checked={hideZeroTimesheet}
              onChange={e => {
                setHideZeroTimesheet(e.target.checked);
              }}
            >
              Show/Hide users submitted any timesheet
            </Checkbox>
          </div>
        </div>
      }
      actionComponent={
        <div className="flex">
          <div style={{ margin: '10px' }}>
            <Button className="btn btn-primary">
              <CSVLink
                headers={headers}
                filename={'Human_Resource_DELINQUENCY_' + Date.now() + '.csv'}
                data={tableData}
              >
                <Icon type="download" style={{ paddingRight: '10px' }} />
                Export CSV
              </CSVLink>
            </Button>
          </div>
          <div className="flex justify-end">
            <DateRangePicker
              handleDateChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              withControls
              className="pr-2"
            />
          </div>
        </div>
      }
    >
      <Wrapper className="ag-theme-balham">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={tableData}
          enableCellTextSelection
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
        >
          <AgGridColumn field="firstName" />
          <AgGridColumn field="lastName" />
          <AgGridColumn field="employeeType" />
          <AgGridColumn field="email" />
          <AgGridColumn field="avgLatency"  headerName="Average Latency" />
          <AgGridColumn field="totalTimesheetSubmit" headerName="Total TimeSheet Submitted" />
          <AgGridColumn field="lastTimesheetSubmitted" headerName="Last Timesheet Submitted" />
          <AgGridColumn field="lastActiveOn" headerName="Last Active On" />
        </AgGridReact>
      </Wrapper>
    </Card>
  );
};

export default DelinquencyReport;
