import gql from 'graphql-tag';

export const APPROVE_TIMESHEET_TIMESHEET_LINEITEM = gql`
  mutation ApproveTimesheetLineItems($timesheetId: String!) {
    approveTimesheetLineItems(input: { timesheetId: $timesheetId })
  }
`;

export const CANCEL_TIMESHEET_TIMESHEET_LINEITEM = gql`
  mutation CancelTimesheet($timesheetId: String!, $comments: String) {
    cancelTimesheet(input: { timesheetId: $timesheetId, comments: $comments })
  }
`;

export const REJECT_TIMESHEET_TIMESHEET_LINEITEM = gql`
  mutation DeclineTimesheetLineItems($timesheetId: String!, $comments: String) {
    declineTimesheetLineItems(input: { timesheetId: $timesheetId, comments: $comments })
  }
`;

export const ADD_PROJECT_RESOURCE = gql`
  mutation AddProjectResource($role: String!, $email: String!, $projectId: String!) {
    addProjectResource(input: { role: $role, email: $email, projectId: $projectId })
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($id: String, $email: String, $password: String!) {
    resetUserPassword(input: { id: $id, email: $email, password: $password })
  }
`;
