import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import numeral from 'numeral';
import { Statistic } from 'antd';
import TextLoader from '../../common/components/TextLoader';

export const TIMESHEET_DETAIL = gql`
  query performance($timesheetId: String!) {
    admin {
      timesheet(timesheetId: $timesheetId) {
        _id
        status
        endDate
        totalHours
        tasks {
          _id
          hours
          project {
            _id
            clientName
            name
          }
          deliverable {
            _id
            deliverableName
            milestoneName
          }
        }
      }
    }
  }
`;

const ResourcePerformanceDetailByProject = ({ timesheetId }) => {
  const { loading, data = {} } = useQuery(TIMESHEET_DETAIL, {
    variables: {
      timesheetId,
    },
  });

  if (loading) {
    return <TextLoader />;
  }

  const timesheet = data?.admin?.timesheet;
  // const projects = timesheet?.tasks?.reduce((acc, cur) => {
  //   if (acc[cur.project._id]) {
  //     return {
  //       ...acc,
  //       [cur.project._id]: [...acc[cur.project._id], cur],
  //     };
  //   }
  //   return {
  //     ...acc,
  //     [cur.project._id]: [cur],
  //   };
  // }, {});
  // console.log({projects});

  // console.log({ timesheet });
  return (
    <div className="flex flex-col">
      <div className="flex justify-start">
        <Statistic
          className="mx-2"
          title="Hours"
          value={numeral(timesheet?.totalHours).format('0.0')}
        />
        <Statistic className="mx-4" title="Status" value={timesheet?.status} />
        <Statistic
          className="mx-2"
          title="Week Ending"
          value={moment(timesheet?.endDate).format('LL')}
        />
        <div className="flex-end">
          <a href={`/reports/timesheets/${timesheetId}`} target="_blank" rel='noreferrer'>
            View Details
          </a>
        </div>
      </div>
      {/* <div>
        {timesheet?.tasks.map(task => (
          <div>{task.deliverable.deliverableName}</div>
        ))}
      </div> */}
    </div>
  );
};

export default ResourcePerformanceDetailByProject;
