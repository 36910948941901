import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import TextLoader from '../../common/components/TextLoader';

const ProjectTemplates = () => {
  const { loading, data } = useQuery(
    gql`
      query {
        admin {
          templates {
            _id
            name
            description
          }
        }
      }
    `,
  );

  if (loading) {
    return <TextLoader text="Loading templates" />;
  }

  const templates = data.admin.templates;

  return (
    <Card
      actionComponent={
        <Button>
          <Link to="/admin/project-templates/new">New Project Template</Link>
        </Button>
      }
      floating
      title="Project Templates"
      bodyBackground={null}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {templates.map(template => (
          <Link key={template._id} className="h-full" to={`/admin/project-templates/${template._id}`}>
            <Card border padded fullHeight>
              <div className="text-initial">
                <h2 className="text-gray-800">{template.name}</h2>
                <p className="text-gray-600">
                  {template.description?.length > 150
                    ? template.description.substring(0, 150 - 3) + '...'
                    : template.description}
                </p>
              </div>
            </Card>
          </Link>
        ))}
      </div>
    </Card>
  );
};

export default ProjectTemplates;
