import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Link } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import NewUserForm from './NewUserForm';
import { Checkbox } from 'antd';
import { Input } from '@mantine/core';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

const Wrapper = styled.div`
  height: 600px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class UserList extends React.Component {
  gridApi = undefined;
  state = {
    isModalVisible: false,
    showActive: true,
    query: '',
  };

  onGridReady = params => {
    this.gridApi = params.api;
  };

  export = () => {
    const params = {
      fileName: `Users-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };
  render() {
    const { data, loading } = this.props;

    const filterData = this.state.showActive ? data?.filter(item => item.accountActive) : data;
    let visibleRows = filterData?.map(item => {
      return {
        ...item,
        accountActive: item.accountActive ? 'Active' : 'Inactive',
      };
    });

    if (this.state.query !== '') {
      visibleRows = visibleRows.filter(item => {
        const name = `${item.firstName} ${item.lastName}`.toLowerCase();
        const email = item.emails[0].address.toLowerCase();
        return name.includes(this.state.query) || email.includes(this.state.query);
      });
    }

    const { isModalVisible } = this.state;

    return (
      <Card
        border
        floating
        padded={false}
        title="Users"
        loading={loading}
        actionComponent={
          <div className="flex gap-2 items-center">
            <Input
              placeholder="Search"
              onChange={e => {
                this.setState({ query: e.target.value.toLowerCase() });
              }}
            />
            <Checkbox
              onChange={e => {
                this.setState({ showActive: e.target.checked });
              }}
              checked={this.state.showActive}
            >
              Only Show Active
            </Checkbox>
            <Button onClick={() => this.setState({ isModalVisible: true })}>Add User</Button>
            <Button onClick={() => this.export()}>Export</Button>
            <Modal
              visible={isModalVisible}
              footer={null}
              onCancel={() => this.setState({ isModalVisible: false })}
            >
              <Box pt={4}>
                <NewUserForm
                  onComplete={() => this.setState({ isModalVisible: false })}
                  notifyUser={true}
                />
              </Box>
            </Modal>
          </div>
        }
      >
        <Wrapper className="ag-theme-balham">
          <AgGridReact
            modules={AllCommunityModules}
            rowData={
              visibleRows?.map(item => ({
                ...item,
                lastActiveOn: item.lastActiveOn
                  ? moment(item.lastActiveOn).format('MM/DD/YYYY hh:mm A')
                  : '',
                daysSinceLastActive: item.lastActiveOn ? moment(item.lastActiveOn).fromNow() : '',
              })) || []
            }
            onGridReady={this.onGridReady}
            enableCellTextSelection
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
            }}
          >
            <AgGridColumn
              width={80}
              cellRendererFramework={({ data }) => (
                <Link to={`/admin/users/${data._id}`}>Details</Link>
              )}
            />
            <AgGridColumn field="firstName" />
            <AgGridColumn field="lastName" sort="asc" />
            <AgGridColumn field="emails.0.address" headerName="Email" />
            <AgGridColumn field="role" />
            <AgGridColumn field="employeeType" />
            <AgGridColumn field="workCalendar" />
            <AgGridColumn field="accountActive" headerName="Account Status" />
            <AgGridColumn width={140} field="lastActiveOn" headerName="Last Active On" />
            <AgGridColumn
              width={140}
              field="daysSinceLastActive"
              headerName="Days Since Last Active"
            />
            <AgGridColumn
              width={140}
              field="approvers"
              headerName="Approvers"
              valueGetter={({ colDef, data }) => {
                const approvers = data[colDef.field];
                if (approvers.length > 0) {
                  if (approvers[0].type === 'self') {
                    return 'self';
                  }
                  return approvers.map(v => v.value).join(',');
                }
                return null;
              }}
            />
            <AgGridColumn
              width={140}
              field="delegatedApprovers"
              headerName="Delegated Approvers"
              valueGetter={({ colDef, data }) => {
                const value = data[colDef.field];
                return value.length > 0 ? value.map(v => v.value).join(',') : null;
              }}
            />
            <AgGridColumn field="payrollId" />
            <AgGridColumn field="integration.sourceId" />
          </AgGridReact>
        </Wrapper>
      </Card>
    );
  }
}

export default UserList;
