import React, { useState } from 'react';
import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Icon, Tooltip, Radio, Divider } from 'antd';
import { searchTable } from './SearchTable';
import { useQuery } from '@apollo/react-hooks';
import TextLoader from '../../common/components/TextLoader';
import { GET_NONVACCINATED_TIMESHEETS } from '../queries';

const NotVaccinatedReport = ({ notVaccinated, title, notVaccinatedUserId }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filter, setFilter] = useState('all');

  const { loading } = useQuery(GET_NONVACCINATED_TIMESHEETS, {
    variables: {
      userIds: notVaccinatedUserId,
    },
  });

  if (loading) {
    return <TextLoader text="Loading ... " />;
  }

  const rowData = notVaccinated?.map(item => {
    let project = '';
    let client = '';
    // const filteredTimesheets = timesheets.filter(timesheet=>item.id === timesheet.userId);
    // const getProjectAndClient = filteredTimesheets?.map(timesheet=>{
    //   const {lineItems} = timesheet;
    //     if(timesheet.totalHours > totalHours ){
    //       totalHours = timesheet.totalHours;
    //       project = lineItems?.length ? lineItems[0].project?.name : "";
    //       client = lineItems?.length ? lineItems[0].project?.clientName : "";
    //     }
    // });
    return {
      ...item,
      project,
      client,
    };
  });

  const filterData = filter === 'all' ? rowData : rowData.filter(item => item.project === filter);

  const timeCounter = (lastTestProofDate, key) => {
    // var month = check => parseInt(check.format('M'));
    // var day = check => parseInt(check.format('D'));
    // var year = check => parseInt(check.format('YYYY'));
    // const today = [year(moment()), month(moment()), day(moment())];
    // const lastProof = [
    //   year(moment(lastTestProofDate)),
    //   month(moment(lastTestProofDate)),
    //   day(moment(lastTestProofDate)),
    // ];

    const daysLeftToNextUpload = 7 - moment().diff(moment(lastTestProofDate), 'days');

    if (daysLeftToNextUpload > 0) {
      // filterData[key]['OverdueBy'] = `-${daysLeftToNextUpload}`;
      return [`${daysLeftToNextUpload} days until next test must be taken`, 'green'];
    } else if (daysLeftToNextUpload === 0) {
      // filterData[key]['OverdueBy'] = daysLeftToNextUpload;
      return [`Next Test should have been taken today`, 'red'];
    } else if (daysLeftToNextUpload < 0) {
      // filterData[key]['OverdueBy'] = Math.abs(daysLeftToNextUpload);
      return [`Next Test should have been taken ${Math.abs(daysLeftToNextUpload)} days ago`, 'red'];
    } else {
      return '';
    }
  };
  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      ...searchTable('employee', searchText, setSearchText, searchedColumn, setSearchedColumn),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...searchTable('status', searchText, setSearchText, searchedColumn, setSearchedColumn),
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      ...searchTable('project', searchText, setSearchText, searchedColumn, setSearchedColumn),
      sorter: (a, b) => (b.project > a.project ? -1 : 1),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      ...searchTable('client', searchText, setSearchText, searchedColumn, setSearchedColumn),
      sorter: (a, b) => (b.client > a.client ? -1 : 1),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'test',
      title: 'Test',
      width: 50,
      render: data => {
        const { test } = data;
        if (test) {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={test}
              style={{ textDecoration: 'underline', fontSize: '12px' }}
            >
              Test Results
            </a>
          );
        }
        return <div>No Test Submitted</div>;
      },
    },

    {
      title: 'Last Date',
      key: 'lastDate',
      render: (data, i) => {
        const { lastDate } = i;
        const counterStatement = timeCounter(lastDate);
        return (
          <div>
            {lastDate}
            {counterStatement && (
              <Tooltip placement="right" title={counterStatement[0]} key={Math.random()}>
                <Icon
                  style={{ color: counterStatement[1], marginLeft: '10px' }}
                  type="exclamation-circle"
                />
              </Tooltip>
            )}
          </div>
        );
      },
      sorter: (a, b) => moment(b.lastDate).unix() - moment(a.lastDate).unix(),
      sortDirections: ['ascend'],
    },
  ];
  return (
    <Card
      border
      floating
      title={title + ' : ' + filterData.length}
      actionComponent={
        <div style={{ padding: '10px' }}>
          Filter :
          <Radio.Group
            defaultValue={filter}
            size="small"
            style={{ marginRight: '10px', marginLeft: '5px' }}
            onChange={e => setFilter(e.target.value)}
          >
            <Radio.Button value="DOB NOW">DOB NOW</Radio.Button>
            <Radio.Button value="Sephora">Sephora</Radio.Button>
            <Radio.Button value="all">All</Radio.Button>
          </Radio.Group>
          <Divider type="vertical" />
          <CSVLink
            filename={'VaccineTracker_NotVaccinated_' + Date.now() + '.csv'}
            data={rowData}
            className="btn"
          >
            Export CSV
          </CSVLink>{' '}
        </div>
      }
    >
      <div>
        <Table
          highlightOnHover
          columns={columns}
          pagination={false}
          dataSource={filterData && filterData.map((r, i) => ({ key: i, ...r }))}
          rowKey={r => r.test + Math.random()}
          style={{ height: '400px' }}
        />
      </div>
    </Card>
  );
};

export default NotVaccinatedReport;
