import React, { useState } from 'react';
import Button from '../Button';
import Form from '../Form';
import { UPDATE_PROJECT_TEMPLATE } from './mutations';
import { Input, Alert } from 'antd';
import client from '../../../../api/graphql/client';
import { TextArea } from '../Input';

const formItemLayout = {
  // labelCol: {
  //   xs: { span: 24 },
  //   sm: { span: 8 },
  // },
  // wrapperCol: {
  //   xs: { span: 24 },
  //   sm: { span: 16 },
  // },
};

const UpdateProjectTemplateForm = ({ onComplete, currentProject, refetchQueries = [] }) => {
  const { name, description, _id: projectId } = currentProject;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(null);
  const [projectName, setProjectName] = useState(name);
  const [projectDescription, setProjectDescription] = useState(description);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setShowError(null);
    client
      .mutate({
        mutation: UPDATE_PROJECT_TEMPLATE,
        variables: {
          projectId: projectId,
          name: projectName,
          description: projectDescription,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      })
      .then(() => {
        setIsSubmitting(false);
        onComplete();
      })
      .catch(err => console.log(err));
  };
  return (
    <Form {...formItemLayout}>
      <Form.Item label="Name">
        <Input
          placeholder=""
          onChange={e => {
            setProjectName(e.target.value);
          }}
          value={projectName}
        />
      </Form.Item>
      <Form.Item label="Description">
        <TextArea
          placeholder=""
          onChange={e => {
            setProjectDescription(e.target.value);
          }}
          value={projectDescription}
        />
      </Form.Item>

      {showError && (
        <div style={{ paddingBottom: '10px' }}>
          <Alert message={showError} type="error" />
        </div>
      )}
      <div className="flex justify-end">
        <Button loading={isSubmitting} type="primary" onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Form>
  );
};

export default UpdateProjectTemplateForm;
