// eslint-disable-next-line
// import { Accounts } from 'meteor/accounts-base';
import { connect } from 'react-redux';
import SignOut from './SignOut';

const logout = () => null; // Accounts.logout();

const maptStateToProps = state => ({
  loggingOut: state.meteor.loggingOut,
  logout,
});

export default connect(maptStateToProps)(SignOut);
