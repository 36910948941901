import React from 'react';
import { useGlobal } from 'reactn';
import { Query } from 'react-apollo';
import { withRouter, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import VaccineReport from './VaccineReport';
import { GET_VACCINE_REPORT } from '../queries';
import TextLoader from '../../common/components/TextLoader';

const VaccineReportContainer = ({ history, match }) => {
  const [user] = useGlobal('user');
  // redirect user back to main screen if they are not allowed to view this screen
  if (!user?.groups.includes('vaccine-report')) {
    return <Redirect to="/" />;
  }

  return (
    <Query query={GET_VACCINE_REPORT}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);        
        if (loading) {
          return <TextLoader text="Loading report please wait... " />;
        }
        const vaccineTrackerReport = get(data, 'vaccineTrackerReport');
        // const allUserTimesheets = get(data, 'allUserTimesheets');
        return <VaccineReport data={{ vaccineTrackerReport }} />;
      }}
    </Query>
  );
};

export default withRouter(VaccineReportContainer);
