import React from 'react';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import { TextArea } from '../../common/components/Input';
import { UPDATE_TIMESHEET_TASK_COMMENT } from '../mutations';
import { GET_USER_TIMESHEET } from '../queries';

const StyledTextArea = styled(TextArea)`
  border-radius: 0;
`;

class TimesheetGridRowCommentUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      comment: this.props.comment,
    };
  }

  handleChange = e => this.setState({ comment: e.target.value });

  handleSave = () => {
    const comment = this.state.comment || null;
    // check if comment was changed, if yes then run mutation
    if (comment !== this.props.comment) {
      this.setState({ isUpdating: true });
      this.props.client
        .mutate({
          mutation: UPDATE_TIMESHEET_TASK_COMMENT,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_USER_TIMESHEET,
              variables: {
                endDate: this.props.endDate,
                user : this.props.user
              },
            },
          ],
          variables: {
            taskId: this.props.taskId,
            comment,
          },
        })
        .then(() => this.setState({ isUpdating: false }));
    }
  };

  render() {
    return (
      <StyledTextArea
        disabled={this.state.isUpdating}
        onChange={this.handleChange}
        onBlur={this.handleSave}
        value={this.state.comment}
        rows={4}
      />
    );
  }
}

export default withApollo(TimesheetGridRowCommentUpdate);
