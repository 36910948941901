import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectListContainer from './ProjectListContainer';
// import ProjectList from './ProjectList/index.tsx';
import ProjectDetailsContainer from './ProjectDetailsContainer';

const ProjectManagement = () => (
  <div>
    <Switch>
      <Route exact path="/project-management/projects" render={() => <ProjectListContainer />} />
      <Route exact path="/project-management/all-projects" render={() => <ProjectListContainer showAllProjects />} />
      {/* <Route exact path="/project-management/projects" render={() => <ProjectList />} /> */}
      {/* <Route exact path="/project-management/all-projects" render={() => <ProjectList showAllProjects />} /> */}
      <Route
          path="/project-management/projects/:projectId/:activeTab"
          render={() => <ProjectDetailsContainer />}
        />
        <Route
          path="/project-management/projects/:projectId"
          render={() => <ProjectDetailsContainer />}
        />
      <Route
          path="/project-management/all-projects/:projectId/:activeTab"
          render={() => <ProjectDetailsContainer />}
        />
        <Route
          path="/project-management/all-projects/:projectId"
          render={() => <ProjectDetailsContainer />}
        />
    </Switch>
  </div>
);

export default ProjectManagement;
