import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ConfigurationList from './ConfigurationList';

const ConfigurationCollection = ({ history, match }) => {
  const [config, setConfig] = useState([]);
  const { loading, data, refetch } = useQuery(
    gql`
      query {
        allConfigurations {
          key
          value
          description
          valueType
        }
      }
    `,
  );

  useEffect(() => {
    if (data?.allConfigurations) {
      setConfig(data.allConfigurations);
    }
  }, [data]);

  return <ConfigurationList handleUpdate={refetch} history={history} data={config} loading={loading} />;
};

export default withRouter(ConfigurationCollection);
