import React from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import Button from '../../common/components/Button';

const EditTaxonomyForm = ({
  taxonomyId,
  term,
  singularName,
  onComplete,
  mutation,
  existingsTerms,
  refetchQueries = [],
  client,
}) => {
  return (
    <Formik
      initialValues={{
        term: term.name,
      }}
      validate={values => {
        const errors = {};
        if (!values.term) {
          errors.term = 'Required';
        }
        if (existingsTerms.includes(values.term)) {
          errors.term = `${singularName} already exists`;
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        client
          .mutate({
            mutation,
            awaitRefetchQueries: true,
            refetchQueries,
            variables: {
              taxonomyId,
              termId: term._id,
              term: values.term,
            },
          })
          .then(res => {
            setSubmitting(false);
            onComplete();
          })
          .catch(err => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item
            label={singularName}
            hasFeedback
            validateStatus={errors.term && touched.term && 'error'}
            help={errors.term && touched.term && errors.term}
            required
          >
            <Input
              name="term"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.term}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Button loading={isSubmitting} type="primary" htmlType="submit">
            {`Edit ${singularName}`}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withApollo(EditTaxonomyForm);
