import React from 'react';
import moment from 'moment';
import { Flex, Box } from 'rebass/styled-components';
import Card from '../../common/components/Card';
// import WeekPicker from '../../common/components/WeekPicker';
import DateRangePicker from '../../common/components/DateRangePicker';
import Button from '../../common/components/Button';
import UserTaskTotalsContainer from './UserTaskTotalsContainer';

class TimesheetCard extends React.Component {
  export = undefined;
  state = {
    loading: true,
    startDate: moment().startOf('isoWeek').format('YYYYMMDD'),
    endDate: moment().endOf('isoWeek').format('YYYYMMDD'),
  };

  changeUrl = endDate => {
    const { history } = this.props;
    history.push(`/human-resources/reports/user-task-totals/${endDate}`);
  };

  onExportReady = ({ getExportFunc }) => {
    const exportFunc = getExportFunc();
    this.export = exportFunc;
  };

  handleExportClick = () => {
    this.export();
  };

  setLoading = loading => this.setState({ loading });

  render() {
    const { loading } = this.state;
    // const { handleDateChange } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <Card
        border
        title="Employee Rollups by Task"
        floating
        padded={false}
        actionComponent={
          <Flex>
            <DateRangePicker
              disabled={loading}
              handleDateChange={range => {
                this.setState({
                  startDate: moment(range[0]).format('YYYYMMDD'),
                  endDate: moment(range[1]).format('YYYYMMDD'),
                });
                const endDateString = moment(range[1]).format('YYYYMMDD');
                this.changeUrl(endDateString);
              }}
              startDate={startDate}
              endDate={endDate}
            />
            {/* <WeekPicker
              loading={loading}
              endDate={moment(endDate).format('YYYYMMDD')}
              onDateChange={endDate => {
                const endDateString = moment(endDate).format('YYYYMMDD');
                this.changeUrl(endDateString);
                handleDateChange(endDate);
              }}
            /> */}
            <Box ml="1em">
              <Button onClick={this.handleExportClick}>Export</Button>
            </Box>
          </Flex>
        }
      >
        <UserTaskTotalsContainer
          startDate={startDate}
          endDate={endDate}
          setLoading={this.setLoading}
          onExportReady={this.onExportReady}
        />
      </Card>
    );
  }
}

export default TimesheetCard;
