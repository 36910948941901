import React, { useEffect } from 'react';
import { setGlobal } from 'reactn';
import useNavigatorOnline from 'use-navigator-online';

const NetworkIndicator = () => {
  // const {
  //   numPendingQueries,
  //   numPendingMutations,
  //   queryError,
  //   mutationError,
  // } = useApolloNetworkStatus();
  const { isOnline, isOffline, backOnline, backOffline } = useNavigatorOnline();

  useEffect(() => {
    setGlobal({
      networkStatus: {
        isOnline,
        isOffline,
        backOnline,
        backOffline,
        // numPendingQueries,
        // numPendingMutations,
        // queryError,
        // mutationError,
      },
    });
  }, [
    isOnline,
    isOffline,
    backOnline,
    backOffline,
    // numPendingQueries,
    // numPendingMutations,
    // queryError,
    // mutationError,
  ]);

  if (backOnline) {
    // window.location.reload();
  }

  return (
    <div className="absolute top-0 right-0 left-0 mx-auto flex justify-center">
      {isOffline && <div className="p-2 bg-red-100 text-white">You appear to be offline.</div>}
    </div>
  );
};

export default NetworkIndicator;
