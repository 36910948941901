import gql from 'graphql-tag';

export const UPDATE_VACCINE_DETAILS = gql`
  mutation UpdateVaccineDetails($vaccineVersion: String!,$lastVaccinationDate: String!,$authorizeShare: Boolean!,$affirmAccurate: Boolean!,$vaccineProof: String!,$discloseStatus:Boolean!) {
    updateVaccineDetails(input: { vaccineVersion: $vaccineVersion , lastVaccinationDate : $lastVaccinationDate,authorizeShare: $authorizeShare, affirmAccurate:$affirmAccurate, vaccineProof:$vaccineProof,discloseStatus:$discloseStatus})
  }
`;

export const UPLOAD_TEST_PROOF = gql`
  mutation TestProofs($documentUrl:String, $date:String) {
    testProofs(documentUrl:$documentUrl, date:$date){
      url
    }
  }
`;

export const DELETE_TEST_PROOF = gql`
  mutation DeleteProof($date:String!) {
    deleteProof(date:$date)
  }
`;

export const UPDATE_TEST_DETAILS = gql`
  mutation UpdatetestDetails($authorizeShare: Boolean!,$affirmAccurate: Boolean!,$discloseStatus:Boolean!) {
    updatetestDetails(input: { authorizeShare: $authorizeShare, affirmAccurate:$affirmAccurate,discloseStatus:$discloseStatus})
  }
`;
export const UPDATE_NO_TYPE = gql`
  mutation UpdateNoType($type: String!,$exceptionText:String) {
    updateNoType(type: $type,exceptionText:$exceptionText)
  }
`;