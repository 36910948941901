import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ResetPassword = ({ location }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Wrapper>
      <Text ml="1rem" fontSize={3}>
        Reset Password
      </Text>
      {queryParams.token ? (
        <ResetPasswordForm token={queryParams.token}/>
      ) : (
        <ResetPasswordRequestForm email={queryParams.email} />
      )}
    </Wrapper>
  );
};

export default withRouter(ResetPassword);
