import React from 'react';
import { withApollo, useQuery } from 'react-apollo';
import { Formik } from 'formik';
import moment from 'moment';
import { Checkbox, Icon, Select, Tooltip } from 'antd';
import { TextArea } from '../../../common/components/Input';
import gql from 'graphql-tag';
import Button from '../Button';
import DatePicker from '../DatePicker';
import Form from '../Form';
import TextLoader from '../TextLoader';
import { UPDATE_RESOURCE, ADD_RESOURCE } from './mutations';

const { Option } = Select;

const GET_USERS = gql`
  query {
    users {
      _id
      firstName
      lastName
      emails {
        address
      }
    }
  }
`;

const dateFormat = 'MM/DD/YYYY';

const UpdateResourceForm = ({
  onComplete,
  client,
  projectId,
  resource = {},
  refetchQueries = [],
  roles,
}) => {
  const { loading, data } = useQuery(GET_USERS);
  const users = data?.users;
  if (loading) return <TextLoader text="Fetching users" />;
  return (
    <Formik
      initialValues={{
        ...resource,
      }}
      validate={({ startDate, name, role }) => {
        console.log({ startDate, name, role });
        const errors = {};
        if (!name) {
          errors['name'] = 'Required';
        }
        // if (!role) {
        //   errors['role'] = 'Required';
        // }
        if (!startDate) {
          errors['startDate'] = 'Required';
        }
        // console.log({ values });
        // if (!values.password) {
        //   !setEmailCheck.isSpruceEmail
        //     ? (errors.password = 'Password is definitely required')
        //     : (errors.password = "I can't let you do this");
        // }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (resource._id) {
          return client
            .mutate({
              mutation: UPDATE_RESOURCE,
              variables: {
                id: resource._id,
                ...values,
              },
              awaitRefetchQueries: true,
              refetchQueries,
            })
            .then(() => {
              setSubmitting(false);
              onComplete();
            })
            .catch(err => {
              console.log('Error Message: ', err.message);
            });
        } else {
          return client
            .mutate({
              mutation: ADD_RESOURCE,
              variables: {
                projectId,
                ...values,
              },
              awaitRefetchQueries: true,
              refetchQueries,
            })
            .then(() => {
              setSubmitting(false);
              onComplete();
            })
            .catch(err => {
              console.log('Error Message: ', err.message);
            });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const { startDate, endDate, email, role, allowProjectManagement, timesheetApprover } =
          values;
        const user = users?.find(user => user.emails[0].address === email);
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Item
              label="User"
              hasFeedback
              required
              help={
                errors.name ? errors.name : 'Select the user to add as a resource to this project.'
              }
              validateStatus={errors.name && 'error'}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select user"
                defaultValue={user?.emails[0].address}
                onChange={email => {
                  const user = users?.find(user => user.emails[0].address === email);
                  setFieldValue('email', email);
                  setFieldValue('name', `${user.firstName} ${user.lastName}`);
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={loading}
              >
                {users &&
                  users.map(item => {
                    const { firstName, lastName, emails } = item;
                    return (
                      <Option value={emails[0].address} key={item._id}>
                        {`${firstName} ${lastName} - ${emails[0].address}`}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Role"
              hasFeedback
              help={
                errors.role
                  ? errors.role
                  : 'Select the role for this resource. Roles can have different billing rates.'
              }
              validateStatus={errors.role && 'error'}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select role"
                defaultValue={role}
                onChange={role => {
                  setFieldValue('role', role);
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {roles &&
                  roles.map(role => {
                    return (
                      <Option value={role.name} key={role._id}>
                        {role.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Start Date"
              hasFeedback
              required
              help={
                errors.startDate
                  ? errors.startDate
                  : 'When did the resource start working on the project?'
              }
              validateStatus={errors.startDate && 'error'}
            >
              <DatePicker
                onChange={momentDate => {
                  setFieldValue('startDate', momentDate.toDate());
                }}
                value={startDate ? moment(startDate) : null}
                format={dateFormat}
              />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip title="Resource will only be able to submit timesheets between the given dates.">
                  End Date <Icon type="question-circle-o" />
                </Tooltip>
              }
              hasFeedback
              help="When did the resource stop working on the project? You can also leave this blank if the resource is still working on the project."
            >
              <DatePicker
                onChange={momentDate => {
                  setFieldValue('endDate', momentDate.toDate());
                }}
                value={endDate ? moment(endDate) : null}
                format={dateFormat}
              />
            </Form.Item>
            <Form.Item hasFeedback help="Allow resource to manage this project?">
              <Checkbox
                checked={allowProjectManagement}
                onChange={e => {
                  setFieldValue('allowProjectManagement', e.target.checked);
                }}
              >
                Allow project management
              </Checkbox>
            </Form.Item>
            <Form.Item hasFeedback help="Have this resource approve hours for this project?">
              <Checkbox
                checked={timesheetApprover}
                onChange={e => {
                  setFieldValue('timesheetApprover', e.target.checked);
                }}
              >
                Is timesheet approver?
              </Checkbox>
            </Form.Item>
            <Form.Item label="Note">
              <TextArea
                name="note"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                disabled={isSubmitting}
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                {resource._id ? 'Update Resource' : 'Add Resource'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(UpdateResourceForm);
