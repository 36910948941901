import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import UserList from './UserList';
import { ADMIN_GET_USERS } from '../queries';

const UserListContainer = ({ history, match }) => (
  <Query query={ADMIN_GET_USERS}>
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      const users = get(data, 'admin.users');
      return <UserList title="All Users" history={history} data={users} loading={loading} />;
    }}
  </Query>
);

export default withRouter(UserListContainer);
