import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Query } from 'react-apollo';
import { get, flatten } from 'lodash';
import TimesheetSnapshot from './TimesheetSnapshot';
import TextLoader from '../../common/components/TextLoader';
import { calculateCost } from './ProjectFinancialsDetailsTable';
import { GET_TIMESHEET_SNAPSHOT_BY_PROJECT_ID } from '../queries';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

export const sumLineItemHours = li => {
  const mon = get(li, 'monTask.hours') || 0;
  const tue = get(li, 'tueTask.hours') || 0;
  const wed = get(li, 'wedTask.hours') || 0;
  const thu = get(li, 'thuTask.hours') || 0;
  const fri = get(li, 'friTask.hours') || 0;
  const sat = get(li, 'satTask.hours') || 0;
  const sun = get(li, 'sunTask.hours') || 0;
  return mon + tue + wed + thu + fri + sat + sun;
};

export const createGridData = (timesheets, resources, roles) => {
  return flatten(
    timesheets.map(ts =>
      ts.lineItems.map(li => {
        const total = sumLineItemHours(li);
        const user = ts.user;
        const email = get(ts, 'user.emails[0].address');
        const userRole = get(user, 'role');
        const resource = resources?.find(r => r.email === email && r.startDate);
        const resourceRole = resource?.role;
        return {
          timesheetId: get(ts, '_id'),
          status: get(ts, 'status'),
          endDate: moment(get(ts, 'endDate')).format('MM-DD-YYYY'),
          firstName: get(ts, 'user.firstName'),
          lastName: get(ts, 'user.lastName'),
          role: resourceRole || userRole,
          email: get(ts, 'user.emails[0].address'),
          clientName: get(li, 'project.client.shortName'),
          project: get(li, 'project.name'),
          deliverable: get(li, 'deliverable.deliverableName'),
          milestone: get(li, 'deliverable.milestoneName'),
          mon: get(li, 'monTask.hours'),
          tue: get(li, 'tueTask.hours'),
          wed: get(li, 'wedTask.hours'),
          thu: get(li, 'thuTask.hours'),
          fri: get(li, 'friTask.hours'),
          sat: get(li, 'satTask.hours'),
          sun: get(li, 'sunTask.hours'),
          total,
          totalCost: calculateCost({
            email: get(ts, 'user.emails[0].address'),
            totalHours: total,
            resources,
            roles,
            userRole: get(ts, "user.role"),
          }),
          comment: `Mon:
${get(li, 'monTask.comment') || 'No comments'}
Tue:
${get(li, 'tueTask.comment') || 'No comments'}
Wed:
${get(li, 'wedTask.comment') || 'No comments'}
Thu:
${get(li, 'thuTask.comment') || 'No comments'}
Fri:
${get(li, 'friTask.comment') || 'No comments'}
Sat:
${get(li, 'satTask.comment') || 'No comments'}
Sun:
${get(li, 'sunTask.comment') || 'No comments'}`
        };
      }),
    ),
  );
};

const TimesheetListContainer = ({ user, startDate, endDate, projectId, ...rest }) => (
  <Query
    query={GET_TIMESHEET_SNAPSHOT_BY_PROJECT_ID}
    variables={{
      projectId,
      startDate,
      endDate,
      showNotSubmitted: false,
    }}
  >
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      if (loading) {
        return <TextLoader text="Loading" />;
      }
      const project = get(data, 'projectManagement.project');
      const roles = get(project, 'roles');
      const resources = get(project, 'resources');
      const { timesheetSnapshots } = project;

      // remove tasks for days that fall outside of the selected range
      const timesheetsWithTrimmedTasks = timesheetSnapshots.map(timesheet => {
        const newLineItems = timesheet.lineItems.map(lineItem => {
          for (const property in lineItem) {
            if (property.endsWith('Task') && lineItem[property]) {
              const { date } = lineItem[property];
              if (date < startDate || date > endDate) {
                delete lineItem[property];
              }
            }
          }
          return lineItem;
        });
        return {
          ...timesheet,
          lineItem: newLineItems,
        };
      });

      const rowData = createGridData(timesheetsWithTrimmedTasks, resources, roles);
      return (
        <TimesheetSnapshot
          user={user}
          rowData={rowData}
          startDate={startDate}
          endDate={endDate}
          {...rest}
        />
      );
    }}
  </Query>
);

export default connect(mapStateToProps)(TimesheetListContainer);
