import gql from 'graphql-tag';

export const ADD_USER_APPROVER = gql`
  mutation AddUserApprover($type: String!, $value: String!) {
    addUserApprover(input: { type: $type, value: $value })
  }
`;
export const ADD_USER_DELEGATED_APPROVER = gql`
  mutation AddUserDelegatedApprover($type: String!, $value: String!) {
    addUserDelegatedApprover(input: { type: $type, value: $value })
  }
`;

export const CHANGE_USER_TEMP_PASSWORD_VALUE = gql`
  mutation ChangeUserTempPasswordValue($id: String) {
    changeUserTempPasswordValue(input: { id: $id })
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changePassword($newPassword: String!) {
    changePassword(newPassword: $newPassword)
  }
`;
