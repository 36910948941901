import React, { useState } from 'react';
import styled from 'styled-components';
import TimesheetAttachments from './TimesheetAttachments';
import TimesheetExpense from './TimesheetExpense';
import Card from '../../common/components/Card';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const TimesheetUploadsContainer = ({ timesheet, endDate, readonly }) => {
  const [defaultTab, setDefaultTab] = useState('1');
  const TimesheetHeader = styled.div`
  padding: 1rem;
    justify-content: space-between;
    > * {
      margin: 0 0.5em;
    }
    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  `;
  return (
    <Card>
      <Tabs
        defaultActiveKey={defaultTab}
        onChange={e => {
          setDefaultTab(e);
        }}
      >
        <TabPane tab="Attachments" key="1">
          <div>
            <TimesheetHeader>
              {!readonly &&
                `You may upload supplementary documents, if available (i.e client timesheets etc.)`}
            </TimesheetHeader>
            <TimesheetAttachments timesheet={timesheet} endDate={endDate} readonly={readonly} />
          </div>
        </TabPane>
        <TabPane tab="Expenses" key="2">
          <div>
            <TimesheetExpense timesheet={timesheet} endDate={endDate} readonly={readonly} />
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default TimesheetUploadsContainer;
