import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Select } from 'antd';

const Wrapper = styled.div`
  height: 700px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

const Filters = ({ rowData, onProjectSelect, onUserSelect }) => {
  const projects = rowData.reduce((acc, cur) => {
    if (!acc.includes(cur.project)) {
      return [...acc, cur.project];
    }
    return acc;
  }, []);
  const users = rowData.reduce((acc, cur) => {
    if (!acc.includes(cur.name)) {
      return [...acc, cur.name];
    }
    return acc;
  }, []);
  return (
    <div className="p-4 flex justify-end gap-2">
      <Select
        placeholder="Filter by project"
        notFoundContent="No data for given date range"
        className="w-1/5"
        onChange={onProjectSelect}
        allowClear
      >
        {projects.map(project => (
          <Select.Option key={project} value={project}>
            {project}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="Filter by employee"
        notFoundContent="No data for given date range"
        className="w-1/5"
        onChange={onUserSelect}
        allowClear
      >
        {users.map(user => (
          <Select.Option key={user} value={user}>
            {user}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

class TimesheetDailySnapshot extends React.Component {
  gridApi = undefined;

  onGridReady = params => {
    this.gridApi = params.api;
    const { onExportReady } = this.props;
    onExportReady({ getExportFunc: () => this.export });
  };

  export = () => {
    const { endDate } = this.props;
    const params = {
      fileName: `TimesheetSnapshot-WeekEnding(${endDate})-ExportedOn(${moment(new Date()).format(
        'YYYYMMDD-hhmm',
      )}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };

  render() {
    const { rowData } = this.props;
    return (
      <div>
        <Filters
          rowData={rowData}
          onUserSelect={name => {
            const filterModel = this.gridApi.getFilterModel();
            if (!name) {
              this.gridApi.setFilterModel({
                ...filterModel,
                name: null,
              });
            }
            this.gridApi.setFilterModel({
              ...filterModel,
              name: {
                filterType: 'text',
                type: 'equals',
                filter: name,
              },
            });
            this.gridApi.onFilterChanged();
          }}
          onProjectSelect={project => {
            const filterModel = this.gridApi.getFilterModel();
            if (!project) {
              this.gridApi.setFilterModel({
                ...filterModel,
                project: null,
              });
            }
            this.gridApi.setFilterModel({
              ...filterModel,
              project: {
                filterType: 'text',
                type: 'equals',
                filter: project,
              },
            });
            this.gridApi.onFilterChanged();
          }}
        />
        <Wrapper className="ag-theme-balham">
          <AgGridReact
            enableCellTextSelection
            modules={AllCommunityModules}
            onGridReady={this.onGridReady}
            rowData={rowData}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
            }}
          >
            {/* <AgGridColumn field="userId" width={110} /> */}
            <AgGridColumn field="name" />
            <AgGridColumn field="project" />
            <AgGridColumn field="deliverable" />
            <AgGridColumn field="milestone" />
            <AgGridColumn field="hours" />
            {/* <AgGridColumn field="duration" width={110} />
          <AgGridColumn field="payrollItem" width={110} />
          <AgGridColumn field="serviceItem" />
          <AgGridColumn field="notes" />
          <AgGridColumn field="class" />
          <AgGridColumn field="status" width={175} />
          <AgGridColumn field="email" />
        <AgGridColumn field="clientName" width={150} /> */}
            {/* <AgGridColumn field="endDate" width={125} /> */}
            {/* <AgGridColumn field="lastName"  /> */}
            {/* <AgGridColumn field="firstName" /> */}
            {/* <AgGridColumn field="payrollId" headerName="Payroll ID" /> */}
            {/* <AgGridColumn field="bu" headerName="BU - IS"/> */}
            {/* <AgGridColumn field="milestone" /> */}
          </AgGridReact>
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(TimesheetDailySnapshot);
