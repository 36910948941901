import React from 'react';
import { Avatar, Badge } from 'antd';
import randomcolor from 'randomcolor';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Popover from '../../common/components/Popover';

const Wrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AvatarMenuItem = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f1f1f1;
  &:last-child {
    border-bottom: none;
  }
  a {
    color: #4f4f4f;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const TopAvatarMenu = () => {
  const [user] = useGlobal('user');
  if (!user) {
    return null;
  }
  const { firstName, lastName, title, imageUrl, emails } = user;
  const avatarInitials = firstName.charAt(0) + lastName.charAt(0) || '';
  return (
    <Popover
      placement="bottomRight"
      trigger="hover"
      title={
        <div>
          <div className="text-center font-normal text-xl">{`${firstName} ${lastName}`}</div>
          <div className="text-center font-light">{title}</div>
          <div className="text-center font-light">{emails[0].address}</div>
        </div>
      }
      content={
        <Wrapper>
          <AvatarMenuItem>
            <Link to="/user/profile">Profile</Link>
          </AvatarMenuItem>
          <AvatarMenuItem>
            <Link to="/auth/signout">Logout</Link>
          </AvatarMenuItem>
        </Wrapper>
      }
    >
      <Badge count={null} className="cursor-pointer">
        {imageUrl ? (
          <Avatar className="shadow-md" size={56} src={imageUrl}></Avatar>
        ) : (
          <Avatar
            className="shadow-md"
            size={56}
            style={{
              backgroundColor: randomcolor({ seed: emails[0].address, luminosity: 'dark' }),
            }}
          >
            {avatarInitials}
          </Avatar>
        )}
      </Badge>
    </Popover>
  );
};

export default TopAvatarMenu;
