import React from 'react';
import EmailStatsReciever from './EmailStatsReciever';
const EmailStatsRecieverContainer = ({users,emailStatsRecievers}) => {
  return (
    <EmailStatsReciever users={users} emailStatsRecievers={emailStatsRecievers}  />
    
  );
};

export default EmailStatsRecieverContainer;
