import React from 'react';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { useGlobal } from 'reactn';
import { Formik } from 'formik';
import { Box, Text } from 'rebass/styled-components';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import Card from '../../common/components/Card';
import client from '../../../api/graphql/client';
import { showSuccess } from '../../common/notifications';
import { CHANGE_USER_PASSWORD } from '../mutations';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PasswordChange = () => {
  const [user] = useGlobal('user');
  const email = get(user, 'emails.0.address');
  const [config] = useGlobal('config')
  const isCompanyEmail = email.includes(config['sso-email-domain']);
  return (
    <Card border padded floating title="Change Password">
      {isCompanyEmail ? (
        <Box>
          <Text>Your credentials are managed by Office 365.</Text>
        </Box>
      ) : (
        <Formik
          initialValues={{
            // oldPassword: '',
            newPassword1: '',
            newPassword2: '',
          }}
          validate={values => {
            const errors = {};
            // if (!values.oldPassword) {
            //   errors.oldPassword = 'Required';
            // }
            if (!values.newPassword1) {
              errors.newPassword1 = 'Required';
            }
            if (!values.newPassword2) {
              errors.newPassword2 = 'Required';
            }

            if (values.newPassword1 !== values.newPassword2) {
              errors.newPassword1 = 'Passwords do not match';
              errors.newPassword2 = 'Passwords do not match';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
            const { newPassword1: newPassword } = values;

            client
              .mutate({
                mutation: CHANGE_USER_PASSWORD,
                variables: {
                  newPassword,
                },
              })
              .then(() => {
                setSubmitting(false);
                showSuccess({ message: 'Password Changed' });
                resetForm();
              })
              .catch(err => console.log(err.message));
            return <Redirect to="/user/timesheets" />;
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} {...formItemLayout}>
              {/* <Form.Item
                label="Old Password"
                hasFeedback
                validateStatus={errors.oldPassword && touched.oldPassword && 'error'}
                help={errors.oldPassword && touched.oldPassword && errors.oldPassword}
                required
              >
                <Input
                  type="password"
                  name="oldPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.oldPassword}
                  disabled={isSubmitting}
                />
              </Form.Item> */}
              <Form.Item
                label="New Password"
                hasFeedback
                required
                validateStatus={errors.newPassword1 && touched.newPassword1 && 'error'}
                help={errors.newPassword1 && touched.newPassword1 && errors.newPassword1}
              >
                <Input
                  type="password"
                  name="newPassword1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword1}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                hasFeedback
                required
                validateStatus={errors.newPassword2 && touched.newPassword2 && 'error'}
                help={errors.newPassword2 && touched.newPassword2 && errors.newPassword2}
              >
                <Input
                  type="password"
                  name="newPassword2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword2}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
              >
                <Button loading={isSubmitting} type="primary" htmlType="submit">
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default PasswordChange;
