import classNames from 'classnames';
import { getGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import { Button, Table, HoverCard, Input, Select, Text, Progress, Card as MantineCard } from '@mantine/core';
import Card from '../../common/components/Card';
import { useDebouncedValue } from '@mantine/hooks';
import WeekPicker from '../../common/components/WeekPicker';
// import DateRangePicker from '../../common/components/DateRangePicker';
import moment from 'moment';
import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import TimesheetApprovers from '../../project-manager/components/TimesheetApprovers';
import useEndDateStore from '../../common/stores/useEndDateStore';

const isNetsuiteSandbox =
  window.location.hostname.includes('localhost') || window.location.hostname.includes('stage');
interface State {
  config: {
    'week-end-day': string;
    'netsuite-url': string;
  };
}

export const PUSH_TIMESHEET_TO_NETSUITE = gql`
  mutation PushTimesheetToNetsuite($timesheetId: ID!) {
    pushTimesheetToNetsuite(timesheetId: $timesheetId)
  }
`;

export const GET_TIMESHEETS_FOR_FINANCE = gql`
  query getTimesheetsForFinance($startDate: String, $endDate: String, $queryString: String, $status: String) {
    finance {
      timesheets(startDate: $startDate, endDate: $endDate, queryString: $queryString, status: $status) {
        _id
        status
        endDate
        totalHours
        user {
          _id
          firstName
          lastName
          imageUrl
          integration {
            sourceId
          }
        }
        approvalsRequired {
          status
          approvedBy
          approvers {
            _id
            firstName
            lastName
            imageUrl
            emails {
              address
            }
          }
        }
        tasks {
          _id
          hours
          integration {
            sourceId
            syncStatus
            error
          }
        }
      }
    }
  }
`;

interface ITimesheet {
  _id: string;
  status: string;
  endDate: string;
  totalHours: number;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    integration?: {
      sourceId: string;
    };
  };
  approvalsRequired: {
    status: string;
    approvedBy: string;
    approvers: {
      _id: string;
      firstName: string;
      lastName: string;
      imageUrl: string;
      emails: {
        address: string;
      }[];
    }[];
  }[];
  tasks: {
    _id: string;
    integration?: {
      sourceId: string;
      error: any;
      syncStatus: string;
    };
    hours: number;
  }[];
}

const Actions = ({
  timesheet,
  pushTimesheetToNetsuite,
  refetch,
}: {
  timesheet: ITimesheet;
  pushTimesheetToNetsuite: () => void;
  refetch: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await pushTimesheetToNetsuite();
      await refetch();
    } catch (error) {
      console.error('ERR', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={timesheet.status !== 'Approved'}
      compact
      onClick={handleClick}
      loading={loading}
    >
      Push to Netsuite
    </Button>
  );
};


function ProgressCard({ timesheets }: { timesheets: ITimesheet[] }) {
  const approvedTimesheets = timesheets.filter(t => t.status === 'Approved').length;
  const declinedTimesheets = timesheets.filter(t => t.status === 'Declined').length;
  const pendingTimesheets = timesheets.filter(t => t.status === 'Submitted-Pending').length;
  const cancelledTimesheets = timesheets.filter(t => t.status === 'Cancelled').length;
  const notSubmittedTimesheets = timesheets.filter(t => t.status === 'Not Submitted').length;

  return (
    <MantineCard
      withBorder
      radius="md"
      padding="xl"
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      })}
    >
      <Text fz="xs" tt="uppercase" fw={700} c="dimmed">Total Timesheet</Text>
      <Text fz="lg" fw={500}>
        {timesheets.length}
      </Text>
      <Progress

        radius="xl"
        size={24}
        sections={[
          { value: (approvedTimesheets / timesheets.length) * 100, color: 'green', label: `${approvedTimesheets}`, tooltip: 'Approved Timesheets' },
          { value: (declinedTimesheets / timesheets.length) * 100, color: 'red', label: `${declinedTimesheets}`, tooltip: 'Declined Timesheets' },
          { value: (pendingTimesheets / timesheets.length) * 100, color: 'orange', label: `${pendingTimesheets}`, tooltip: 'Pending Timesheets' },
          { value: (cancelledTimesheets / timesheets.length) * 100, color: 'gray', label: `${cancelledTimesheets}`, tooltip: 'Cancelled Timesheets' },
          { value: (notSubmittedTimesheets / timesheets.length) * 100, color: 'lightgray', label: `${notSubmittedTimesheets}`, tooltip: 'Not Submitted Timesheets' },
        ]} />
    </MantineCard>
  );
}

const TimesheetsPage = () => {
  const { config } = getGlobal<State>();
  const netsuiteUrl = config['netsuite-url'];
  const { endDate } = useEndDateStore();

  const [dateRange, setDateRange] = useState([
    moment(endDate).subtract(6, 'days').toDate(),
    moment(endDate).toDate(),
  ]);
  const [queryString, setQueryString] = useState<string | null>(null);
  const [debouncedQueryString] = useDebouncedValue(queryString, 700);
  const [status, setStatusFilter] = useState<string | null>('Submitted-Pending');

  const [pushTimesheetToNetsuite] = useMutation(PUSH_TIMESHEET_TO_NETSUITE);
  const {
    loading,
    data: apolloData,
    refetch,
  } = useQuery(GET_TIMESHEETS_FOR_FINANCE, {
    variables: {
      startDate: moment(dateRange[0]).format('YYYYMMDD'),
      endDate: moment(dateRange[1]).format('YYYYMMDD'),
      queryString: debouncedQueryString,
      status,
    },
    fetchPolicy: 'no-cache',
  });

  const timesheets: ITimesheet[] = apolloData?.finance?.timesheets || [];
  const timesheetsToShow = timesheets;

  return (
    // @ts-ignore
    <Card
      floating
      bodyBackground={null}
      title="Timesheets"
      loading={loading}
      actionComponent={
        <div className="flex gap-2">
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQueryString(e.target.value);
            }}
            placeholder="Search by name or email"
          />
          <Select
            allowDeselect
            onChange={setStatusFilter}
            value={status}
            data={[
              { value: 'Submitted-Pending', label: 'Submitted-Pending' },
              { value: 'Approved', label: 'Approved' },
              { value: 'Declined', label: 'Declined' },
              { value: 'Cancelled', label: 'Cancelled' },
              { value: 'Not Submitted', label: 'Not Submitted' },
            ]}
            placeholder="Status"
          />
          {/* <DateRangePicker
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            handleDateChange={(range: [moment.Moment, moment.Moment]) => {
              setDateRange([range[0].toDate(), range[1].toDate()]);
            }}
          /> */}
          <WeekPicker
            endDate={moment(dateRange[1]).format('YYYYMMDD')}
            // @ts-ignore
            onDateChange={endDate => {
              setDateRange([moment(endDate).subtract(6, 'days').format('YYYYMMDD'), endDate]);
            }}
          />
        </div>
      }
    >
      <ProgressCard timesheets={timesheetsToShow} />
      <Table>
        <thead>
          <tr>
            <th className="w-1/5">Timesheets</th>
            <th className="w-1/5">End Date</th>
            <th className="w-1/5">Approvers</th>
            <th className="w-1/5">Tasks</th>
            <th className="w-1/5">Actions</th>
          </tr>
        </thead>
        <tbody>
          {timesheetsToShow
            .sort((a, b) => a.status.localeCompare(b.status))
            .map(ts => (
              <tr key={ts._id}>
                <td className="w-1/5">
                  <div className="flex justify-between items-center w-full">
                    <HoverCard width={500} shadow="md" openDelay={300}>
                      <HoverCard.Target>
                        <div className="flex flex-col justify-items-center items-start hover:bg-gray-200 p-2 w-full">
                          {/* <Avatar
                            size={50}
                            radius="xl"
                            src={ts.user.imageUrl}
                          >{`${ts.user.firstName?.charAt(0) + ts.user.lastName?.charAt(0)}`}</Avatar> */}
                          {ts.user.firstName} {ts.user.lastName}
                        </div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <div>
                          <Link to={`/admin/users/${ts.user._id}`}>TS User: {ts.user._id}</Link>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${netsuiteUrl}/app/common/entity/employee.nl?id=${ts.user.integration?.sourceId}&whence=`}
                          >
                            <div>
                              Netsuite Employee ID: {ts.user.integration?.sourceId}
                            </div>
                          </a>
                        </div>
                      </HoverCard.Dropdown>
                    </HoverCard>


                    <HoverCard width={500} shadow="md">
                      <HoverCard.Target>
                        <div
                          key={ts._id}
                          // to={`/reports/timesheets/${ts._id}`}
                          className={classNames('w-5 h-5 bg-gray-200 shadow-xs text-xs text-center', {
                            'bg-green-100 text-white': ts.status === 'Approved',
                            'bg-gray-500 text-white': ts.status === 'Cancelled',
                            'bg-orange': ts.status === 'Submitted-Pending',
                            'bg-red-100 text-white': ts.status === 'Declined',
                          })}
                        >{ts.totalHours}</div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <div>
                          <div>{ts.status}</div>
                          <Link to={`/reports/timesheets/${ts._id}`}>TS ID: {ts._id}</Link>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${netsuiteUrl}/app/accounting/transactions/time/weeklytimebill.nl?startdate=${moment(ts.endDate).subtract(6, 'days').format('M/D/YYYY')}&enddate=${moment(ts.endDate).format('M/D/YYYY')}&employee=${ts.user.integration?.sourceId}`}
                          >
                            <div>
                              Netsuite Timesheet
                            </div>
                          </a>
                        </div>
                      </HoverCard.Dropdown>
                    </HoverCard>

                  </div>
                </td>
                <td>{moment(ts.endDate).format('MM/DD/YY')}</td>
                <td className="w-1/5">
                  <TimesheetApprovers approvalsRequired={ts.approvalsRequired} />
                </td>
                <td className="w-3/5">
                  <div className="flex gap-2">
                    {ts.tasks.map(task => {
                      const sourceId = task.integration?.sourceId;

                      const renderTask = () => {
                        if (task?.integration?.error) {
                          return (
                            <HoverCard width={500} shadow="md">
                              <HoverCard.Target>
                                <div
                                  key={task._id}
                                  className={classNames('w-5 h-5 bg-white p-2 m-1 shadow-xs', {
                                    'bg-red-100': task.integration && !task?.integration?.sourceId,
                                  })}
                                ></div>
                              </HoverCard.Target>
                              <HoverCard.Dropdown>
                                <div>{JSON.stringify(task.integration.error)}</div>
                              </HoverCard.Dropdown>
                            </HoverCard>
                          );
                          // return <Tooltip label={JSON.stringify(task.integration.error['o:errorDetails'])}>
                          //   <div key={task._id} className={classNames('w-5 h-5 bg-white p-2 m-1 shadow-xs', {
                          //     'bg-green-100': task.integration && task?.integration?.sourceId,
                          //     'bg-red-100': task.integration && !task?.integration?.sourceId,
                          //   })}></div>
                          // </Tooltip>
                        }
                        return (
                          <div
                            key={task._id}
                            className={classNames('w-5 h-5 bg-white shadow-xs text-xs text-center', {
                              'bg-green-100 text-white': task.integration && task.integration.sourceId && task.integration?.syncStatus === 'SYNCED',
                              'bg-orange text-white': task.integration && task.integration.sourceId && (task.integration?.syncStatus === 'PENDING_SYNC' || task.integration?.syncStatus === 'PENDING_CHANGES') ,
                              'bg-red-100 text-white': task.integration && (!task.integration.sourceId || task.integration?.syncStatus === 'ERROR'),
                            })}
                          >{task.hours}</div>
                        );
                      };

                      if (sourceId) {
                        return (
                          <a
                            key={task._id}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://3862939${isNetsuiteSandbox ? '-sb1' : ''
                              }.app.netsuite.com/app/accounting/transactions/timebill.nl?id=${task.integration?.sourceId
                              }&l=T`}
                          >
                            {renderTask()}
                          </a>
                        );
                      }

                      return renderTask();
                    })}
                  </div>
                </td>
                <td>
                  <Actions
                    timesheet={ts}
                    pushTimesheetToNetsuite={async () => {
                      await pushTimesheetToNetsuite({
                        variables: {
                          timesheetId: ts._id,
                        },
                      });
                    }}
                    refetch={() =>
                      refetch({
                        startDate: moment(dateRange[0]).format('YYYYMMDD'),
                        endDate: moment(dateRange[1]).format('YYYYMMDD'),
                        queryString,
                        status,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default TimesheetsPage;
