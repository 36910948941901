import React, { useState } from 'react';
import { getGlobal } from 'reactn';
import { Checkbox, DatePicker, Upload, Icon, message, Spin } from 'antd';
import { Formik } from 'formik';
import moment from 'moment';
import Form from '../../common/components/Form';
import Select from '../../common/components/Select';
import Button from '../../common/components/Button';
import client from '../../../api/graphql/client';
import { UPDATE_VACCINE_DETAILS } from '../mutations';
import { uploadFileToAzureBlob } from '../../common';
import { showSuccess, showError } from '../../common/notifications';

const VaccinatedForm = ({ data, setUpdatedDetails }) => {
  const {
    vaccineVersion,
    lastVaccinationDate,
    vaccineProof,
    authorizeShare,
    affirmAccurate,
    discloseStatus,
  } = data || {};
  const [uploading, setUploading] = useState(false);
  const { Option } = Select;
  const dateFormat = 'MM/DD/YYYY';
  const disabledDate = current => {
    // Can not select days before today and today
    return current > moment().endOf('day');
  };
  const beforeUpload = file => {
    const isJpg = file.type === 'image/jpeg';
    const isPng = file.type === 'image/png';
    if (!isJpg && !isPng) {
      message.error('You can only upload JPG/PNG files!');
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return (isJpg || isPng) && isLt20M;
  };
  const uploadButton = uploading ? (
    <Spin />
  ) : (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const uploadHandler = async ({ file }, setFieldValue) => {
    const { user } = getGlobal();
    const { type } = file;
    const fileName = user._id + '_' + Date.now() + '_VaccineCard.' + type.split('/')[1];

    setUploading(true);
    const url = await uploadFileToAzureBlob({
      fileName,
      file,
      blobContainerName: 'uploads',
    });
    setFieldValue('vaccineProof', url);
    setUploading(false);
  };

  const shareLabel =
    'By checking this box, I confirm my understanding that if I provide information about my vaccination status to Spruce Technology, Inc. (Spruce), the information will be maintained in a confidential manner, except that information may be provided to appropriate officials/managers of Spruce who have a need to know this information, primarily for the purpose of ensuring compliance with workplace safety standards. I further understand that Spruce is not requiring me to provide my vaccination status and that if I do not wish to provide my vaccination status, Spruce must treat me as if my status is not fully vaccinated.';
  const affirmLable =
    ' I understand that the Americans with Disabilities Act and other laws may prohibit my employer from disclosing my medical/health information. By checking the box below, I authorize Spruce to disclose to clients and others whom I may encounter for work-related purposes, that I am fully vaccinated or not fully vaccinated against COVID-19. I understand that this disclosure is due to certain health and safety protocols, including third-party verification of vaccination status. I further understand that if I do not consent, Spruce may be required to inform third parties that I have declined, and they may then treat me as unvaccinated. Spruce did not seek to coerce or pressure me to disclose information relating to my vaccination status or my medical or family history.';
  const discloseLabel =
    'Yes, I consent for Spruce to disclose my vaccination status as “fully vaccinated” or “unvaccinated”.';
  return (
    <Formik
      initialValues={{
        vaccineVersion: vaccineVersion,
        lastVaccinationDate: lastVaccinationDate
          ? lastVaccinationDate
          : moment(new Date()).format('YYYY-MM-DD'),
        authorizeShare: authorizeShare,
        affirmAccurate: affirmAccurate,
        vaccineProof: vaccineProof,
        discloseStatus: discloseStatus,
      }}
      validate={values => {
        const errors = {};
        if (!values.lastVaccinationDate) {
          errors.lastVaccinationDate = 'Required';
        }
        if (!values.vaccineVersion) {
          errors.vaccineVersion = 'Required';
        }

        if (!values.affirmAccurate) {
          errors.affirmAccurate = 'Required';
        }
        if (!values.authorizeShare) {
          errors.authorizeShare = 'Required';
        }
        if (!values.discloseStatus) {
          errors.discloseStatus = 'Required';
        }
        if (!values.vaccineProof) {
          errors.vaccineProof = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        return client
          .mutate({
            mutation: UPDATE_VACCINE_DETAILS,
            variables: {
              vaccineVersion: values.vaccineVersion,
              lastVaccinationDate: values.lastVaccinationDate,
              authorizeShare: values.authorizeShare,
              affirmAccurate: values.affirmAccurate,
              vaccineProof: values.vaccineProof,
              discloseStatus: values.discloseStatus,
            },
          })
          .then(() => {
            setTimeout(() => {
              setSubmitting(false);
              setUpdatedDetails(true);
            }, 1000);
            showSuccess({ message: 'Updated Successfully' });
          })
          .catch(err => showError({ message: err.message }));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit} layout="vertical">
            <Form.Item
              label="Which version of vaccine did you receive ?"
              hasFeedback
              validateStatus={errors.vaccineVersion && touched.vaccineVersion && 'error'}
              help={errors.vaccineVersion && touched.vaccineVersion && errors.vaccineVersion}
              className="w-full md:w-1/2 p-1"
            >
              <Select
                name="vaccineVersion"
                style={{ width: 300 }}
                placeholder="Select a vaccine"
                onChange={value => setFieldValue('vaccineVersion', value)}
                onBlur={handleBlur}
                defaultValue={values.vaccineVersion || ''}
              >
                <Option
                  value="moderna"
                  selected={values.vaccineVersion === 'moderna' ? true : false}
                >
                  Moderna
                </Option>
                <Option value="pfizer" selected={values.vaccineVersion === 'pfizer' ? true : false}>
                  Pfizer
                </Option>
                <Option
                  value="jhonsonAndJhonson"
                  selected={values.vaccineVersion === 'jhonsonAndJhonson' ? true : false}
                >
                  Johnson & Johnson
                </Option>
                <Option
                  value="astraZenecavaxzevria"
                  selected={values.vaccineVersion === 'astraZenecavaxzevria' ? true : false}
                >
                  AstraZeneca Vaxzevria
                </Option>
                <Option
                  value="siiCovishield"
                  selected={values.vaccineVersion === 'siiCovishield' ? true : false}
                >
                  Serum Institute of India COVISHIELD
                </Option>
                <Option
                  value="sinopharmBibpCovilo"
                  selected={values.vaccineVersion === 'sinopharmBibpCovilo' ? true : false}
                >
                  SinoPharm/BIBP Covilo
                </Option>
                <Option
                  value="sinovacCovac"
                  selected={values.vaccineVersion === 'sinovacCovac' ? true : false}
                >
                  Sinovac CoronaVac
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="Date of last vaccination" hasFeedback className="w-full md:w-1/2 p-2">
              <DatePicker
                name="lastVaccinationDate"
                format={dateFormat}
                disabledDate={disabledDate}
                defaultValue={moment(values.lastVaccinationDate || null)}
                onChange={(date, dateString) => setFieldValue('lastVaccinationDate', dateString)}
              />
            </Form.Item>
            <Form.Item
              label="Please provide proof of vaccination with a CDC vaccination card or screenshot from a State or City-approved vaccination tracking app"
              hasFeedback
              className="w-full md:w-1/2 p-2"
              validateStatus={errors.vaccineProof && touched.vaccineProof && 'error'}
              help={errors.vaccineProof && touched.vaccineProof && errors.vaccineProof}
            >
              <Upload
                name="proof"
                listType="picture-card"
                customRequest={value => uploadHandler(value, setFieldValue)}
                showUploadList={false}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={1}
                disabled={uploading}
                action={vaccineProof || values.vaccineProof}
              >
                {values.vaccineProof && !uploading ? (
                  <>
                    <img src={values.vaccineProof} alt="Proof" style={{ width: '100%' }} />
                    <h5>Click to upload</h5>
                  </>
                ) : (
                  uploadButton
                )}
              </Upload>

              {values.vaccineProof && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={values.vaccineProof}
                  style={{ textDecoration: 'underline', fontSize: '12px' }}
                >
                  Download Vaccine Card
                </a>
              )}
            </Form.Item>
            <Form.Item
              hasFeedback
              validateStatus={errors.authorizeShare && touched.authorizeShare && 'error'}
              help={errors.authorizeShare && touched.authorizeShare && errors.authorizeShare}
              className="w-full md:w-1/2 p-1"
            >
              <Checkbox
                name="authorizeShare"
                checked={values.authorizeShare ? true : false}
                onChange={handleChange}
              >
                {shareLabel}
              </Checkbox>
            </Form.Item>
            <Form.Item
              hasFeedback
              validateStatus={errors.affirmAccurate && touched.affirmAccurate && 'error'}
              help={errors.affirmAccurate && touched.affirmAccurate && errors.affirmAccurate}
              className="w-full md:w-1/2 p-1"
            >
              <Checkbox
                name="affirmAccurate"
                checked={values.affirmAccurate ? true : false}
                onChange={handleChange}
              >
                {affirmLable}
              </Checkbox>
            </Form.Item>
            <Form.Item
              hasFeedback
              validateStatus={errors.discloseStatus && touched.discloseStatus && 'error'}
              help={errors.discloseStatus && touched.discloseStatus && errors.discloseStatus}
              className="w-full md:w-1/2 p-1"
            >
              <Checkbox
                name="discloseStatus"
                checked={values.discloseStatus ? true : false}
                onChange={handleChange}
              >
                {discloseLabel}
              </Checkbox>
            </Form.Item>

            <Form.Item className="w-full md:w-2/2 p-2">
              <Button loading={isSubmitting} disabled={uploading} type="primary" htmlType="submit">
                Update Vaccine Details
              </Button>
            </Form.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VaccinatedForm;
