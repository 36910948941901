import React,{useState} from 'react';
import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import { CSVLink } from 'react-csv';
import { searchTable } from './SearchTable';
import moment from 'moment';



const FullyVaccinatedReport = ({ data, title }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      ...searchTable('employee',searchText, setSearchText,searchedColumn, setSearchedColumn),
    },
    {
      title: 'Vaccine',
      key: 'vaccine',
      ...searchTable('employee',searchText, setSearchText,searchedColumn, setSearchedColumn),
      render: data => {
        const { vaccineProof, vaccine } = data;
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={vaccineProof}
            style={{ textDecoration: 'underline', fontSize: '12px' }}
          >
            {vaccine}
          </a>
        );
      },
    },
    {
      title: 'Vaccine Date',
      dataIndex: 'vaccineDate',
      key: 'vaccineDate',
      sorter: (a, b) => moment(b.vaccineDate).unix() - moment(a.vaccineDate).unix(),
      sortDirections: ['descend'],
    },
  ];
  return (
    <Card
      border
      floating
      title={title +" : "+ data.length}
      actionComponent={
        <div style={{ padding: '10px' }}>
          <CSVLink filename={'VaccineTracker_FullyVaccinated_'+Date.now()+'.csv'} data={data} className="btn">
            Export CSV
          </CSVLink>{' '}
        </div>
      }
    >
      <div>
        <Table
          highlightOnHover
          columns={columns}
          pagination={false}
          dataSource={data && data.map((r, i) => ({ key: i, ...r }))}
          rowKey={r => r.vaccineDate + Math.random()}
          style={{ height: '400px' }}
        />
      </div>
    </Card>
  );
};

export default FullyVaccinatedReport;
