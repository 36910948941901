import React, { useState } from 'react';
import moment from 'moment';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import TestProofListContainer from './TestProofListContainer';

const TestProofListCard = ({ submitted }) => {
  const [range, setRange] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  });
  const { startDate, endDate } = range;
  const handleDateChange = range => {
    const [startDate, endDate] = range;
    setRange({
      startDate,
      endDate,
    });
  };
  return (
    <Card
      padded={false}
      floating
      title="Upload Proofs"
      bodyBackground="none"
      actionComponent={
        <DateRangePicker
          handleDateChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
        />
      }
      fadedContents={submitted ? false : true}
    >
      <TestProofListContainer
        startDate={startDate.format('YYYYMMDD')}
        endDate={endDate.format('YYYYMMDD')}
        submitted={submitted}
      />
    </Card>
  );
};

export default TestProofListCard;
