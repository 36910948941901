import { useState, useEffect } from 'react';
import moment from 'moment';
import { useGlobal } from 'reactn';
import gql from 'graphql-tag';
import numeral from 'numeral';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import Button from '../../common/components/Button';
import PayrollAllocationReportGrid from './PayrollAllocationReportGrid';
import TextLoader from '../../common/components/TextLoader';
import { sumLineItemHours } from '../../project-manager/components/TimesheetSnapshotContainer';
import { fetchTimesheetsForPeriod } from '../index';

export const PAYROLL_ALLOCATION_QUERY = gql`
  query getTimesheetWeeklySnapshot($startDate: String, $endDate: String) {
    finance {
      timesheetSnapshots(startDate: $startDate, endDate: $endDate)
    }
  }
`;

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const constructGridData = timesheetSnapshots => {
  const timesheets = timesheetSnapshots.reduce((acc, cur) => {
    const email = cur.user.emails[0].address;

    const projects = cur.lineItems
      .reduce((accL, curL) => {
        const totalLineItemHours = sumLineItemHours(curL);
        const existingProject = accL.find(p => p.name === curL.project.name);
        if (existingProject) {
          existingProject.totalLineItemHours += totalLineItemHours;
          return accL;
        }
        return [
          ...accL,
          {
            ...curL.project,
            totalLineItemHours,
          },
        ];
      }, [])
      .map(p => ({
        ...p,
        allocation: p.totalLineItemHours / cur.totalHours,
      }));

    return {
      ...acc,
      [email]: {
        ...cur,
        projects,
      },
    };
  }, {});

  let gridRows = [];
  for (const key in timesheets) {
    const ts = timesheets[key];
    const totalByLineItems = timesheets[key].projects.reduce(
      (acc, cur) => acc + cur.totalLineItemHours,
      0,
    );

    ts.projects.forEach(p => {
      gridRows.push({
        firstName: ts.user.firstName,
        lastName: ts.user.lastName,
        email: ts.user.emails[0].address,
        payrollId: ts.user.payrollId,
        totalHours: p.totalLineItemHours,
        allocation: numeral(p.totalLineItemHours / totalByLineItems).format('0.00%'),
        project: p.name,
        bu: `${p.accountingCode || ''}-${p.accountingCodeSuffix || ''}`,
        endDate: moment(ts.endDate).format('MM-DD-YYYY'),
      });
    });
  }
  return gridRows;

  // return data?.finance?.timesheetSnapshots.map(ts => ({
  //   firstName: ts.user.firstName,
  //   lastName: ts.user.lastName,
  //   email: ts.user.emails[0].address,
  //   totalHours: ts.totalHours,
  // }));
};

const PayrollAllocationReport = () => {
  const [endDate] = useGlobal('endDate');
  const [dateRange, setDateRange] = useState([
    moment(endDate).subtract(7, 'days'),
    moment(endDate),
  ]);
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchedData = await fetchTimesheetsForPeriod({
        startDate: dateRange[0],
        endDate: dateRange[1],
        setLoadingText,
        query: PAYROLL_ALLOCATION_QUERY,
      });
      setTimesheets(fetchedData);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [dateRange]);

  const gridData = constructGridData(timesheets);

  return (
    <Card
      border
      title="Payroll Allocation"
      floating
      padded={false}
      actionComponent={
        <div className="flex">
          <DateRangePicker
            loading={loading}
            handleDateChange={range => {
              setDateRange(range);
            }}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
          />
          <div className="pl-2">
            <Button disabled={loading} onClick={handleExportClick}>
              Export
            </Button>
          </div>
        </div>
      }
    >
      {loading ? (
        <TextLoader text={loadingText} />
      ) : (
        <PayrollAllocationReportGrid onExportReady={onExportReady} rowData={gridData} />
      )}
    </Card>
  );
};

export default PayrollAllocationReport;
