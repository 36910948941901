import { create, SetState } from 'zustand';

type EndDateStoreState = {
  endDate: string | undefined;
  setEndDate: (endDate: any) => void;
};

const useEndDate = create<EndDateStoreState>((set: SetState<EndDateStoreState>) => ({
  endDate: undefined,
  setEndDate: (endDate: any) => set({ endDate }),
}));

export default useEndDate;
