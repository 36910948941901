import React, { useEffect } from 'react';
import { getGlobal } from 'reactn';
import { datadogRum } from '@datadog/browser-rum';
// import Cookies from 'js-cookie';
import TextLoader from '../../common/components/TextLoader';
import { useApolloClient } from '@apollo/react-hooks';

function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
}

const SignOut = () => {
  const client = useApolloClient();

  useEffect(() => {
    async function logout() {
      const { user, config } = getGlobal();
      const emailDomain = config['sso-email-domain'];
      const email = user?.emails[0].address;

      clearAllCookies();

      await localStorage.clear();
      datadogRum.removeUser();
      // resetStore also refetches any active queries
      // await client.resetStore();
      await client.clearStore();
      const signinUrl = `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/auth/signin`;

      if (email) {
        // to logout from ms auth, send them to the ms logout page and delete cookies.
        // to logout from email, just delete cookies.
        const isOauth = email.split('@')[1].includes(emailDomain);
        window.location.href = isOauth
          ? 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
            signinUrl
          : signinUrl;
      }
      window.location.href = signinUrl;
    }
    logout();
  }, [client]);

  return <TextLoader text="Logging you out" />;
};

export default SignOut;
