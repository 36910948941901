import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_TAXONOMY = gql`
  query getTaxonomy($name: String!) {
    taxonomy(name: $name) {
      _id
      name
      description
      terms {
        _id
        name
        order
      }
    }
  }
`;

const useTaxonomy = (termNamePlural) => {
  const { loading, data } = useQuery(GET_TAXONOMY, {
    variables: {
      name: termNamePlural,
    },
  });
  return { loading, data };
};

export default useTaxonomy;