import React from 'react';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TimesheetList from './TimesheetList';
import { GET_TIMESHEETS_FOR_FINANCE } from '../queries';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

const TimesheetListContainer = ({ user, startDate, endDate, queryString }) => (
  <Query query={GET_TIMESHEETS_FOR_FINANCE} variables={{ startDate, endDate, queryString }}>
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      return (
        <TimesheetList user={user} timesheets={get(data, 'finance.timesheetSnapshots')} users={get(data, 'users')} loading={loading} />
      );
    }}
  </Query>
);

export default connect(mapStateToProps)(TimesheetListContainer);
