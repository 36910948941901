import React, { useState } from 'react';
import { Select } from 'antd';
import Card from '../../common/components/Card';
import { Switch, Route } from 'react-router-dom';
import ManualTimesheetCardContainer from './ManualTimesheetCardContainer';
import TimesheetListCard from '../../timesheets/components/TimesheetListCard';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import NewUserForm from '../../admin/components/NewUserForm';
import { Box } from 'rebass/styled-components';

export const ListUsers = props => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { users } = props;
  const { Option } = Select;

  const onChange = value => {
    setSelectedUser(value);
  };
  return (
    <>
      <Card
        floating
        padded={true}
        title="Add Timesheet for Other Users"
        actionComponent={
          <div>
            <Button onClick={() => setModalVisible(true)}>Add User</Button>
            <Modal visible={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
              <Box pt={4}>
                <NewUserForm onComplete={() => setModalVisible(false)} notifyUser={false} />
              </Box>
            </Modal>
          </div>
        }
      >
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a User"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {users &&
            users.map(item => {
              let firstName = item.firstName ? item.firstName : '';
              let lastName = item.lastName ? item.lastName : '';
              let email = item.emails.length ? item.emails[0].address : '';
              return (
                <Option value={item._id} key={item._id}>
                  {firstName + ' ' + lastName + ' - ' + email}
                </Option>
              );
            })}
        </Select>
      </Card>
      {selectedUser && (
        <Switch>
          <Route exact path="/finance/manualTimesheets" render={() => <TimesheetListCard />} />
          <Route
            path="/finance/manualTimesheets/:endDate"
            render={() => <ManualTimesheetCardContainer user={selectedUser} />}
          />
        </Switch>
      )}
    </>
  );
};

export default ListUsers;
