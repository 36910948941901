import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import TextLoader from '../TextLoader';
import classNames from 'classnames';
import { fadeIn as fadeInStyle } from '../../../../globalStyles';

const propTypes = {
  /**
   * Component to render in the top right section of the Card.
   * @type {node}
   */
  actionComponent: PropTypes.node,
  /**
   * CSS string for border-top of the card.
   * @type {string}
   */
  borderTop: PropTypes.string,
  /**
   * Elements to render in the body of the Card.
   * @type {element(s)}
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /**
   * Indicator of whether the Card should be faded and disabled.
   * @type {bool}
   */
  faded: PropTypes.bool,
  /**
   * Indicator of whether the contents of the card should be faded and disabled.
   * @type {bool}
   */
  fadedContents: PropTypes.bool,
  /**
   * Text to display if contents are faded.
   * @type {bool}
   */
  fadedContentsText: PropTypes.string,
  /**
   * Indicator of whether the Card should fade in on mount.
   * @type {bool}
   */
  fadeIn: PropTypes.bool,
  /**
   * CSS string for height.
   * @type {string}
   */
  height: PropTypes.string,
  /**
   * Indicator of whether the Card will lift when hovered over.
   * @type {bool}
   */
  liftOnHover: PropTypes.bool,
  /**
   * Indicator of whether the Card should show a loading screen.
   * @type {bool}
   */
  loading: PropTypes.bool,
  /**
   * Text to display if Card is in loading state.
   * @type {string}
   */
  loadingText: PropTypes.string,
  /**
   * Indicator of whether the card should be padded.
   * @type {bool}
   */
  padded: PropTypes.bool,
  /**
   * Title to show on top of the Card.
   * @type {string}
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Description to show on top of the Card.
   * @type {string}
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * CSS string for the width.
   * @type {string, node}
   */
  width: PropTypes.string,
  /**
   * CSS string for the border of the card. Overwrites borderTop prop.
   * @type {string}
   */
  border: PropTypes.bool,
  /**
   * CSS string for the background of the card.
   * @type {string}
   */
  background: PropTypes.string,
  /**
   * Indicator of whether the body content should be centered.
   * @type {bool}
   */
  centered: PropTypes.bool,
  /**
   * CSS string for the overflow property of the body.
   * @type {string}
   */
  overflow: PropTypes.string,
  /**
   * Function to run when Card is clicked.
   * @type {func}
   */
  onClick: PropTypes.func,
  /**
   * Determines if card should have a cursor on hover
   * @type {func}
   */
  clickable: PropTypes.bool,
  /**
   * Determines whether the Card is disabled or not. No pointer events are allowed.
   * @type {bool}
   */
  disabled: PropTypes.bool,
  /**
   * Determines whether the Card has shadow or not.
   * @type {bool}
   */
  withShadow: PropTypes.bool,
};

const defaultProps = {
  actionComponent: undefined,
  headerComponent: undefined,
  borderTop: undefined,
  children: undefined,
  faded: false,
  fadedContents: false,
  fadedContentsText: undefined,
  fadeIn: true,
  height: 'auto',
  liftOnHover: false,
  loading: false,
  loadingText: undefined,
  padded: false,
  title: undefined,
  description: undefined,
  width: 'auto',
  border: undefined,
  background: '#FFF',
  bodyBackground: '#FFF',
  centered: false,
  overflow: undefined,
  onClick: undefined,
  clickable: false,
  disabled: false,
  withShadow: false,
  floating: false,
};

const Wrapper = styled.div`
  margin-bottom: 1rem;
  border: ${props => (props.border ? '1px solid #e9ebf1' : 'none')};
  position: relative;
  background: ${props => !props.floating && props.background};
  width: ${props => props.width};
  border-top: ${props => props.borderTop};
  border-radius: 5px;
  transition: all 0.5s ease;
  ${props => props.disabled && 'pointer-events:none'};
  ${props =>
    props.withShadow &&
    !props.floating &&
    'box-shadow: 0 7px 14px 0 rgba(59,65,94,.1), 0 3px 6px 0 rgba(0,0,0,.07);'};
  ${props =>
    props.fadeIn &&
    css`
      animation: ${fadeInStyle} 0.2s linear;
    `};
  ${props =>
    props.faded ||
    (props.loading &&
      `
    pointer-events: none;
    opacity: .6;
    cursor: default;
  `)};
  ${props =>
    props.liftOnHover &&
    `
    &:hover {
      box-shadow: 0 9px 20px 0 rgba(59,65,94,.1), 0 5px 20px 0 rgba(0,0,0,.07);
    }
  `};
  ${props =>
    props.clickable &&
    `
    cursor: pointer;
  `};
  ${props => props.fullHeight && 'height: 100%'};
`;

const TopBar = styled.div`
  ${props => (props.floating ? 'padding-bottom: 1em' : 'padding: 1em;')};
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 1.4em;
  display: flex;
  align-items: center;
`;
const Description = styled.div`
  font-weight: 300;
  font-size: 1em;
  display: flex;
  align-items: center;
  padding: 0.1em 0 1em 0.2em;
`;

const Body = styled.div`
  border: ${props => (props.border ? '1px solid #e9ebf1' : 'none')};
  overflow: auto;
  background: ${props => (props.bodyBackground ? props.bodyBackground : 'none')};
  ${props => !props.title && 'border-top-left-radius: 5px;'};
  ${props => !props.title && 'border-top-right-radius: 5px;'};
  border-bottom-right-radius: 5px;
  ${props => !props.description && 'border-top-left-radius: 5px;'};
  border-bottom-left-radius: 5px;
  ${props => props.height && `height: ${props.height};`};
  ${props => props.overflow && `overflow: ${props.overflow};`};
  ${props =>
    props.centered &&
    `
    display: flex;
    justify-content: center;
  `};
  ${props =>
    props.faded &&
    `
    pointer-events: none;
    opacity: .3;
    cursor: default;
  `};
  ${props =>
    props.withShadow &&
    props.floating &&
    'box-shadow: 0 7px 14px 0 rgba(59,65,94,.1), 0 3px 6px 0 rgba(0,0,0,.07);'};
  ${props => props.fullHeight && 'height: 100%'};
`;

const FadedContentsText = styled.div`
  position: absolute;
  padding: 1em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    line-height: initial;
    font-size: 1.3em;
    font-weight: 400;
    text-align: center;
    padding: 2em;
    margin: 0.5em;
    border: 1px dashed #d4d4d4;
    background: rgba(255, 255, 255, 0.9);
  }
`;
const Card = ({
  actionComponent: ActionComponent,
  leftComponent: LeftComponent,
  headerComponent: HeaderComponent,
  borderTop,
  borderWrapper,
  children,
  faded,
  fadedContents,
  fadedContentsText,
  fadeIn,
  floating,
  height,
  liftOnHover,
  loading,
  loadingText,
  padded,
  title,
  description,
  width,
  border,
  background,
  bodyBackground,
  centered,
  overflow,
  onClick,
  clickable,
  withShadow,
  disabled,
  fullHeight,
  ...rest
}) => {
  const Loader = <TextLoader text={loadingText || 'Loading'} />;
  const Top = (
    <>
      <TopBar floating={floating} className="flex flex-col md:flex-row justify-between align-middle gap-1 md:gap-0">
        {!title ? null : <Title>{title}</Title>}
        {!LeftComponent ? <div /> : LeftComponent}
        {!ActionComponent ? <div /> : ActionComponent}
      </TopBar>

      {!description ? <div /> : <Description floating={floating}>{description}</Description>}
    </>
  );
  const shouldShowShadow = withShadow && !loading; // && !floating;
  return (
    <Wrapper
      fullHeight={fullHeight}
      disabled={disabled}
      onClick={onClick}
      clickable={!!onClick || clickable}
      background={background}
      faded={faded}
      fadeIn={fadeIn}
      borderTop={borderTop}
      border={borderWrapper}
      // width={width}
      floating={floating}
      // keep getting this error if boolean value is passed to loading. Have to pass either string 'true' or undefined...
      // Warning: Received `false` for a non-boolean attribute `loading`.
      // If you want to write it to the DOM, pass a string instead: loading="false" or loading={value.toString()}.
      // If you used to conditionally omit it with loading={condition && value}, pass loading={condition ? value : undefined} instead.
      loading={loading ? 'true' : undefined}
      {...rest}
    >
      {HeaderComponent || null}
      {title || ActionComponent || description ? Top : null}

      <Body
        fullHeight={fullHeight}
        faded={!loading && fadedContents}
        overflow={overflow}
        padded={padded}
        height={height}
        centered={centered}
        floating={floating}
        border={border}
        className={classNames('Card_Body transition-shadow duration-200', {
          'shadow-sm': shouldShowShadow,
          'hover:shadow-md': liftOnHover,
          'p-2 md:p-4': padded,
        })}
        bodyBackground={bodyBackground}
      >
        {loading ? Loader : children}
      </Body>
      {!loading && fadedContents && fadedContentsText && (
        <FadedContentsText>
          <div>{fadedContentsText}</div>
        </FadedContentsText>
      )}
    </Wrapper>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
