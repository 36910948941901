import React, { useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import Card from '../Card';
import Table from '../Table';
import NewApproverForm from './NewApproverForm';
import client from '../../../../api/graphql/client';
import { REMOVE_APPROVER } from '../../../admin/mutations';
import { ADMIN_GET_USER_BY_ID } from '../../../admin/queries';

const UserApprovers = ({
  data, title, userEmail, userId, delegated = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      key: 'actions',
      width: 50,
      render: approver => {
        const { type, value } = approver;
        return (
          <Button
            type="danger"
            icon="close"
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              client
                .mutate({
                  mutation: REMOVE_APPROVER,
                  awaitRefetchQueries: true,
                  variables: {
                    userEmail,
                    type,
                    value,
                    delegated,
                  },
                  refetchQueries: [
                    {
                      query: ADMIN_GET_USER_BY_ID,
                      variables: {
                        userId,
                      },
                    },
                  ],
                })
                .then(() => {
                  setIsLoading(false);
                });
            }}
          >
            Remove
          </Button>
        );
      },
    },
  ];
  return (
    <Card
      title={title}
      floating
      actionComponent={(
        <div>
          <Modal visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
            <NewApproverForm
              onComplete={() => setIsModalVisible(false)}
              userEmail={userEmail}
              userId={userId}
              delegated={delegated}
            />
          </Modal>
          <Button onClick={() => setIsModalVisible(true)}>Add Approver</Button>
        </div>
)}
    >
      <div>
        <Table
          highlightOnHover
          columns={columns}
          pagination={false}
          dataSource={data.map((r, i) => ({ key: i, ...r }))}
          rowKey={r => r.type + r.value}
        />
      </div>
    </Card>
  );
};

export default UserApprovers;
