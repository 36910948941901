import moment from 'moment';

const defaultState = {
  timesheets: {
    endDate: moment().endOf('isoWeek').format('YYYYMMDD'),
  },
  // meteor: {
  //   user: {
  //     _id: '123',
  //     emails: [{ address: 'mjoldic@sprucetech.com' }],
  //     profile: {
  //       firstName: 'Mirza',
  //       lastName: 'Joldic',
  //     },
  //     token: '0-ZBstE235JFFzQxA74nfahzxWkzUofwsol4cK_CXqV'
  //   },
  // },
};

export default defaultState;