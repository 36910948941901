import React, { Fragment } from 'react';
import styled, { keyframes, css } from 'styled-components';
import moment from 'moment';
import Drawer from '../../common/components/Drawer';
import Badge from '../../common/components/Badge';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Timeline from '../../common/components/Timeline';

const shake = keyframes`
    0% { transform: rotate(0deg); }
   20% { transform: rotate(0deg); }
   50% { transform: rotate(3deg) scale(1.05); }
   60% { transform: rotate(-3deg) scale(1.025); }
   80% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const StyledBadge = styled(Badge)`
  &:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  ${props => props.shouldShake && css`animation: ${shake} 1s infinite;`};
`;

const StyledTimeline = styled(Timeline)`
  > li:last-child {
    padding-bottom: 0;
  }
  & .ant-timeline-item-content {
    min-height: initial;
  }
`;

const determineIconType = ({ status }) => {
  switch (status) {
    case 'Approved':
      return 'check';
    case 'Declined':
      return 'close';
    default:
      return null;
  }
};

const determineIconColor = ({ status }) => {
  switch (status) {
    case 'Approved':
      return 'green';
    case 'Declined':
      return 'red';
    case 'Cancelled':
      return 'gray';
    default:
      return 'blue';
  }
};

const renderTimelineDot = ({ status }) => {
  switch (status) {
    case 'Approved':
      return <Icon type={determineIconType({ status })} style={{ fontSize: '16px' }} />;
    case 'Declined':
      return <Icon type={determineIconType({ status })} style={{ fontSize: '16px' }} />;

    default:
      return null;
  }
};

class TimesheetTimeline extends React.Component {
  state = {
    visible: false,
    timelineClicked: false,
  };

  handleClick = () => this.setState({ visible: !this.state.visible, timelineClicked: true });

  handleClose = () => this.setState({ visible: false });

  render() {
    const { timeline, status } = this.props;
    return (
      <div>
        <IconContainer
          onClick={this.handleClick}
          shouldShake={!this.state.timelineClicked && status === 'Declined'}
        >
          <StyledBadge dot={status === 'Declined'}>
            <Button icon="clock-circle-o">Activity</Button>
          </StyledBadge>
        </IconContainer>
        <Drawer
          title="Timesheet Activity"
          placement="right"
          width={400}
          onClose={this.handleClose}
          visible={this.state.visible}
        >
          <StyledTimeline>
            {timeline.map(event => {
              return (
                <Timeline.Item
                  key={event.date}
                  dot={renderTimelineDot({ status: event.type })}
                  color={determineIconColor({ status: event.type })}
                >
                  {`${moment(event.date).format('L LT')}: ${event.type} by ${event.createdBy}`}
                  {event.metadata && (
                    <Fragment>
                      <div>
                        <u>Reason</u>
                      </div>
                      <div>{event.metadata}</div>
                    </Fragment>
                  )}
                </Timeline.Item>
              );
            })}
          </StyledTimeline>
        </Drawer>
      </div>
    );
  }
}

export default TimesheetTimeline;
