import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import VaccineProofContainer from './VaccineProofContainer';
import VaccineReportContainer from './VaccineReportContainer';
import Page from '../../common/components/Page';

const VaccineTrackerPage = () => {
  return (
    <Page>
      <Switch>
        <Route exact path="/vaccine-tracker/proofs" render={() => <VaccineProofContainer />} />
        <Route exact path="/vaccine-tracker/report" render={() => <VaccineReportContainer />} />
      </Switch>
    </Page>
  );
};

export default withRouter(VaccineTrackerPage);
