import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { EditText as OriginalEditText } from 'react-edit-text';
import TextLoader from '../../common/components/TextLoader';
import {
  ADD_PROJECT_EXPENSE,
  UPDATE_PROJECT_EXPENSE,
  REMOVE_PROJECT_EXPENSE,
} from './ProjectExpenses';
import { GET_PROJECT_BY_ID } from '../queries';
import 'react-edit-text/dist/index.css';
import { updateDeliverable } from '../../common/components/UpdateDeliverableForm/UpdateDeliverableForm';
import { useApolloClient } from '@apollo/react-hooks';

const EditText = props => (
  <OriginalEditText
    {...props}
    style={{
      outline: '1px dashed gray',
      margin: 0,
      opacity: props.readonly ? 0.5 : 1,
      transition: 'opacity 0.2s ease-in-out',
      ...props.style,
    }}
    defaultValue={`${props.defaultValue}`}
  />
);

export const UPDATE_PROJECT_FIELD = gql`
  mutation UpdateProjectField($projectId: ID!, $field: String!, $value: String!, $type: String!) {
    updateProjectField(projectId: $projectId, field: $field, value: $value, type: $type)
  }
`;

const GET_DELIVERABLES_AND_EXPENSES = gql`
  query getProjectDeliverablesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        deductExpenses
        deliverables(withHours: true) {
          _id
          projectId
          milestoneName
          deliverableName
          accountingCode
          accountingCodeSuffix
          totalApprovedHours
          totalCost
          budget
          includeInAllProjects
          budgetPercent
          blendedRate
          tasks {
            _id
            hours
          }
          expenses {
            expenseId
            budget
          }
        }
        expenses {
          _id
          name
          amount
          proposedAmount
        }
      }
    }
  }
`;

const Table = ({ children, className, ...rest }) => {
  return (
    <table className={classNames('', className)} {...rest}>
      {children}
    </table>
  );
};
const Tbody = ({ children, className, ...rest }) => {
  return (
    <tbody className={classNames('text-center whitespace-no-wrap', className)} {...rest}>
      {children}
    </tbody>
  );
};
const Tr = ({ children, className, ...rest }) => {
  return (
    <tr className={classNames('', className)} {...rest}>
      {children}
    </tr>
  );
};
const Td = ({ children, className, ...rest }) => {
  return (
    <td
      className={classNames(className, 'h-10 px-1 border border-gray-500 border-solid')}
      {...rest}
    >
      {children}
    </td>
  );
};

const CategorySection = ({ project, expenses, category, deliverables, setDeliverables }) => {
  const [updating, setUpdating] = useState(false);
  const client = useApolloClient();

  const budgetPercent = category.budgetPercent || 0;
  const budget = category.budget || 0;

  // const categoryBudget = (project.budget * budgetPercent) / 100;
  const total = expenses.reduce((acc, cur) => {
    return acc + cur.amount * (budgetPercent / 100);
  }, budget || 0);

  return (
    <Table
      className="category-section"
      style={{ opacity: updating ? 0.8 : 1, transition: 'opacity 0.2s ease-in-out' }}
    >
      <Tbody className="">
        {/* <Tr /> */}
        <Tr className="">
          <Td className="w-32 font-bold">
            <EditText
              readonly={updating}
              type="number"
              defaultValue={`${budgetPercent}`}
              formatDisplayText={value => numeral(value / 100).format('0,0.[00]%')}
              onSave={async ({ value }) => {
                setUpdating(true);
                await updateDeliverable({
                  values: {
                    ...category.deliverable,
                    budget: project.budget * (parseFloat(value) / 100),
                    budgetPercent: parseFloat(value),
                  },
                  setSubmitting: () => {},
                  deliverable: category.deliverable,
                  isMilestone: true,
                  client,
                  refetchQueries: [
                    { query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId: project._id } },
                  ],
                  onComplete: () => {},
                  usePercentage: true,
                });
                setUpdating(false);
              }}
            />
          </Td>
          <Td className="w-32 font-bold">Est. Hours</Td>
        </Tr>
        <Tr className="">
          <Td className="font-bold">{category.name.replace(/[0-9]/g, '')}</Td>
          <Td className="">
            <EditText
              readonly={updating}
              type="number"
              className="italic"
              defaultValue={`${category.blendedRate}`}
              formatDisplayText={value => numeral(value).format('$0,0.[00]')}
              onSave={async ({ value }) => {
                setUpdating(true);
                await updateDeliverable({
                  values: {
                    ...category.deliverable,
                    blendedRate: parseFloat(value),
                  },
                  setSubmitting: () => {},
                  deliverable: category.deliverable,
                  isMilestone: true,
                  client,
                  refetchQueries: [
                    { query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId: project._id } },
                  ],
                  onComplete: () => {},
                  usePercentage: true,
                });
                setUpdating(false);
              }}
            />
          </Td>
        </Tr>
        <Tr className="" style={{ backgroundColor: '#E2EEDA' }}>
          <Td className="">{numeral(budget).format('$0,0.[00]')}</Td>
          <Td className="">
            {category?.blendedRate > 0
              ? numeral(budget / category.blendedRate).format('0,0.[0]')
              : '-'}
          </Td>
        </Tr>
        {expenses.map(expense => (
          <Tr key={expense._id} style={{ backgroundColor: '#FFF2CC' }}>
            <Td className="">
              {numeral((expense.amount * budgetPercent) / 100).format('$0,0.[00]')}
            </Td>
            <Td className="bg-gray-500"></Td>
          </Tr>
        ))}
        <Tr>
          <Td className="font-bold">{numeral(total).format('$0,0.[00]')}</Td>
          <Td className="bg-gray-500" colSpan={2}></Td>
        </Tr>
        <Tr>
          <Td className="bg-gray-500" colSpan={2}></Td>
        </Tr>
        <Tr />
      </Tbody>
    </Table>
  );
};

const Expense = ({ expense, onChange, projectId }) => {
  // mognodb ids are longer than 5 characters so this should indicate if its a temp expense
  const tempExpense = expense._id.length <= 5;

  const [updating, setUpdating] = useState(false);
  const [addProjectExpense] = useMutation(ADD_PROJECT_EXPENSE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId } }],
  });
  const [updateProjectExpense] = useMutation(UPDATE_PROJECT_EXPENSE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId } }],
  });
  const [removeProjectExpense] = useMutation(REMOVE_PROJECT_EXPENSE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId } }],
  });

  return (
    <Tr className="expense" style={{ backgroundColor: '#FFF2CC' }}>
      <Td className="text-left">
        <EditText
          readonly={updating}
          defaultValue={expense.name}
          onSave={async ({ value }) => {
            setUpdating(true);
            if (tempExpense) {
              await addProjectExpense({
                variables: {
                  projectId,
                  name: value,
                  amount: 0,
                },
              });
            } else {
              if (value === '') {
                // delete expense if name is empty
                await removeProjectExpense({
                  variables: {
                    expenseId: expense._id,
                  },
                });
              } else {
                await updateProjectExpense({
                  variables: {
                    expenseId: expense._id,
                    name: value,
                    amount: expense.amount,
                  },
                });
                setUpdating(false);
              }
            }
          }}
        />
      </Td>
      <Td className="1">
        <EditText
          readonly={tempExpense}
          type="number"
          defaultValue={`${expense.proposedAmount}`}
          formatDisplayText={value => numeral(value).format('$0,0.[00]')}
          onSave={async ({ value }) => {
            setUpdating(true);
            await updateProjectExpense({
              variables: {
                expenseId: expense._id,
                name: expense.name,
                amount: expense.amount,
                proposedAmount: parseFloat(value || 0),
              },
            });
            setUpdating(false);
          }}
        />
      </Td>
      <Td className="2">
        <EditText
          readonly={tempExpense}
          type="number"
          defaultValue={expense.amount === 0 ? null : expense.amount}
          formatDisplayText={value => numeral(value).format('$0,0.[00]')}
          onSave={async ({ value }) => {
            setUpdating(true);
            await updateProjectExpense({
              variables: {
                expenseId: expense._id,
                name: expense.name,
                amount: parseFloat(value || 0),
              },
            });
            setUpdating(false);
          }}
        />
      </Td>
    </Tr>
  );
};

const ChangeOrderExpense = ({ tempExpense, category, project, expense, expenseBudget }) => {
  const [updating, setUpdating] = useState(false);
  const client = useApolloClient();
  return (
    <EditText
      readonly={tempExpense || !category.budget || updating}
      type="number"
      defaultValue={expenseBudget}
      formatDisplayText={value => numeral(value).format('$0,0.[00]')}
      onSave={async ({ value }) => {
        setUpdating(true);
        await updateDeliverable({
          values: {
            ...category.deliverable,
            expenseId: expense._id,
            expenseBudget: parseFloat(value),
          },
          setSubmitting: () => {},
          deliverable: category.deliverable,
          isMilestone: true,
          client,
          refetchQueries: [
            {
              query: GET_DELIVERABLES_AND_EXPENSES,
              variables: { projectId: project._id },
            },
          ],
          onComplete: () => {},
          usePercentage: false,
        });
        setUpdating(false);
      }}
    />
  );
};

const ChangeOrder = ({ category, project, expenses, index, setExpenses, totalChangeOrders }) => {
  const [updating, setUpdating] = useState(false);
  const client = useApolloClient();
  const totalBudget = expenses.reduce((acc, cur) => {
    const expenseBudget = category.expenses.find(e => e.expenseId === cur._id)?.budget;
    return acc + (expenseBudget || 0);
  }, category.budget || 0);

  return (
    <Table className="change-order">
      <Tbody className="">
        <Tr>
          <Td className="text-center font-bold" style={{ minWidth: '11rem' }}>{`CO${
            index + 1
          }`}</Td>
        </Tr>
        {/* <Tr>
          <Td className="text-center font-bold">
            <EditText
              className={classNames({
                'font-bold': !!title,
              })}
              placeholder="Specify title"
              formatDisplayText={value => `"${value}"`}
              defaultValue={title}
              onSave={({ value }) => {
                setTitle(value);
              }}
            />
          </Td>
        </Tr> */}
        <Tr>
          <Td className="text-center font-bold">{category.name}</Td>
        </Tr>
        <Tr style={{ backgroundColor: '#E2EEDA' }}>
          <Td className="text-center font-bold">
            <EditText
              readonly={updating}
              type="number"
              defaultValue={`${category.budget}`}
              formatDisplayText={value => numeral(value).format('$0,0.[00]')}
              onSave={async ({ value }) => {
                setUpdating(true);
                await updateDeliverable({
                  values: {
                    ...category.deliverable,
                    budget: parseFloat(value),
                  },
                  setSubmitting: () => {},
                  deliverable: category.deliverable,
                  isMilestone: true,
                  client,
                  refetchQueries: [
                    { query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId: project._id } },
                  ],
                  onComplete: () => {},
                  usePercentage: false,
                });
                setUpdating(false);
              }}
            />
          </Td>
        </Tr>
        {expenses.map((expense, index) => {
          // mognodb ids are longer than 5 characters so this should indicate if its a temp expense
          const tempExpense = expense._id.length <= 5;
          const expenseBudget = category.expenses.find(e => e.expenseId === expense._id)?.budget;
          return (
            <Tr key={expense._id} style={{ backgroundColor: '#FFF2CC' }}>
              <Td className="">
                <ChangeOrderExpense
                  tempExpense={tempExpense}
                  category={category}
                  project={project}
                  expense={expense}
                  expenseBudget={expenseBudget}
                />
              </Td>
            </Tr>
          );
        })}
        <Tr>
          <Td className="font-bold">{numeral(totalBudget).format('$0,0.[00]')}</Td>
        </Tr>
        {/* <Tr>
          <Td className=""></Td>
        </Tr> */}
        <Tr>
          <Td className="font-bold">{index + 1 === totalChangeOrders && 'Grand Total Contract'}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

const ProjectSummary = ({ project }) => {
  const [updating, setUpdating] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [deliverables, setDeliverables] = useState([]);

  const [updateProject] = useMutation(UPDATE_PROJECT_FIELD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_DELIVERABLES_AND_EXPENSES, variables: { projectId: project._id } },
      { query: GET_PROJECT_BY_ID, variables: { projectId: project._id } },
    ],
  });

  const { loading, data } = useQuery(GET_DELIVERABLES_AND_EXPENSES, {
    variables: {
      projectId: project._id,
    },
  });

  useEffect(() => {
    let tempExpenses = [...(data?.projectManagement?.project?.expenses || [])];
    const numberRowsToFill = 6 - tempExpenses.length;
    for (let index = 0; index < numberRowsToFill; index++) {
      tempExpenses.push({
        _id: `${index}`,
        proposed: null,
        amount: null,
        budget: null,
        name: '',
      });
    }
    setExpenses(tempExpenses);

    setDeliverables(data?.projectManagement?.project?.deliverables || []);
  }, [loading, data]);

  if (loading) {
    return <TextLoader text="Loading" />;
  }

  let rowsObj = deliverables.reduce((acc, cur) => {
    if (acc[cur.milestoneName]) {
      return {
        ...acc,
        [cur.milestoneName]: [...acc[cur.milestoneName], cur],
      };
    } else {
      return {
        ...acc,
        [cur.milestoneName]: [cur],
      };
    }
  }, {});

  let categories = [];
  for (const [key, value] of Object.entries(rowsObj)) {
    const totalCost = value.reduce((acc, cur) => acc + cur.totalCost, 0);
    const milestoneDeliverable = value.find(
      deliverable => deliverable.deliverableName === deliverable.milestoneName,
    );
    categories.push({
      _id: milestoneDeliverable?._id,
      budgetPercent: milestoneDeliverable?.budgetPercent,
      deliverableIds: value.map(deliverable => deliverable._id),
      name: key,
      value: totalCost,
      budget: milestoneDeliverable?.budget || value.reduce((acc, cur) => acc + cur.budget, 0),
      blendedRate: milestoneDeliverable?.blendedRate || 0,
      expenses: milestoneDeliverable?.expenses || [],
      deliverable: {
        projectId: project._id,
        deliverableName: key,
        milestoneName: key,
        ...milestoneDeliverable,
      },
    });
  }

  const phases = categories.filter(c => !c.name.startsWith('ASD'));
  const changeOrders = categories.filter(c => c.name.startsWith('ASD'));

  const totalProposed = expenses.reduce(
    (acc, cur) => acc + (cur.proposedAmount || 0),
    project.proposedBudget || 0,
  );
  const totalActual = expenses.reduce((acc, cur) => acc + (cur.amount || 0), project.budget || 0);
  const totalArchitectualFee = categories.reduce((acc, cur) => {
    return acc + cur.budget;
  }, 0);
  const totalConsultantFee = expenses.reduce((expenseAcc, expense) => {
    const totalPhasesFee = categories.reduce((acc, cur) => {
      const fee = (expense.amount * cur.budgetPercent) / 100;
      return acc + (fee || 0);
    }, 0);
    const totalChangeOrderFee = categories.reduce((acc, cur) => {
      const fee = cur.expenses
        .filter(e => e.expenseId === expense._id)
        .reduce((acc, cur) => acc + cur.budget, 0);
      return acc + fee;
    }, 0);

    return expenseAcc + totalPhasesFee + totalChangeOrderFee;
  }, 0);

  return (
    <div className="flex gap-2 p-2 overflow-auto">
      <Table className="">
        <Tbody className="">
          {/* <Tr /> */}
          <Tr />
          <Tr className="">
            <Td className="w-32 uppercase font-bold text-left">Base Fees</Td>
            <Td className="w-32 uppercase font-bold">Proposed</Td>
            <Td className="w-32 uppercase font-bold">Actual</Td>
          </Tr>
          <Tr style={{ backgroundColor: '#E2EEDA' }}>
            <Td className="font-bold text-left">Architectural</Td>
            <Td className="">
              <EditText
                readonly={updating}
                type="number"
                defaultValue={`${project.proposedBudget}`}
                formatDisplayText={value => numeral(value).format('$0,0.[00]')}
                onSave={async ({ value }) => {
                  setUpdating(true);
                  await updateProject({
                    variables: {
                      projectId: project._id,
                      field: 'proposedBudget',
                      value: value,
                      type: 'number',
                    },
                  });
                  setUpdating(false);
                }}
              />
            </Td>
            <Td className="">
              <EditText
                readonly={updating}
                defaultValue={`${project.budget}`}
                formatDisplayText={value => numeral(value).format('$0,0.[00]')}
                onSave={async ({ value }) => {
                  setUpdating(true);
                  await updateProject({
                    variables: {
                      projectId: project._id,
                      field: 'budget',
                      value: value,
                      type: 'number',
                    },
                  });
                  // setProject({ ...project, budget: parseFloat(value) });
                  // const _deliverables = deliverables.map(deliverable => {
                  //   return {
                  //     ...deliverable,
                  //     budget: parseFloat(value) * (deliverable.budgetPercent / 100),
                  //   };
                  // });
                  // setDeliverables(_deliverables);
                  setUpdating(false);
                }}
              />
            </Td>
          </Tr>
          {expenses.map((expense, index) => (
            <Expense
              key={`${expense._id}-${index}`}
              projectId={project._id}
              expense={expense}
              onChange={async updatedExpense => {
                const newExpenses = [...expenses];
                newExpenses[index] = { ...updatedExpense };
                setExpenses(newExpenses);
              }}
            />
          ))}
          <Tr>
            <Td className="font-bold text-right">Total Fees</Td>
            <Td className="font-bold">{`${numeral(totalProposed).format('$0,0.[00]')}`}</Td>
            <Td className="font-bold">{`${numeral(totalActual).format('$0,0.[00]')}`}</Td>
          </Tr>
          <Tr>
            <Td className="font-bold text-right" colSpan={2}>
              Base Architectual Fee
            </Td>
            <Td className="font-bold">{`${numeral(project.budget).format('$0,0.[00]')}`}</Td>
          </Tr>
          {/* <Tr /> */}
        </Tbody>
      </Table>
      <div className="flex">
        {phases.map((category, i) => {
          return (
            <CategorySection
              key={`${i}-${category._id || category.name}`}
              project={project}
              category={category}
              expenses={expenses}
              deliverables={deliverables}
              setDeliverables={setDeliverables}
            />
          );
        })}
      </div>
      <div className="flex">
        {changeOrders.map((category, index) => (
          <ChangeOrder
            index={index}
            key={`${category._id}-${index}`}
            project={project}
            category={category}
            expenses={expenses}
            totalChangeOrders={changeOrders.length}
            setExpenses={setExpenses}
          />
        ))}
      </div>
      <div className="flex">
        <Table className="">
          <Tbody className="">
            {/* <Tr></Tr> */}
            <Tr></Tr>
            <Tr>
              <Td className="font-bold w-32">Total Fee's</Td>
            </Tr>
            <Tr style={{ backgroundColor: '#E2EEDA' }}>
              <Td>{numeral(totalArchitectualFee).format('$0,0.[00]')}</Td>
            </Tr>
            {expenses.map((expense, index) => {
              const totalPhasesFee = categories.reduce((acc, cur) => {
                const fee = (expense.amount * cur.budgetPercent) / 100;
                return acc + (fee || 0);
              }, 0);
              const totalChangeOrderFee = categories.reduce((acc, cur) => {
                const fee = cur.expenses
                  .filter(e => e.expenseId === expense._id)
                  .reduce((acc, cur) => acc + cur.budget, 0);
                return acc + fee;
              }, 0);
              return (
                <Tr key={expense._id} style={{ backgroundColor: '#FFF2CC' }}>
                  <Td className="">
                    {numeral(totalChangeOrderFee + totalPhasesFee).format('$0,0.[00]')}
                  </Td>
                </Tr>
              );
            })}
            <Tr></Tr>
            {/* <Tr></Tr> */}
            <Tr>
              <Td className="font-bold text-lg" style={{ borderWidth: '2px ' }}>
                {numeral(totalConsultantFee + totalArchitectualFee).format('$0,0.[00]')}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default ProjectSummary;
