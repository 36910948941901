import moment from 'moment';
import { get, keyBy, values } from 'lodash';
import client from '../../api/graphql/client';

const fetchTimesheetsForPeriod = async ({ startDate, endDate, setLoadingText, query }) => {
  let start = moment(startDate);
  let end = moment(endDate);
  let current = start.clone();
  let fetchedData = [];

  while (current.isBefore(end)) {
    setLoadingText(`Loading ${current.format('MMMM YYYY')}`); // Set loading false after fetching
    let monthEnd = moment(current).endOf('month');
    if (monthEnd.isAfter(end)) {
      monthEnd = end.clone();
    }
    const { data } = await client.query({
      query: query,
      variables: {
        startDate: current.format('YYYYMMDD'),
        endDate: monthEnd.format('YYYYMMDD'),
      },
    });

    fetchedData.push(...(get(data, 'finance.timesheetSnapshots') || []));
    current = monthEnd.add(1, 'day');
  }

  const hashMap = keyBy(fetchedData, '_id');
  const finalFetchedData = values(hashMap);
  
  return finalFetchedData;
};

export { fetchTimesheetsForPeriod };
