import React from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import TimesheetApprovalListCard from './TimesheetApprovalListCard';
import ProjectManagement from './ProjectManagement';
import Page from '../../common/components/Page';

// need router history to change url on tab change
const ProjectManagerPage = () => {
  return (
    <Page full>
      <Switch>
        <Route
          exact
          path="/project-management"
          render={() => <Redirect to="/project-management/details" />}
        />
        <Route
          path="/project-management/timesheets/:endDate"
          render={() => <TimesheetApprovalListCard />}
        />
        <Route path="/project-management/timesheets" render={() => <TimesheetApprovalListCard />} />
        <Route path="/project-management" render={() => <ProjectManagement />} />
      </Switch>
    </Page>
  );
};

export default withRouter(ProjectManagerPage);
