import React, { useState } from 'react';
import Card from '../Card';
import { Switch } from 'antd';
import client from '../../../../api/graphql/client';
 import { ACTIVE_INACTIVE_ACCOUNT } from '../../../admin/mutations';


const ActiveInactiveAccount = ({ data, title }) => {
    const { id, accountActive } = data[0];
  const [status, setStatus] = useState(accountActive );
  const [submitting, setSubmitting] = useState(false);
 
    const handlechange =(e)=>{
        setSubmitting(true);
        client
        .mutate({
          mutation: ACTIVE_INACTIVE_ACCOUNT,
          variables: {
            id: id,
            status:e
          },
        })
        .then(() => {
          setSubmitting(false);          
        })
        .catch(err => {
          console.log('Error Message: ', err.message);
        });
    }


  return (
    <Card
      title={title}
      description="Indicates whether user is an active company employee."
      floating
      actionComponent={
        <div>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            disabled={submitting}
            defaultChecked={status}
            onChange={e => {
                setStatus(e)
                handlechange(e);
            }}
          />
        </div>
      }
    />
  );
};

export default ActiveInactiveAccount;
