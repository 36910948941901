import React from 'react';
import ListUsers from './ListUsers';
import TimesheetLoading from '../../timesheets/components/TimesheetLoading';
import { Query } from 'react-apollo';
import { ADMIN_GET_USERS } from '../../admin/queries';

const ManualTimesheetsPage = props => {
  return (
    <Query query={ADMIN_GET_USERS}>
      {({ loading, error, data }) => {
        if (error) return <div>error</div>;
        if (loading || !data) return <TimesheetLoading />;
        return <ListUsers users={data.admin.users} />;
      }}
    </Query>
  );
};

export default ManualTimesheetsPage;
