import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

const SignIn = ({ location }) => {
  return (
    <Redirect
      to={location.state?.referrerPathname + location.state?.referrerSearch || '/user/timesheets'}
    />
  );
};

export default withRouter(SignIn);
