import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_ALL_TAXONOMIES = gql`
  query getAllTaxonomies {
    allTaxonomies {
      _id
      name
    }
  }
`;

const useAllTaxonomies = () => {
  const { loading, data } = useQuery(GET_ALL_TAXONOMIES);
  return { loading, data };
};

export default useAllTaxonomies;