import React from 'react';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import Button from '../../common/components/Button';
import { GET_USER_TIMESHEET } from '../queries';
import { CREATE_TIMESHEET } from '../mutations';

const Wrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class TimesheetCreate extends React.Component {
  state = {
    loading: false,
  };

  createTimesheet = () => {
    this.setState({ loading: true });
    this.props.client.mutate({
      mutation: CREATE_TIMESHEET,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_TIMESHEET,
          variables: {
            endDate: this.props.endDate,
            user: this.props.user || null,
          },
        },
      ],
      variables: {
        endDate: this.props.endDate,
        user: this.props.user || null,
      },
    });
    // component will be unmounted so no need to call setState
    // .then(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Wrapper>
        <h2>You have not yet created a timesheet for this period.</h2>
        <Button
          loading={this.state.loading}
          type="primary"
          icon="plus"
          onClick={this.createTimesheet}
        >
          Create New Timesheet
        </Button>
      </Wrapper>
    );
  }
}

export default withApollo(TimesheetCreate);
