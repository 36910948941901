import React from 'react';
import moment from 'moment';
import { Input } from 'antd';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import TimesheetListContainer from './TimesheetListContainer';

const { Search } = Input;

class TimesheetListCard extends React.Component {
  state = {
    startDate: moment().subtract(1, 'week').startOf('week'),
    endDate: moment().subtract(1, 'week').endOf('isoWeek'),
    queryString: null,
  };

  handleDateChange = range => {
    const [startDate, endDate] = range;
    this.setState({ startDate, endDate });
  };

  render() {
    const { startDate, endDate, queryString } = this.state;
    return (
      <Card
        border
        padded={false}
        title="Timesheets"
        floating
        withShadow={false}
        actionComponent={
          <div className="flex justify-end">
            <DateRangePicker
              handleDateChange={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              withControls
              className="pr-2"
            />
            <Search
              style={{ width: 350 }}
              placeholder="Search by name, email, project, client, deliverable"
              onSearch={queryString => this.setState({ queryString })}
              enterButton
            />
          </div>
        }
      >
        <TimesheetListContainer
          queryString={queryString}
          startDate={startDate.format('YYYYMMDD')}
          endDate={endDate.format('YYYYMMDD')}
        />
      </Card>
    );
  }
}

export default TimesheetListCard;
