/* eslint-disable import/no-unresolved */
// import { Meteor } from 'meteor/meteor';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import SignIn from './SignIn';

const mapStateToProps = state => ({
  referrerPathname: get(state, 'router.location.state.referrerPathname'),
  referrerSearch: get(state, 'router.location.state.referrerSearch'),
  location: get(state, 'router.location'),
  loggingIn: state.meteor.loggingIn,
  loggedIn: state.meteor.loggedIn,
  user: state.meteor.user,
});

const mapDispatchToProps = () => ({
  loginWithAzureAd: () => {
    // Meteor.loginWithAzureAd({ loginStyle: 'redirect' });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SignIn));
