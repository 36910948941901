import React from 'react';
import Button from '../../common/components/Button';

const TimesheetDownloadButton = ({ timesheet }) => {
  const { documentUrl, status } = timesheet;

  return (
    <Button
      disabled={['Not Submitted', 'Cancelled', 'Submitted-Pending'].includes(status)}
      icon="cloud-download"
      type="circle"
      href={documentUrl}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};

export default TimesheetDownloadButton;
