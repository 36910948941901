import React from 'react';
import moment from 'moment';
import { Flex, Box } from 'rebass/styled-components';
import Card from '../../common/components/Card';
import DateRangePicker from '../../common/components/DateRangePicker';
import Button from '../../common/components/Button';
import TimesheetSnapshotContainer from './TimesheetSnapshotContainer';

class TimesheetCard extends React.Component {
  state = {
    startDate: moment().startOf('isoWeek').format('YYYYMMDD'),
    endDate: moment().endOf('isoWeek').format('YYYYMMDD'),
  };

  export = undefined;

  onExportReady = ({ getExportFunc }) => {
    const exportFunc = getExportFunc();
    this.export = exportFunc;
  };

  handleExportClick = () => {
    this.export();
  };

  render() {
    const { projectId } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <Card
        padded={false}
        actionComponent={
          <Flex>
            <DateRangePicker
              handleDateChange={range => {
                this.setState({
                  startDate: range[0].format('YYYYMMDD'),
                  endDate: range[1].format('YYYYMMDD'),
                });
              }}
              startDate={startDate}
              endDate={endDate}
            />
            <Box ml="1em">
              <Button onClick={this.handleExportClick}>Export</Button>
            </Box>
          </Flex>
        }
      >
        <TimesheetSnapshotContainer
          startDate={startDate}
          endDate={endDate}
          onExportReady={this.onExportReady}
          projectId={projectId}
        />
      </Card>
    );
  }
}

export default TimesheetCard;
