import TimesheetApprovalListContainer from './TimesheetApprovalListContainer';
import TimesheetApprovedListContainer from './TimesheetApprovedListContainer';
import { SegmentedControl } from '@mantine/core';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TimesheetApprovalGrid from './TimesheetApprovalGrid';

const TimesheetApprovalListCard = () => {
  const [selectedView, setSelectedView] = useState('');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const view = params.get('view') || 'View by Pending';
    setSelectedView(view);
  }, [location]);

  const handleViewChange = (view) => {
    setSelectedView(view);
    const params = new URLSearchParams(location.search);
    params.set('view', view);
    history.push({ search: params.toString() });
  };

  return (
    <div>
      <div className="flex justify-end mb-2">
        <SegmentedControl
          size="xs"
          radius="xs"
          data={['View by Pending', 'View by Week']}
          value={selectedView}
          onChange={handleViewChange}
        />
      </div>
      {selectedView === 'View by Pending' ? (
        <div>
          <TimesheetApprovalListContainer />
          <TimesheetApprovedListContainer />
        </div>
      ) : (
        <TimesheetApprovalGrid />
      )}
    </div>
  );
};

export default TimesheetApprovalListCard;
