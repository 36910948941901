import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Layout from '../../common/components/Layout';
import SideMenu from './SideMenu';

const { Sider } = Layout;
const CustomSider = styled(Sider)`
  background: initial;
  .ant-layout-sider-children {
    background: initial;
  }
`;

const SideBar = () => (
  <CustomSider width="230" breakpoint="sm"  collapsedWidth="0">
    <SideMenu />
  </CustomSider>
);

SideBar.propTypes = {
  // boolean that represents whether the sidebar is collapsed
  isCollapsed: PropTypes.bool.isRequired,
  // function to run when sidebar collapse
  handleCollapse: PropTypes.func.isRequired,
};

// export default SideBar;
export default SideMenu;
