import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { get, flatten, groupBy } from 'lodash';
import UserTaskTotals from './UserTaskTotals';
import TextLoader from '../../common/components/TextLoader';
import { GET_TIMESHEET_WEEKLY_SNAPSHOT } from '../queries';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

export const sumLineItemHours = li => {
  const mon = get(li, 'monTask.hours') || 0;
  const tue = get(li, 'tueTask.hours') || 0;
  const wed = get(li, 'wedTask.hours') || 0;
  const thu = get(li, 'thuTask.hours') || 0;
  const fri = get(li, 'friTask.hours') || 0;
  const sat = get(li, 'satTask.hours') || 0;
  const sun = get(li, 'sunTask.hours') || 0;
  return mon + tue + wed + thu + fri + sat + sun;
};

// const formatDate = dateStr => {
//   let dateString = dateStr;
//   let year = dateString.substring(0, 4);
//   let month = dateString.substring(4, 6);
//   let day = dateString.substring(6, 8);
//   let date = new Date(year, month - 1, day);
//   return date.toLocaleDateString('fr-CA');
// };

export const createGridData = timesheets =>
  flatten(
    timesheets
      .filter(ts => ts.status === 'Approved')
      .map(ts =>
        ts.lineItems.map(li => {
          let bu = `${get(li, 'project.accountingCode') || ''}-${
            get(li, 'project.accountingCodeSuffix') || ''
          }`;
          if (
            get(li, 'deliverable.accountingCode') &&
            get(li, 'deliverable.accountingCodeSuffix')
          ) {
            bu = `${get(li, 'deliverable.accountingCode') || ''}-${
              get(li, 'deliverable.accountingCodeSuffix') || ''
            }`;
          }
          let days = ['monTask', 'tueTask', 'wedTask', 'thuTask', 'friTask', 'satTask', 'sunTask'];
          let finalData = [];
          for (let i = 0; i < days.length; i++) {
            let temp = {
              userIdDeliverableId: `${get(ts, 'user._id')}-${get(li, 'deliverable._id')}`,
              name: `${get(ts, 'user.firstName')} ${get(ts, 'user.lastName')}`,
              userId: get(ts, 'user._id'),
              customerJob: `${get(li, 'project.client.quickbooks.customerName')}: ${get(
                li,
                'project.quickbooks.jobName',
              )}`,
              timesheetId: get(ts, '_id'),
              status: get(ts, 'status'),
              endDate: moment(get(ts, 'endDate')).format('MM-DD-YYYY'),
              payrollItem: get(ts, 'user.quickbooks.payrollItem'),
              billableStatus: 'T',
              class: '',
              payrollId: get(ts, 'user.payrollId'),
              email: get(ts, 'user.emails[0].address'),
              clientName: get(li, 'project.client.shortName'),
              project: get(li, 'project.name'),
              bu: bu,
              milestone: get(li, 'deliverable.milestoneName'),
              deliverable: get(li, 'deliverable.deliverableName'),
              serviceItem: 'Architectural',
            };
            let taskdate = get(li, days[i] + '.date');
            let taskhour = get(li, days[i] + '.hours');
            let comment = get(li, days[i] + '.comment');
            if (taskdate && taskhour) {
              temp['date'] = moment(taskdate).format('MM-DD-YYYY');
              temp['duration'] = taskhour;
              temp['notes'] = `${get(li, 'deliverable.milestoneName')} - ${get(
                li,
                'deliverable.deliverableName',
              )}${comment ? ` : ${comment}` : ''}`;
              finalData.push(temp);
            }
          }
          return finalData;
        }),
      ),
  );

const TimesheetListContainer = ({ user, setLoading, startDate, endDate, ...rest }) => {
  const { data, loading, error } = useQuery(GET_TIMESHEET_WEEKLY_SNAPSHOT, {
    variables: {
      startDate,
      endDate,
    },
  });
  useEffect(() => {
    if (!error) {
      setLoading(loading);
    }
  }, [loading, error, setLoading]);
  if (loading) {
    return <TextLoader text="Loading" />;
  }
  const timesheets = get(data, 'finance.timesheetSnapshots') || [];

  console.log({ timesheets });
  // remove tasks for days that fall outside of the selected range
  const timesheetsWithTrimmedTasks = timesheets.map(timesheet => {
    const newLineItems = timesheet.lineItems.map(lineItem => {
      for (const property in lineItem) {
        if (property.endsWith('Task') && lineItem[property]) {
          const { date } = lineItem[property];
          if (date < startDate || date > endDate) {
            delete lineItem[property];
          }
        }
      }
      return lineItem;
    });
    return {
      ...timesheet,
      lineItem: newLineItems,
    };
  });
  const IndividualTaskrow = createGridData(timesheetsWithTrimmedTasks);
  const rowData = IndividualTaskrow.flat();
  const internalTasks = rowData.filter(task => task.clientName === 'ENV');
  const groupByUserAndDeliverable = groupBy(rowData, 'userIdDeliverableId');
  console.log({ rowData, internalTasks, groupByUserAndDeliverable });

  const newRowData = Object.entries(groupByUserAndDeliverable).map(property => {
    return {
      userIdDeliverableId: property[0],
      name: property[1][0].name,
      project: property[1][0].project,
      deliverable: property[1][0].deliverable,
      milestone: property[1][0].milestone,
      hours: property[1].reduce((acc, curr) => acc + curr.duration, 0),
    };
  });
  console.log({ newRowData });
  return <UserTaskTotals user={user} rowData={newRowData} endDate={endDate} {...rest} />;
};

export default connect(mapStateToProps)(TimesheetListContainer);
