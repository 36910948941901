import React from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TopAvatarMenu from './TopAvatarMenu';
import Icon from '../../common/components/Icon';
import Drawer from '../../common/components/Drawer';
import SideMenuContainer from '../../sidebar/components/SideMenu';

const CustomHeader = styled.div`
  z-index: 2;
  padding: 0 16px;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const Logo = styled.img`
  height: 35px;
`;

const LogoWrapper = styled.div`
  width: ${props => (props.sidebarCollapsed ? '150px' : '250px')};
  display: flex;
  justify-content: flex-start;
  transition: all 200ms 300ms;
`;

const StyledIcon = styled(({ sidebarCollapsed, ...rest }) => <Icon {...rest} />)`
  font-size: 25px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 200ms;
  ${props => props.sidebarCollapsed && 'transition: opacity 200ms 300ms; opacity: 1;'};
`;

const TopBar = ({ toggleSidebar, sidebarCollapsed }) => {
  const [config] = useGlobal('config');
  const [showMobileMenu, setShowMobileMenu] = useGlobal('showMobileMenu');

  return (
    <CustomHeader className="w-full">
      <LogoWrapper sidebarCollapsed={sidebarCollapsed}>
        <Link to="/user">
          <Logo src={config['logo-topbar-url']} alt="Logo" />
        </Link>
      </LogoWrapper>
      <StyledIcon
        sidebarCollapsed={sidebarCollapsed}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        type={sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
        className="text-white"
      />
      <TopAvatarMenu />
      <Drawer
        bodyStyle={{ padding: 0, paddingTop: 40 }}
        placement="left"
        visible={showMobileMenu}
        onClose={() => {
          setShowMobileMenu(false);
        }}
      >
        <SideMenuContainer />
      </Drawer>
    </CustomHeader>
  );
};

export default TopBar;
