import React, { useState } from 'react';
import Cron, { HEADER } from 'react-cron-generator';
import './index.css';

/**
 * 
 * @param {setter} setstateFunction  
 * @param {getter} get state value from the component
 * @returns 
 */
const CronScheduler = ({setter,getter}) => {

  const SpringCronToRegulatCron = value=>{
    const str = value.substring(2,value.length-2).replace('?','*')
    return str
  }


  const handleChange = (e,p) => {
    const val = SpringCronToRegulatCron(e);

    setter(val);
  };
  // const des = (ResultText)=>{console.log(e,"translated");}

  const [value] = useState('0 0,12 1 */30 *');
  const options = {
    headers: [HEADER.MONTHLY, HEADER.WEEKLY],
    

  };

  return (
    <div>
      <Cron
        onChange={(e) => handleChange(e)}
        value={value}
        showResultText={true}
        showResultCron={false}
        options={options}
        // locale="en"
        // translateFn={(ResultText)=>{console.log(ResultText,"translated");}}
      />
    </div>
  );
};

export default CronScheduler;
