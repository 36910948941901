import React from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input, {TextArea} from '../../common/components/Input';
import {  UPDATE_CONFIG } from '../mutations';
import { Select } from 'antd';


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditConfigForm = ({ onComplete, client, notifyUser, values }) => {

  const isValidUrl = (string) => {
    let url;
  
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
};

const isJSON = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}


  return (
    <Formik
      initialValues={values}
      validate={values => {
        const errors = {};

        if (!values.value) {
          errors.value = 'Required';
        }
        //Value validation check logic
        if(values.valueType === 'Email' && !values.value.includes('@')){
          errors.value = 'Value must be a valid email address';
        }
        else if(values.valueType === "Number" && (typeof Number(values.value) !== 'number' || isNaN(Number(values.value)))){
          errors.value = 'Value must be a valid number';
        }
        else if(values.valueType === "Json" && !isJSON(values.value)){
          errors.value = 'Value must be json format';
        }
        else if(values.valueType === "Boolean" && (values.value !== 'true' && values.value !== 'false')){
          errors.value = 'Value must be true or false';
        }
        else if(values.valueType === "Url" && !isValidUrl(values.value)){
          errors.value = 'Value must be a valid URL';
        }

        if (!values.description) {
          errors.description = 'Required';
        }

        if (!values.valueType) {
          errors.valueType = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        client
          .mutate({
            mutation: UPDATE_CONFIG,
            awaitRefetchQueries: true,
            variables: {
              id: values.key,
              description: values.description,
              value: values.value,
              valueType: values.valueType,
            },
          })
          .then(res => {
            // if (res.errors.length > 0 && res.errors[0].message === 'Error: User exists') {
            //   setErrors({ email: 'Email/User already in the system' });
            // } else {
            //   onComplete();
            // }
            onComplete();
            setSubmitting(false);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <div style={{marginTop: '2rem'}} className='flex justify-center items-center w-full  '>
        <Form className='w-full flex  flex-col justify-center items-center' onSubmit={handleSubmit} {...formItemLayout}>
        
        <Form.Item
          className='w-full flex justify-center items-center'
            hasFeedback
            validateStatus={errors.key && touched.key && 'error'}
            help={errors.key && touched.key && errors.key}
          >
            <span className='text-black font-semibold'>
            Key
            </span>
            <Input
              name="key"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.key}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
          className='w-full flex justify-center items-center'
            validateStatus={errors.valueType && touched.valueType && 'error'}
            help={errors.valueType && touched.valueType && errors.valueType}
          >
            <span className='text-black font-semibold'>
            Type
            </span>
            <Select

            name="valueType"
              placeholder="Pick value"
              
              onChange={(value) => setFieldValue('valueType', value)}
              onBlur={handleBlur}
              value={values.valueType}
            >
      {['String', 'Number', 'Boolean', 'Json','Url','Email'].map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      )
      )}
            </Select>
          </Form.Item>
          <Form.Item
          className='w-full flex justify-center items-center'
            hasFeedback
            validateStatus={errors.value && touched.value && 'error'}
            help={errors.value && touched.value && errors.value}
          >
            <span className='text-black font-semibold'>
            Value
            </span>
            <TextArea
              name="value"
              rows={4}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.value}
              disabled={isSubmitting}
            />
          </Form.Item>
          
          <Form.Item
          className='w-full flex justify-center items-center'
            hasFeedback
            validateStatus={errors.description && touched.description && 'error'}
            help={errors.description && touched.description && errors.description}
          
          >
            <span className='text-black font-semibold'>
          Description
            </span>
            <TextArea
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              rows={4}
              disabled={isSubmitting}
            />
          </Form.Item>
          {/* )} */}


          
          <Form.Item
            className=' ml-auto'
           
          >
            <Button className='ml-auto ' loading={isSubmitting} type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
        </div>
      )}
    </Formik>
  );
};

export default withApollo(EditConfigForm);
