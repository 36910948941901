import React from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Tabs, { TabPane } from '../../common/components/Tabs';
import Card from '../../common/components/Card';
import ProjectCard from '../../common/components/ProjectCard';
import ProjectDetailsTimesheetList from './ProjectDetailsTimesheetList';
import ProjectDetailsResourceList from './ProjectDetailsResourceList';
import ProjectDetailsDeliverableList from './ProjectDetailsDeliverableList';
import TimesheetSnapshotCard from './TimesheetSnapshotCard';
import TimesheetApprovalListContainer from './TimesheetApprovalListContainer';
import ProjectFinancials from './ProjectFinancials';
import ProjectRoles from './ProjectRoles';
import ProjectExpenses from './ProjectExpenses';
import ProjectSummary from './ProjectSummary';

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

const ProjectDetails = ({ user, goToUrl, project, activeTab, refetchQueries, refetch }) => {
  const [config] = useGlobal('config');
  const history = useHistory();
  if (!project) return null;
  const { location } = history;
  const { pathname } = location;

  let pathPrefix = pathname.split('/').slice(0, 3).join('/');

  if (pathname.includes('finance')) {
    pathPrefix = pathPrefix.replace('project-management', 'finance');
  }

  if (!activeTab) {
    return <Redirect to={`${pathname}/deliverables`} />;
  }

  return (
    <Flex flexDirection="column" pb="6rem">
      <ProjectCard project={project} refetchQueries={refetchQueries} showActions />
      <Box width={1} mt=".5rem">
        <Card border fadeIn withShadow padded={false} floating>
          <StyledTabs
            activeKey={activeTab}
            onChange={activeTab => goToUrl(`${pathPrefix}/${project._id}/${activeTab}`)}
          >
            {config['company-short-name'] === 'ENV' && (
              <TabPane tab="Project Summary" key="summary">
                <Switch>
                  <Route
                    path={`${pathPrefix}/:projectId/summary`}
                    render={() => <ProjectSummary project={project} />}
                  />
                </Switch>
              </TabPane>
            )}
            <TabPane
              tab={config['custom-deliverables-tab-name'] || 'Deliverables'}
              key="deliverables"
            >
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/deliverables`}
                  render={() => <ProjectDetailsDeliverableList projectId={project._id} refetchQueries={refetchQueries} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Timesheets" key="timesheets">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/timesheets`}
                  render={() => <ProjectDetailsTimesheetList projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Team" key="team">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/team`}
                  render={() => (
                    <ProjectDetailsResourceList roles={project.roles} projectId={project._id} />
                  )}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Roles" key="roles">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/roles`}
                  render={() => <ProjectRoles projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab={config['custom-expenses-tab-name'] || 'Expenses'} key="expenses">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/expenses`}
                  render={() => <ProjectExpenses project={project} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Financials" key="financials">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/financials`}
                  render={() => <ProjectFinancials project={project} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Export Hours" key="export">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/export`}
                  render={() => <TimesheetSnapshotCard projectId={project._id} />}
                />
              </Switch>
            </TabPane>
          </StyledTabs>
        </Card>
      </Box>
      <TimesheetApprovalListContainer projectId={project._id} />
    </Flex>
  );
};

export default ProjectDetails;
