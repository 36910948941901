import { createStore, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import LogRocket from 'logrocket';
import history from './history';
import rootReducer from '../../modules/rootReducer';
import defaultState from './defaultState';

const middleware = [routerMiddleware(history), LogRocket.reduxMiddleware()];

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
const store = createStore(
  combineReducers({
    ...rootReducer,
    router: connectRouter(history),
  }),
  defaultState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(...middleware),
);

export default store;
