
import Button from '../../common/components/Button';
import React, { useState } from 'react';
import numeral from 'numeral';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    if (payload[0].name === 'Variance') {
      const variance = payload[0].value;
      return (
        <div className="bg-white p-2">
          <p className="">{`${label}`}</p>
          <p className="">Budget Remaining: {variance > 0 ? variance : 0}</p>
          <p className="">Over Budget: {variance < 0 ? variance * -1 : 0}</p>
        </div>
      );
    }

    const totalCost = payload[0]?.value || 0;
    const budgetRemaining = payload[1]?.value || 0;
    return (
      <div className="bg-white p-2">
        <p className="font-bold">{`${label}`}</p>
        <p className="">Total Cost: {numeral(totalCost).format('$0,0')}</p>
        <p className="">
          Budget Remaining: {numeral(budgetRemaining > 0 ? budgetRemaining : 0).format('$0,0')}
        </p>
        <p className="">
          Over Budget: {numeral(budgetRemaining < 0 ? budgetRemaining * -1 : 0).format('$0,0')}
        </p>
      </div>
    );
  }

  return null;
};

const ProjectDetailsMilestoneBudgetChart = ({ deliverables, header }) => {
  const [showVariance, setShowVariance] = useState(false);
  console.log(deliverables);
  const data2 = deliverables.reduce((acc, cur) => {
    if (acc[cur.milestoneName]) {
      acc[cur.milestoneName].totalCost += cur.totalCost;
      acc[cur.milestoneName].budget += cur.budget;
      acc[cur.milestoneName].variance += cur.budget - cur.totalCost;
      return {
        ...acc,
      };
    } else {
      return {
        ...acc,
        [cur.milestoneName]: {
          totalCost: cur.totalCost || 0,
          budget: cur.budget,
          variance: cur.budget - cur.totalCost || 0,
        },
      };
    }
  }, {});

  let data3 = [];
  for (const [key, value] of Object.entries(data2)) {
    data3.push({
      name: key,
      ...value,
    });
  }

  if (data3.length === 0) {
    return null;
  }

  const totalBudget = data3.reduce((acc, cur) => acc + cur.budget, 0);

  if (totalBudget === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex gap-2 mb-4">
        {header}
        <Button style={{width: 120 }} onClick={() => setShowVariance(!showVariance)}>Variance</Button>
      </div>
      <div className="mt-4">
        <ResponsiveContainer width="100%" height={200}>
          <BarChart width="100%" height="100%" data={data3}>
            <CartesianGrid strokeDashrray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={value => {
                return numeral(value).format('$0,0');
              }}
            />
            <Tooltip content={<CustomTooltip />} />

            {showVariance ? (
              <Bar dataKey="variance" name="Variance" barSize={data3.length < 10 ? 50 : null}>
                {data3.map((entry, index) => (
                  <Cell key={index} fill={entry.totalCost > entry.budget ? '#e53e3e' : '#38a169'} />
                ))}
              </Bar>
            ) : (
              <>
                <Bar
                  stackId="a"
                  dataKey="totalCost"
                  name="Total Cost"
                  fill="#38a169"
                  barSize={data3.length < 10 ? 50 : null}
                >
                  {data3.map((entry, index) => {
                    return <Cell key={`cell-${index}`} />;
                  })}
                </Bar>
                <Bar stackId="a" dataKey="variance">
                  {data3.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        stroke={entry.variance > 0 ? 'black' : null}
                        strokeWidth={entry.variance > 0 ? 1 : null}
                        strokeDasharray={entry.variance > 0 ? 3 : null}
                        fill={(() => {
                          if (entry.budget > 0) {
                            return entry.variance > 0 ? '#f0fff4' : '#e53e3e';
                          }
                          return 'lightgray'
                        })()}
                      />
                    );
                  })}
                </Bar>
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ProjectDetailsMilestoneBudgetChart;


