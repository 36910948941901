// eslint-disable-next-line
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri: process?.env.REACT_APP_GRAPHQL_BASE_URL + '/api/graphql',
  credentials: 'include',
});

// eslint-disable-next-line
const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

export const deleteAllCookies = (rootDomain, subdomains) => {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie.trim();

    // Iterate through all subdomains
    for (const subdomain of subdomains) {
      const domain = `${subdomain}.${rootDomain}`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain};secure`;
    }
  }
};

function getRootDomain() {
  const hostname = window.location.hostname;
  const domainParts = hostname.split('.');
  const rootDomain = domainParts.slice(-2).join('.');
  return rootDomain;
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log({ graphQLErrors, networkError });
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          console.log({ err });
          console.log('Unauthorized');
          localStorage.clear();
          client.resetStore();
          deleteAllCookies(getRootDomain(), ['', 'stage', 'stg']);
          // history.push('/auth/signout');
          window.location.href = window.location.origin + '/auth/signout';
          return forward(operation);
        default:
          return;
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // throw new Error(error);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

// const link = middlewareLink.concat(httpLink, errorLink);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache,
  defaultOptions,
});

const createClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache,
    defaultOptions,
  });
};

export { createClient };

export default client;
