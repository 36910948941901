import React from 'react';
import styled from 'styled-components';
import { withRouter, Route, Switch } from 'react-router-dom';
import TimesheetReportContainer from './TimesheetReportContainer';

const Container = styled.div`
  padding: 0 1em 1em 1em;
`;

// need router history to change url on tab change
const ReportsPage = () => (
  <Container>
    <Switch>
      <Route
        path="/reports/timesheets/:timesheetId"
        render={() => <TimesheetReportContainer />}
      />
    </Switch>
  </Container>
);

export default withRouter(ReportsPage);
