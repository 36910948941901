import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import ReportsPage from '../modules/reports/components/ReportsPage';
import { GlobalStyle } from '../globalStyles';

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: white;
`;

const Layout = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

const MainPane = styled.div`
  display: flex;
`;

const Content = styled.div`
  max-width: ${props => (props.sidebarCollapsed ? props.maxWidth + props.sidebarWidth : props.maxWidth)}px;
  min-width: ${props => props.minWidth}px;
  width: calc(100vw - ${props => (props.sidebarCollapsed ? 0 : props.sidebarWidth)}px);
  transition: all 200ms ease-in-out;
`;

const sidebarWidth = 250;
const contentMaxWidth = 1115;
const contentMinWidth = 970;

const ReportsLayout = () => (
  <Wrapper>
    <GlobalStyle />
    <Layout>
      <MainPane>
        <Content sidebarWidth={sidebarWidth} maxWidth={contentMaxWidth} minWidth={contentMinWidth}>
          <Switch>
            <Route path="/reports" component={ReportsPage} />
          </Switch>
        </Content>
      </MainPane>
    </Layout>
  </Wrapper>
);

export default ReportsLayout;
