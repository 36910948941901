import { Redirect, Route, Switch } from 'react-router-dom';
import TimesheetsPage from './TimesheetsPage.tsx';
import IntegrationsPage from './IntegrationsPage.tsx';
import NetsuiteIntegrationPage from './NetsuiteIntegrationPage.tsx';
import TasksPage from './TasksPage.tsx';
import Page from '../../common/components/Page';
import SettingsContainer from './SettingsContainer';
import ConfigurationCollection from './ConfigurationCollection.js';

const SuperAdminPage = () => (
  <Page full>
    <Switch>
      <Route exact path="/super-admin" render={() => <Redirect to="/admin/timesheets" />} />
      <Route exact path="/super-admin/configurations" render={() => <ConfigurationCollection />} />
      <Route exact path="/super-admin/timesheets" render={() => <TimesheetsPage />} />
      <Route exact path="/super-admin/integrations" render={() => <IntegrationsPage />} />
      <Route exact path="/super-admin/integrations/netsuite" render={() => <NetsuiteIntegrationPage />} />
      <Route exact path="/super-admin/settings" render={() => <SettingsContainer />} />
      <Route exact path="/super-admin/tasks" render={() => <TasksPage />} />
    </Switch>
  </Page>
);

export default SuperAdminPage;
