import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import ResourceUtilization from './ResourceUtilization';
import Page from '../../common/components/Page';

const PerformancePage = () => {
  return (
    <Page full>
      <Switch>
        <Route exact path="/performance" render={() => <Dashboard />} />
        <Route
          exact
          path="/performance/resource-utilization"
          render={() => <ResourceUtilization />}
        />
      </Switch>
    </Page>
  );
};

export default PerformancePage;
