import { useQuery } from '@apollo/react-hooks';
// @ts-ignore
import { Progress } from 'antd';
import { GET_DELIVERABLES_AND_EXPENSES } from '../../../modules/project-manager/components/ProjectFinancialSummary';
import { calcBudgetFill } from '../../../modules/project-manager/components/ProjectFinancials';
import { constructDailyTotals } from './TimesheetGrid';
import { trpc } from '../../../utils/trpc';

const ProjectBudgetIndicator = ({
  projectId,
  lineItems,
}: {
  projectId: string;
  lineItems: [any];
}) => {
  const { loading, data } = useQuery(GET_DELIVERABLES_AND_EXPENSES, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: myRate = 0 } = trpc.useQuery(
    [
      'users.myRateByProjectId',
      {
        projectId,
      },
    ],
    {
      refetchOnWindowFocus: false,
    },
  );

  if (loading) {
    return null;
  }

  const project = data?.projectManagement?.project;
  const expenses = data?.projectManagement?.project?.expenses || [];
  const deliverables = data?.projectManagement?.project?.deliverables || [];

  // @ts-ignore
  const totalDeliverableCost = deliverables.reduce((acc, cur) => {
    return acc + cur.totalCost;
  }, 0);

  const deductExpenses = data?.projectManagement?.project?.deductExpenses;
  const totalExpenseCost = deductExpenses
    ? // @ts-ignore
      expenses.reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0)
    : 0;
  const totalCost = totalDeliverableCost + totalExpenseCost;
  const { budget: projectBudget } = project;
  const budgetUsed = totalCost;
  // const budgetRemaining = projectBudget - totalCost;

  // @ts-ignore
  let rowsObj = deliverables.reduce((acc, cur) => {
    if (acc[cur.milestoneName]) {
      return {
        ...acc,
        [cur.milestoneName]: [...acc[cur.milestoneName], cur],
      };
    } else {
      return {
        ...acc,
        [cur.milestoneName]: [cur],
      };
    }
  }, {});

  let categories = [];
  for (const [key, value] of Object.entries(rowsObj)) {
    // @ts-ignore
    const budget = value.reduce((acc, cur) => acc + cur.budget, 0);
    // @ts-ignore
    const totalCost = value.reduce((acc, cur) => acc + cur.totalCost, 0);
    categories.push({
      name: key,
      value: totalCost,
      fill: totalCost > budget ? 'red' : '#38A169',
    });
  }

  if (expenses.length > 0) {
    // @ts-ignore
    const totalExpenses = expenses.reduce((acc, cur) => acc + cur.amount, 0);
    categories.push({
      name: 'Expenses',
      value: totalExpenses,
      fill: 'lightgray',
    });
  }

  const budgetUsedPct = (budgetUsed / projectBudget) * 100;

  const dailyTotals = constructDailyTotals(lineItems);
  let newHours = 0;
  for (const property in dailyTotals) {
    // @ts-ignore
    newHours += dailyTotals[property];
  }

  const newCost = newHours * myRate;
  const newBudgetPct = ((totalDeliverableCost + newCost) / projectBudget) * 100;

  const budgetDiff = newBudgetPct - budgetUsedPct;

  if (!projectBudget || projectBudget === 0) {
    return null;
  }

  const status = budgetUsedPct > 100 ? 'exception' : budgetUsedPct <= 100 ? 'active' : 'success';
  return (
    <div className="ml-2 flex items-end">
      <div style={{ width: 150 }}>
        <Progress
          percent={Math.floor(newBudgetPct)}
          successPercent={budgetUsedPct < 100 ? Math.floor(budgetUsedPct) : undefined}
          size="small"
          strokeColor={calcBudgetFill({ budgetUsedPct: (newBudgetPct || budgetUsedPct) / 100 })}
          status={status}
        />
      </div>
      <div className="font-thin text-xs ml-2">
        {status === 'exception'
          ? 'Over budget'
          : status === 'active'
          ? 'Budget used'
          : 'On budget'}
      </div>
      {myRate > 0 && budgetDiff > 0 && status !== 'exception' && (
        <div className="font-thin text-xs ml-2">
          {`(Your new hours will use ${budgetDiff.toFixed(1)}% of the budget)`}
        </div>
      )}
    </div>
  );
};

export default ProjectBudgetIndicator;
