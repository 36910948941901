import React, { useState } from 'react';
import { Slider, Form } from 'antd';
import { TIMESHEET_THRESHOLD } from '../mutations';
import client from '../../../api/graphql/client';
import moment from 'moment';
import Card from '../../common/components/Card';
import { Tooltip, Icon, Alert } from 'antd';

const TimesheetThresholdRuleContainer = ({ thresholdDetails }) => {
  const { updatedBy, updatedAt } = thresholdDetails || {};
  const [max, setMax] = useState(thresholdDetails ? thresholdDetails?.max : 0);
  const [min, setMin] = useState(thresholdDetails ? thresholdDetails?.min : 0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name, hours) => {
    if (name === 'max') {
      setMax(hours);
    } else if (name === 'min') {
      setMin(hours);
    }
    setIsLoading(true);
    client
      .mutate({
        mutation: TIMESHEET_THRESHOLD,
        variables: {
          min: name === 'min' ? hours : min,
          max: name === 'max' ? hours : max,
        },
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return (
    <Card
      title="Threshold Rules"
      actionComponent={
        <>
          {updatedBy && (
            <Tooltip
              placement="topRight"
              title={`Updated By : ${updatedBy} at ${moment(updatedAt).format('LLL')} `}
            >
              <Icon type="info-circle" theme="twoTone" />{' '}
            </Tooltip>
          )}
        </>
      }
    >
      <div>
        {!thresholdDetails && (
          <Alert
            style={{ padding: '10px', margin: '10px' }}
            message="Default : Maximum 80 hours || Minimum 0 hours"
            type="warning"
          />
        )}
        <Form style={{ padding: '10px', margin: '10px' }}>
          <Form.Item label="Maximum Hours allowed">
            {`${max} hours`}
            <Slider
              max={120}
              tooltipPlacement="bottom"
              defaultValue={max}
              disabled={isLoading}
              onChange={val => {
                handleChange('max', val);
              }}
            />
          </Form.Item>
          <Form.Item label="Minimum Hours allowed">
            {`${min} hours`}
            <Slider
              max={120}
              tooltipPlacement="bottom"
              defaultValue={min}
              disabled={isLoading}
              onChange={val => {
                handleChange('min', val);
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default TimesheetThresholdRuleContainer;
