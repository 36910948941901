import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserTaskTotalsCard from './UserTaskTotalsCard';
import { changeTimesheetDate } from '../../timesheets/actions';

const mapStateToProps = state => ({
  endDate: state.timesheets.endDate,
});

const mapDispatchToProps = dispatch => ({
  handleDateChange: endDate => dispatch(changeTimesheetDate(endDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserTaskTotalsCard));