import Tag from 'antd/lib/tag';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  &:hover {
    opacity: initial;
    cursor: initial;
  }
  height: max-content;
`;

export default StyledTag;
