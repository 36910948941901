import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../common/components/Card';
import NewProjectTemplateForm from './NewProjectTemplateForm';

const ProjectTemplatesNew = () => {
  let history = useHistory();
  return (
    <Card floating title="Create New Template" bodyBackground={null}>
      <NewProjectTemplateForm
        onComplete={projectId => {
          history.push(`/admin/project-templates/${projectId}`);
        }}
      />
    </Card>
  );
};

export default ProjectTemplatesNew;
