import Taxonomy from './Taxonomy';
import useAllTaxonomies from '../../../hooks/useAllTaxonomies'; // Import the new hook
import TextLoader from '../../common/components/TextLoader'; // Assuming you have a loader component

const TaxonomyPage = () => {
  const { loading, data } = useAllTaxonomies(); // Use the hook to fetch all taxonomies

  if (loading) {
    return <TextLoader text="Loading taxonomies..." />;
  }

  const taxonomies = data?.allTaxonomies || []
  console.log({ data, taxonomies });

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
      {taxonomies.map(taxonomy => (
        <Taxonomy
          key={taxonomy._id}
          title={taxonomy.name}
          termNameSingular={taxonomy.name.slice(0, -1)} // Assuming singular is the name without the last character
          termNamePlural={taxonomy.name}
        />
      ))}
    </div>
  );
};

export default TaxonomyPage;
