import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TextLoader from '../../common/components/TextLoader';
import { HR_DELINQUENCY } from '../queries';
import DelinquencyReport from './DelinquencyReport';

import moment from 'moment';


const DelinquencyReportCard = () => {
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment());
  
  const handleDateChange = range => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    // this.setState({ startDate, endDate });
  };

  const variables = {
    startDate: startDate.format('YYYYMMDD'),
    endDate: endDate.format('YYYYMMDD'),
  };

  return (
    <Query query={HR_DELINQUENCY} variables={variables}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        if (loading) {
          return <TextLoader text="Loading Report" />;
        }
        const users = get(data, 'usersWithTimesheet');
        return (
          <DelinquencyReport
            data={{ users, handleDateChange, startDate, endDate }}
          />
        );
      }}
    </Query>
  );
};

export default DelinquencyReportCard;
