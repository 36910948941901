import { Badge } from 'antd';
import { Query } from 'react-apollo';
import { GET_TIMESHEETS_FOR_APPROVAL } from '../../../project-manager/queries';

const ApprovalBadgeCounter = () => {
  return (
    <Query query={GET_TIMESHEETS_FOR_APPROVAL}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {({ loading, error, data }) => {
        // if (error) throw new Error(error);
        if (error) return null;

        const approvalCount = data?.projectManagement?.timesheets?.length || null;
        return approvalCount && <Badge count={approvalCount} />;
      }}
    </Query>
  );
};

export default ApprovalBadgeCounter;
