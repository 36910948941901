import styled from 'styled-components';
import Input from 'antd/lib/input';

const StyledInput = styled(Input)`
  font-size: 1em;
`;

const { TextArea } = Input;

export default StyledInput;
export { TextArea };
