import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SignInContainer from '../modules/auth/components/SignInContainer';
import SignOutContainer from '../modules/auth/components/SignOutContainer';
import { fadeIn, GlobalStyle } from '../globalStyles';
import Oauth from '../modules/auth/components/Oauth';
import ResetPassword from '../modules/auth/components/ResetPassword';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 0.2s linear;
`;

const Content = styled.div`
  margin-top: 100px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthLayout = () => {
  const [config] = useGlobal('config');
  return (
    <Wrapper>
      <Helmet>
        <title>{`${config['company-short-name']} Time Tracker`}</title>
        <link rel="icon" href={`${config['favicon-url']}`} sizes="32x32" />
      </Helmet>
      <GlobalStyle />
      <Switch>
        <Route
          path="/auth"
          render={() => (
            <Layout>
              <Content>
                {/* <LogoWrapper>
                  <Logo src={config['logo-auth-url']} alt="Logo" />
                </LogoWrapper> */}
                <Switch>
                  <Route path="/auth/signin" component={SignInContainer} />
                  <Route path="/auth/signout" component={SignOutContainer} />
                  <Route path="/auth/reset-password" component={ResetPassword} />
                </Switch>
              </Content>
            </Layout>
          )}
        />
        <Route path="/_oauth" component={Oauth} />
      </Switch>
    </Wrapper>
  );
};

export default AuthLayout;
