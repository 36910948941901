/*
 * action types
 */

export const CHANGE_TIMESHEET_DATE = 'CHANGE_TIMESHEET_DATE';
export const GET_TIMESHEET = 'GET_TIMESHEET';
export const STORE_TIMESHEET_TO_STATE = 'STORE_TIMESHEET_TO_STATE  ';
// export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'

/*
 * other constants
 */

// export const VisibilityFilters = {
//   SHOW_ALL: 'SHOW_ALL',
//   SHOW_COMPLETED: 'SHOW_COMPLETED',
//   SHOW_ACTIVE: 'SHOW_ACTIVE'
// }

/*
 * action creators
 */

export function storeTimesheetToState(timesheet) {
  return { type: STORE_TIMESHEET_TO_STATE, timesheet };
}

export function changeTimesheetDate(endDate) {
  return { type: CHANGE_TIMESHEET_DATE, endDate };
}

export function getTimesheet(endDate) {
  return { type: GET_TIMESHEET, endDate };
}
