import { Avatar, Badge } from 'antd';
import Tooltip from '../../common/components/Tooltip';
import styled from 'styled-components';

const ApproverList = styled.div`
  &:not(:first-child) {
    &:before {
      display: flex;
      align-items: center;
      content: '+';
    }
  }
`;

interface Emails {
  address: string;
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  emails: Emails[];
}

interface ApprovalsRequired {
  status: string;
  approvedBy: string;
  approvers: User[];
}

interface Props {
  approvalsRequired: ApprovalsRequired[];
}

const TimesheetApprovers = ({ approvalsRequired = [] }: Props) => {
  if (!approvalsRequired || approvalsRequired.length === 0) {
    return <div>No approvers</div>;
  }
  return (
    <div className="flex justify-start gap-2">
      {approvalsRequired?.map((approval, approvalIndex) => {
        const approvedBy = approval?.approvedBy;
        return (
          <ApproverList key={approvalIndex} className="flex relative gap-2">
            {approval.approvers.map((user, approverIndex) => {
              return (
                <div
                  key={approverIndex}
                  className="approver"
                  style={{
                    zIndex: approval.approvers.length - approverIndex,
                    marginLeft: approverIndex === 0 ? 0 : -30,
                  }}
                >
                  {/* @ts-ignore */}
                  <Badge
                    key={user._id}
                    dot
                    color={
                      approvedBy === user?.emails[0]?.address
                        ? 'green'
                        : approval.status === 'Declined'
                        ? 'red'
                        : 'orange'
                    }
                    offset={[-10, 5]}
                  >
                    <Tooltip title={`${user.firstName} ${user.lastName}`}>
                      <Avatar
                        className="shadow-md border-solid border border-white"
                        size={50}
                        src={user.imageUrl}
                      >{`${user.firstName?.charAt(0) + user.lastName?.charAt(0)}`}</Avatar>
                    </Tooltip>
                  </Badge>
                </div>
              );
            })}
          </ApproverList>
        );
      })}
    </div>
  );
};

export default TimesheetApprovers;
